import React, { useCallback } from 'react'
import {
  Container,
  FlagContainer
} from "./style";
import Flag from "react-world-flags";
import AmazonRestrictedInfo from "../AmazonRestrictedInfo";
import { Popover } from "@mui/material";


const RestrictedField = (data:any) => {

  const { sellerID, isUsed } = data

  const isCARes = !!data.row?.original?.restrictions?.find((r: any) => r.marketplaceId === 2 && r.sellerId === sellerID && (isUsed ? r.isRestrictedUsed : r.isRestricted ))
  const isUSRes = !!data.row?.original?.restrictions?.find((r: any) => r.marketplaceId === 1 && r.sellerId === sellerID && (isUsed ? r.isRestrictedUsed : r.isRestricted ))

  const asin  = data.row?.original?.asin
  const isNeedPopOver = isCARes || isUSRes

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    asin && isNeedPopOver && setAnchorEl(event.currentTarget);
  },[asin, isNeedPopOver]);

  const handleClose = () => {
    setAnchorEl(null);
  };

    return (
    <Container>
      <FlagContainer onClick={handleClick}>
        {  isCARes &&  <Flag code={"CAN"} /> }
     </FlagContainer>

      <FlagContainer onClick={handleClick}>
        { isUSRes &&  <Flag code={"USA"} /> }
      </FlagContainer>

      {asin &&  <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <AmazonRestrictedInfo  asin = {asin} sellerId={data.sellerID} handlerClose={handleClose} isUsed={isUsed}/>
      </Popover>}
  </Container>)
}

export default RestrictedField

