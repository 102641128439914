import {
    API,
    rootApi
}                           from "api/index";
import { TSellers }         from "api/sellers/d";

export const getSellers = async (): Promise<TSellers[]> =>  {
    const response = await API.get(`${rootApi}/sellers/getActiveSellers`)
    return response?.data?.result || []
}
