import styled   from "styled-components";
import {
    Box,
    Popover
}               from "@mui/material";
import { Flex } from "style";

export const PopoverHoverBox = styled(Flex)`
    width: 100%;
    height: 100%;
`

export const PopoverHoverChildBox = styled(Box)`
  width: 100%;
  height: 100%;
`

export const StyledPopover = styled(Popover) `
  &&& {
    font-size: 12px;
  }
`
