import styled, { css } from "styled-components";
import { Flex } from "style";
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import {
    grey
} from "@mui/material/colors";
export const BsrContainer = styled(Flex)<{isHovered?: boolean}>`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  
  ${props => props.isHovered && css`
    cursor: pointer;
  `}
`;

export const BsrTimerContainer = styled(Flex)<{timeColor?: string, isAsin?: boolean}>`
  &&& {
    font-size: 10px;
    opacity: 0.8;
    position: absolute;
    bottom: 2px;
    left: 0;
    color: ${props => props.timeColor || 'inherit'};
    
    ${props => !props.isAsin && css`
      color: ${grey[400]};
    `}
  
  }
`;

export const BsrTimeIcon = styled(AlarmOnIcon)`
  transform: scale(0.7);
  transform-origin: bottom left;
`

export const BsrTimeIconOff = styled(AlarmOffIcon)`
  transform: scale(0.7);
  transform-origin: bottom left;
`

export const RecordValidityCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
`

export const RecordValidityPart = styled(Flex)<{value?: boolean | number}>`
  &&& {
    font-size: 9px;
    opacity: 0.8;
    position: absolute;
    bottom: 2px;
    right: 0;
    line-height: 1.1;
    padding: 0 2px;
    justify-content: center;
    align-items: center;
    
    ${props => !!props.value && css`
       ${RecordValidityCircle} {
         background: radial-gradient(circle at -1% 57.5%, rgb(19, 170, 82) 0%, rgb(0, 102, 43) 90%);
       }
    `}
    
  }
`;

