import React, {
  useMemo,
  useCallback,
  useReducer,
  useRef,
  useEffect
} from "react";
import TableDataContext from "components/Table/context/context";
import {
  isEqual,
  get,
  merge,
  omit,
  pick,
  cloneDeep
} from 'lodash'
import {
  TReducer,
  TReducerAction,
  TABLE_EVENTS,
  TChangeColumnVisibilityPayLoad,
  TFilerPayload
} from "components/Table/context/d";
import { useFilterNumber } from "components/Table/context/helpers";
import {
  startOfDay,
  endOfDay
} from 'date-fns'
import { useSearchParams } from "react-router-dom";
import queryString from "query-string"
import { deepEqual } from "fast-equals";
import { LocalStorage } from 'utils/LocalStorage'
import { defaultFnFilters } from "components/Table/helpers";
import {
  hash1Create,
  emptyObject,
  emptyArray
} from "Utils";
import {
  getSavedTableFilters,
  addTableFilter,
  removeTableFilter
} from "api/filters";
import { FieldColumnType } from "components/Table/TableHeader/FilterSortCell/d";

export const MAX_PER_PAGE = 50000

const reducer = (state: TReducer, action: TReducerAction) => {
  switch (action.type) {
    default:
      return state


    case TABLE_EVENTS.setFilterFromSaved: {
      const filter = filterByOrder(state.savedFilters?.[action.payload]?.filter || {})
      const filterHash  = (state.savedFilters?.[action.payload] as any)?.hashKey || ""
      return {
        ...state,
        filter,
        filterHash,
        isNeedRefetch: Math.floor(new Date().getTime())
      }
    }

    case TABLE_EVENTS.clearAllFilters: {
      return {
        ...state,
        filter: { },
        isNeedRefetch: Math.floor(new Date().getTime())
      }
    }

    case TABLE_EVENTS.setCurrentTableFilters: {

      return {
        ...state,
        savedFilters: (action.payload || []).reduce((acc: any, x: any) => {
          const filter =  filterByOrder(x.filter)
          const hashKey = hash1Create(filter);
          return {
          ...acc,
            [hashKey]: {
              ...x,
              hashKey,
              filter
             }
          }
        }, {})
      }
    }

    case TABLE_EVENTS.checkCurrentFilterHash: {
      const hash = hash1Create(state.filter);
      if (hash === state.filterHash) return state;
      return {
        ...state,
        filterHash: hash
      }
    }
    case TABLE_EVENTS.addFilter: {
      const field = action.payload.field
      const data = action.payload.data
      if (isEqual(get(state.filter, field), data)) return state
      let filter = { ...state.filter }
      if (`${data}`.length === 0) {
        filter = omit({ ...state.filter }, [action.payload])
      } else {
        filter = {
          ...filter,
          [field]: data
        }
      }
      return {
        ...state,
        filter:  filterByOrder(filter),
        isNeedRefetch: Math.floor(new Date().getTime())
      }
    }

    case TABLE_EVENTS.addMultiFilter: {
      const data = action.payload as any
      const _filter = Object.keys(data).filter(key => {
        if (isEqual(get(state.filter, key), data[key])) return false
        return true
      })
      let filter = { ...state.filter }

      if (!_filter.length) {
        filter = omit({ ...state.filter }, [action.payload])
      } else {
        const _data = _filter.reduce((acc, f)=> {
          return {
            ...acc,
            [f]: data[f]
          }
        },{})
        filter = {
          ...filter,
          ..._data
        }
      }
      return {
        ...state,
        filter:  filterByOrder(filter),
        isNeedRefetch: Math.floor(new Date().getTime())
      }
    }

    case TABLE_EVENTS.clearFilter: {

      const  filterOld = { ...state.filter }
      const filter = omit(filterOld, [action.payload])
      if(isEqual(filter, filterOld)) return state
      return {
        ...state,
        filter: filterByOrder(filter),
       // isNeedRefetch: Math.floor(new Date().getTime()),
      }
    }
    case TABLE_EVENTS.changeVisibilityColumn: {
      const column = (action.payload as TChangeColumnVisibilityPayLoad).column
      const _isVisible = (action.payload as TChangeColumnVisibilityPayLoad).isVisible
      const index = state.columns.findIndex(x => x.accessor === column)
      if (index === -1) return state
      const isVisible = _isVisible === undefined ? !state.columns[index].isVisible : _isVisible
      if (isVisible === state.columns[index].isVisible) return state;
      const columns = [...state.columns]
      columns.splice(index, 1, {
        ...state.columns[index],
        isVisible
      })
      return {
        ...state,
        columns: [...columns]
      }
    }

    case TABLE_EVENTS.columnsChange: {
      const columns = action.payload.map((c: any) => {
        const oldColumn = state.columns.find(cc => cc.accessor === c.accessor) || {}
        return {
          ...oldColumn,
          ...c
        }
      })

      return {
        ...state,
        columns
      }
    }

    case TABLE_EVENTS.setPage: {
      const page = action.payload
      if (state.page === page) return state
      return {
        ...state,
        page,
        isNeedRefetch: Math.floor(new Date().getTime())
      }
    }
    case TABLE_EVENTS.setPerPage: {
      const perPage = action.payload
      if (perPage === state.perPage) return state
      return {
        ...state,
        perPage,
        isNeedRefetch: Math.floor(new Date().getTime())
      }
    }
    case TABLE_EVENTS.setSortBy: {
      const { field, direction } = action.payload
      return {
        ...state,
        sort: {
          ...state.sort,
          field,
          direction: direction === undefined ? !state.sort?.direction : direction
        },
        isNeedRefetch: Math.floor(new Date().getTime())
      }
    }
    case TABLE_EVENTS.addAdditional: {
      return {
        ...state,
        additional: {
          ...action.payload
        },
        isNeedRefetch: Math.floor(new Date().getTime())
      }
    }
    case TABLE_EVENTS.clearAdditional: {
      return {
        ...state,
        isNeedRefetch: Math.floor(new Date().getTime()),
        additional: undefined
      }
    }
    case TABLE_EVENTS.clearSort: {
      return {
        ...state,
        isNeedRefetch: Math.floor(new Date().getTime()),
        sort: undefined
      }
    }
    case TABLE_EVENTS.clearNeedRefetch: {
      return {
        ...state,
        isNeedRefetch: 0
      }
    }

    case TABLE_EVENTS.setRefetch: {
      return {
        ...state,
        isNeedRefetch: Math.floor(new Date().getTime()),
      }
    }

    case TABLE_EVENTS.setResizeColumns: {
      const columnResizing = action.payload as any
      if(!columnResizing) return state
      return {
        ...state,
        columnResizing: {
          ...state.columnResizing,
          ...columnResizing
        }
      }
    }

  }
}

const filterByOrder = (filter: any) => {
   const keys =  Object.keys(filter)
   keys.sort();
   return keys.reduce((acc,x) => {
      const val = filter[x]
      if(Array.isArray(val)) val.sort()
      return {
        ...acc,
        [x]: val
      }
   },{})
}



const useTableContext = (dataEntry: any) => {
  const {
    modelName,
    columns: entryColumns = emptyArray,
    extendedColumns = emptyArray,
    fnFilters,
    requestOptions: optionsEntry = emptyObject,
    includeModelMap,
    hideColumnVisibility = false,
    filter: _filter = emptyObject,
    page = 0,
    sort,
    perPage,
    isExportCsv = true,
    isGlobalSearch = false,
    tableSearchTooltip = 'Search by asin / mpn',
    additional: entryAdditional = emptyObject,
    storageKey,
    tableSystemID,
    columnResizing: entryColumnResizing
  } = dataEntry
  const { filterNumberValue } = useFilterNumber(true)

  const dataReducerStartState = useRef({
    columns: [],
    savedFilters: {},
    page,
    perPage: perPage || 25,
    isNeedRefetch: 1,
    filter: filterByOrder(_filter),
    sort,
    additional: entryAdditional,
    hideColumnVisibility,
    isExportCsv,
    isGlobalSearch,
    tableSearchTooltip,
    tableSystemID,
    columnResizing: entryColumnResizing
  })
  const [state, dispatch] = useReducer(reducer, dataReducerStartState.current as TReducer)
  const options = useRef(optionsEntry)

  /*** entry columns changed **/
  useEffect(() => {
    const savedColumns = LocalStorage.getTableColumnSettings(storageKey)
    dispatch({
      type: TABLE_EVENTS.columnsChange,
      payload: entryColumns.map((c: any) => {
        const sv = savedColumns.find((s: any) => s.accessor === c.accessor) || {}
        return {
          ...c,
          ...sv
        }
      })
    })
  }, [entryColumns, dispatch, storageKey])

  useEffect(() => {
    const payload = LocalStorage.getTableColumnResizing(storageKey)
    dispatch({
      type: TABLE_EVENTS.setResizeColumns,
      payload
    })
  }, [dispatch, storageKey])

  const { columns, filter, additional, columnResizing } = state as any
  /** when filter is changed check the hash */
  useEffect(() => {
    dispatch({
      type: TABLE_EVENTS.checkCurrentFilterHash
    })
  }, [dispatch, filter])

  useEffect(() => {
    if (!storageKey || !columns.length) return
    const cc = columns.map((c: any) => pick(c, ["accessor", 'isVisible']))
    LocalStorage.setTableColumnSettings(storageKey, cc)
  }, [columns, storageKey])

  const getFilterByType = useCallback((fieldType: string, key: string, data: any) => {
    switch (fieldType) {
      default:
        return {
          [key]: { $like: `%${data}%` }
        }

      case 'value':
      case 'multiselection':
        return {
          [key]: data
        }
      case 'multiselectionNum':
        return {
          [key]: data.flat().map((x: any)=> Number(x))
        }
      case 'selection':
        return {
          [key]: data.value
        }

      case FieldColumnType.onlyDate: {

        const date =  new Date(data)
        date.setHours(12)
        const _date = date.toISOString()
        return {
          [key]: {
            $gte: _date,
            $lte: _date
          }
        }
      }

      case 'number':
        return {
          [key]: filterNumberValue(data)
        }
      case 'date':
        return {
          [key]: {
            $gte: startOfDay(data),
            $lte: endOfDay(data)
          }
        }

      case 'fn': {
        return merge({}, fnFilters, defaultFnFilters)?.[key]?.(data) || undefined
      }

    }
  }, [fnFilters, filterNumberValue])

  const getAllColumns = useCallback((cols: any) => {
    return cols.map((col: any) => {
      if (col?.accessor) return col
      return getAllColumns(col.columns)
    })
  }, [])

  const getAllColumnsWithIncludedModels = useCallback((cols: any) => {
    const _cols = getAllColumns(cols).flat()
    return _cols.map((x: any) => {
      let model = '' as any
      if (x?.includeModel) {
        const arr = x?.includeModel.split('.')
        model = arr.map((x: any) => includeModelMap?.[x]).join('.') as any
      }
      return {
        ...x,
        model: model ? model : undefined
      }
    })
  }, [getAllColumns, includeModelMap])

  const filtersFetchData = useCallback(() => {
    const array = Object.keys(filter).map(key => {
        const data = filter[key] || ''
        if (columns) {
          const _cols = getAllColumns(columns).flat()
          const column = _cols.find((f: any) => f.accessor === key || f.fieldName === key) || extendedColumns.find((f: any) => f.accessor === key || f.fieldName === key)
          if (column) {
            if (column.includeModel) return undefined
            if (column.accessor) key = column.fieldName ? column.fieldName : column.accessor
            if(column?.customFn) {
              return fnFilters?.[key]?.(data) || undefined
            }
            if (column?.fieldType !== "selection") {
              if (!`${data}`.length) return undefined
            }
            return getFilterByType(column?.fieldType, key, data)
          }
        }
        return  merge({},  defaultFnFilters,fnFilters)?.[key]?.(data) || undefined
      }
    )
    .filter(x => !!x)
    if (!array.length) return {}
    return {
      filter: {
        $and: array
      }
    }
  }, [filter, getFilterByType, fnFilters, getAllColumns, columns, extendedColumns])

  const filterIncludeModelsData = useCallback(() => {
    const { include } = options.current
    if (!include) return {}
    let array = Object.keys(filter).map(key => {
      if (!columns) return {}
      const column = columns.find((f: any) => f.accessor === key || f.fieldName === key) || extendedColumns.find((f: any) => f.accessor === key || f.fieldName === key)
      if (!column || !column.includeModel) return undefined
      const data = filter[key]
      return {
        model: column.includeModel,
        filter: getFilterByType(column.fieldType || 'string', column.fieldName || key, data)
      }
    }).filter(x => !!x) as any

    let _array = array.reduce((acc: any, d: any) => {
      const oldIndex = acc.findIndex((x: any) => x.model === d.model)
      if (oldIndex === -1) {
        return [
          ...acc,
          {
            model: d.model,
            filter: [d.filter]
          }
        ]
      }
      acc.splice(oldIndex, 1, {
        model: d.model,
        filter: [...acc[oldIndex].filter, d.filter]
      })
      return [...acc]
    }, []).map((x: any) => ({
      model: x.model.split('.'),
      filter: {
        $and: x.filter
      }
    }))

    const _include = _array.reduce((acc: any, oneModel: any) => {
      let stringFind = 'include'
      const mapIndex = oneModel.model.map((p: any) => {
        const pointer = get(acc, stringFind)
        if (!pointer) return -1
        const f = pointer.findIndex((hh: any) => hh.model === p || hh.as === p)
        if (f === -1) return -1
        stringFind = stringFind + `[${f}].include`
        return f
      }).filter((x: any) => x !== -1)

      if (mapIndex.length !== oneModel.model.length) return acc

      stringFind = `include[${mapIndex[0]}]`
      let obj = get(acc, stringFind)
      obj.required = true

      mapIndex.slice(1).forEach((x: any) => {
        stringFind = stringFind + `.include[${x}]`
        obj = get(acc, stringFind)
        obj.required = true
      })

      obj.filter = oneModel.filter
      return { ...acc }
    }, cloneDeep(options.current))
    return _include
  }, [filter, options, columns, getFilterByType, extendedColumns])

  const sortData = useCallback(() => {
    if (!state.sort) return
    const field = state.sort?.field
    const sort = {
      direction: state.sort.direction ? 'DESC' : 'ASC',
      field
    }
    const column = columns.find((f: any) => f.accessor === field || f.fieldName === field) || extendedColumns.find((f: any) => f.accessor === field || f.fieldName === field)
    if (column?.fieldName) {
      sort.field = column.fieldName
    }
    if (!column || !column.includeModel) return sort
    const models = column.includeModel.split('.').map((mod: string) => includeModelMap?.[mod] || mod)
    return {
      ...sort,
      model: [...models || field]
    }
  }, [columns, state, includeModelMap, extendedColumns])

  const requestOptions = useMemo(() => {
    const { page, perPage } = state
    const sort = state.sort ? {
      sort: sortData()
    } : {}
    const filterData = filtersFetchData()
    const filterDataInclude = filterIncludeModelsData()

    return merge({}, {
        perPage: perPage === -1 ? MAX_PER_PAGE : perPage || 25,
        page: perPage === -1 ? 1 : (page + 1) || 1,
      },
      { ...optionsEntry },
      filterData,
      filterDataInclude,
      sort,
      additional
    ) as any
  }, [state, additional, optionsEntry, filtersFetchData, sortData, filterIncludeModelsData])

  const setPage = useCallback((payload: number) => {
    dispatch({
      type: TABLE_EVENTS.setPage,
      payload
    })
  }, [dispatch])

  const setPerPage = useCallback((payload: number) => {
    dispatch({
      type: TABLE_EVENTS.setPerPage,
      payload
    })
  }, [dispatch])

  const visibleColumns = useMemo(() => { return columns.filter((x: any) => !!x.isVisible) }, [columns])

  const toggleVisibilityColumn = useCallback((column: string, isVisible?: boolean) => {
    dispatch({
      type: TABLE_EVENTS.changeVisibilityColumn,
      payload: { column, isVisible }
    })
  }, [dispatch])

  const setSortBy = useCallback((field: string, direction?: boolean) => {
    dispatch({
      type: TABLE_EVENTS.setSortBy,
      payload: {
        field,
        direction
      }
    })
  }, [dispatch])

  const setAdditional = useCallback((data: any) => {
    dispatch({
      type: TABLE_EVENTS.addAdditional,
      payload: data
    })
  }, [dispatch])

  const clearAdditional = useCallback(() => {
    dispatch({
      type: TABLE_EVENTS.clearAdditional
    })
  }, [dispatch])

  const clearSort = useCallback(() => {
    dispatch({
      type: TABLE_EVENTS.clearSort
    })
  }, [dispatch])

  const clearNeedRefetch = useCallback(() => {
    dispatch({
      type: TABLE_EVENTS.clearNeedRefetch,
    })
  }, [dispatch])

  const setFilter = useCallback((payload: TFilerPayload) => {
    dispatch({
      type: TABLE_EVENTS.addFilter,
      payload
    })
  }, [dispatch])

  const setMultiFilter = useCallback((payload: any)=> {
    dispatch({
      type: TABLE_EVENTS.addMultiFilter,
      payload
    })
  },[dispatch])

  const clearFilter = useCallback((field: string) => {
    dispatch({
      type: TABLE_EVENTS.clearFilter,
      payload: field
    })
  }, [dispatch])

  const setCurrentFilters = useCallback((payload: any[]) => {
    dispatch({
      type: TABLE_EVENTS.setCurrentTableFilters,
      payload
    })
  }, [dispatch])

  const fetchTableFilters = useCallback(async () => {
    if (!tableSystemID) return
    try {
      const data = await getSavedTableFilters(tableSystemID)
      dispatch({
        type: TABLE_EVENTS.setCurrentTableFilters,
        payload: data?.result || []
      })
    } catch (e) { }
  }, [tableSystemID, dispatch])

  const setColumnResizing = useCallback((data: any)=> {
    dispatch({
      type: TABLE_EVENTS.setResizeColumns,
      payload: data
    })
  },[dispatch])

  useEffect(() => {
    fetchTableFilters().then()
  }, [fetchTableFilters])


  const isCurrentFilterSavedOne = state.filterHash && !!state?.savedFilters?.[state.filterHash]

  const refCurrentFilter = useRef({ } as any )
  refCurrentFilter.current = {
    filter: filter,
    isCurrentFilterSavedOne,
    tableSystemID
  }



  const  saveFilterToBackend= useCallback(async (filterName: string)=> {
       if(!!refCurrentFilter.current.isCurrentFilterSavedOne || !Object.keys(refCurrentFilter.current.filter).length) return;
       await addTableFilter({
          tableFrontendId: refCurrentFilter.current.tableSystemID,
          filterName,
          filter: refCurrentFilter.current.filter
       })
      fetchTableFilters().then()
  },[refCurrentFilter, fetchTableFilters])

  const removeFilterToBackend= useCallback(async (id: number)=> {
    await removeTableFilter(id)
    fetchTableFilters().then()
  },[fetchTableFilters])


  const clearAllFilters = useCallback(() => {
       dispatch({
         type: TABLE_EVENTS.clearAllFilters,
       })
  },[dispatch])


  const reuseSavedFilter = useCallback((payload: string) => {
       dispatch({
         type: TABLE_EVENTS.setFilterFromSaved,
         payload
       })
  },[dispatch])

  const setRefetch = useCallback(()=> {
    dispatch({
      type: TABLE_EVENTS.setRefetch
    })
  },[dispatch])


  const getColumnWidth = useCallback((columnId: string)=> {
    if(!columnResizing) return
    return columnResizing?.[columnId]
  },[columnResizing])

  useEffect(()=> {
    if(!storageKey || !columnResizing) return
    LocalStorage.setTableColumnResizing(storageKey, columnResizing)
  },[columnResizing])


  return useMemo(() => {
    return {
      ...state,
      isCurrentFilterSavedOne: state.filterHash && !!state?.savedFilters?.[state.filterHash],
      visibleColumns,
      toggleVisibilityColumn,
      setPage,
      setPerPage,
      setSortBy,
      clearNeedRefetch,
      setFilter,
      clearFilter,
      requestOptions,
      hideColumnVisibility,
      clearSort,
      getAllColumns,
      setAdditional,
      clearAdditional,
      setCurrentFilters,
      getAllColumnsWithIncludedModels,
      saveFilterToBackend,
      clearAllFilters,
      reuseSavedFilter,
      removeFilterToBackend,
      setRefetch,
      setMultiFilter,
      setColumnResizing,
      getColumnWidth,
      modelName,
      optionsEntry
    }
  }, [
    modelName,
    toggleVisibilityColumn,
    state,
    visibleColumns,
    setPage,
    setPerPage,
    setSortBy,
    clearNeedRefetch,
    setFilter,
    clearFilter,
    requestOptions,
    hideColumnVisibility,
    clearSort,
    getAllColumns,
    setAdditional,
    clearAdditional,
    setCurrentFilters,
    getAllColumnsWithIncludedModels,
    saveFilterToBackend,
    clearAllFilters,
    reuseSavedFilter,
    removeFilterToBackend,
    setRefetch,
    setMultiFilter,
    setColumnResizing,
    getColumnWidth,
    optionsEntry
  ])
}

export type TTableDataContextType = ReturnType<typeof useTableContext>;

const TableDataContextContainer = ({
  children,
  columns,
  fnFilters,
  modelName,
  ...rest
}: React.PropsWithChildren<{
  columns: any[],
  fnFilters?: any,
  modelName: string
}>) => {

  const [searchParams, setSearchParams] = useSearchParams()

  const refSearchParams = useRef(searchParams)
  refSearchParams.current = searchParams

  const { perPage: defaultPerPage = 25, filter: defaultFilter = {} } = rest as any

  const { startFilters, startPage, startLimit, startSort, startAdditional } = useMemo(() => {
    let params = Object.fromEntries(searchParams)
    let filter = params.filter as any
    if (filter) {
      filter = queryString.parse(filter) as any
      filter = Object.keys(filter).reduce((acc, key: string) => {
        const column = columns.find(s => s.accessor === key)
        const value = filter[key]
        if ((column?.fieldType === FieldColumnType.multiselection || column?.fieldType === FieldColumnType.multiselectionNum ) && !Array.isArray(value)) {
          return {
            ...acc,
            [key]: [value]
          }
        }
        return acc
      }, filter)
    }

    let sort = params.sort as any
    if (sort) {
      sort = queryString.parse(sort) as any
      sort.direction = sort.direction === 'false' ? false : true
    }
    let additional = params.additional as any
    if (additional) {
      additional = queryString.parse(additional) as any
    }
    return {
      startFilters: {...(filter || {}),...(defaultFilter || {})},
      startPage: (Number(params.page) || 1) - 1,
      startLimit: (Number(params.limit)) || defaultPerPage,
      startSort: sort || undefined,
      startAdditional: additional || undefined
    }
  }, [searchParams, defaultPerPage, defaultFilter, columns])

  const _columns = useMemo(() => [...columns].map(x => ({
    ...x,
    isVisible: x?.isVisible === undefined ? true : x?.isVisible,
    isSortedDesc: false
  })), [columns])


  const providerData = useTableContext({
    columns: _columns,
    fnFilters,
    modelName,
    ...rest,
    page: startPage,
    perPage: startLimit,
    filter: startFilters,
    sort: startSort,
    additional: startAdditional
  });

  const { filter, page, perPage, sort, additional } = providerData

  useEffect(() => {
    const oldParams = pick(Object.fromEntries(refSearchParams.current), ["filter", "sort", "page", "limit"])
    let _params = {}
    if (page !== 0) {
      _params = {
        ..._params,
        page: `${page + 1}`,
      }
    }

    if (perPage !== defaultPerPage) {
      _params = {
        ..._params,
        limit: `${perPage}`,
      }
    }

    if (filter && Object.keys(filter).length) {
      _params = {
        ..._params,
        filter: queryString.stringify(filter)
      } as any
    }

    if (sort && Object.keys(sort).length) {
      _params = {
        ..._params,
        sort: queryString.stringify(sort)
      } as any
    }

    if (additional && Object.keys(additional).length) {
      _params = {
        ..._params,
        additional: queryString.stringify(additional)
      }
    }

    if (deepEqual(oldParams, _params)) return
    // @ts-ignore
    setSearchParams({
      ...omit(Object.fromEntries(refSearchParams.current), ["filter", "sort", "additional", "page", "limit"]),
      ..._params
    })
  }, [filter, perPage, page, sort, additional, setSearchParams, refSearchParams, defaultPerPage])

  return (
    <TableDataContext.Provider value={providerData}>
      {children}
    </TableDataContext.Provider>
  );
};

export default TableDataContextContainer;
