import styled             from "styled-components";
import { Flex }           from "style";
import {
    List,
    ListItemButton,
    ToggleButtonGroup,
    ToggleButton,
    Box,
    Chip,
    Button
} from "@mui/material";
import { transparentize }    from "polished";
import {
    blue
}                            from "@mui/material/colors";
import { InputNumberSearch } from "components/Table/TableHeader/FilterSortCell/FilterInputNumber";
import SelectText            from "components/Select";
import ButtonIcon from "components/Button/ButtonIcon";
import {
    ShopifyHeaderContainer,
    FontAwesomeIconShopify
} from "components/TableHeaders/ShopifyHeader/style";
import {
    OptiScoreHeaderContainer,
    FlagHeader,
    FontAwesomeIcon
} from "components/TableHeaders/OptiscoreHeader/style";


export const FilterSortColumnContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 260px;
  max-width: 260px;
  padding: 10px;
  
  
  .MuiDivider-root {
    width: 100%;
  }
`

export const FilterSortHeader = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  position: relative;
  min-width: 50px;
`

export const FilterSortTitle = styled(Flex)`
  font-weight: 500;
  text-transform: uppercase;
  min-width: 50px;
  
  ${OptiScoreHeaderContainer} {
        display: flex;
        flex-direction: row-reverse;
        height: 100%;
       
       ${FontAwesomeIcon}{
           position: relative;
           top: auto;
           left: auto;
       }
       
        ${FlagHeader}{
            margin-right: 10px;
            position: relative;
            top: auto;
            left: auto;
        }
   }
   ${ShopifyHeaderContainer}{
       display: flex;
       justify-content: center;
       align-content: center;
       height: 100%;
       
       ${FontAwesomeIconShopify}{
           position: relative;
           left: auto;
           top: auto;
       }
   }
`

export const ClosePopupIcon = styled(ButtonIcon).attrs({
    size: 'small'
})`

`

export const SortClearButton = styled(Button).attrs({
    color: 'inherit',
    size: 'small',
    variant: 'outlined'
})`
`

export const SortListContainer = styled(List)`
  &&& {
    width: 100%;
    position: relative;
    padding-top: 20px;

    .MuiListItemText-root {
      padding-left: 25px;
    }

    .MuiSvgIcon-root {
      width: 1em;
      height: 0.7em;
    }
  }
`

export const SortListItemButton = styled(ListItemButton)`

  &.MuiListItemButton-root{
    &.Mui-selected {
      background-color: ${transparentize(0.80,blue["700"])};
    }
  }

`


export const FilterFormContainer = styled(Flex)`
  padding: 10px 0px;
  flex-direction: column;
  width: 100%;
`

export const FilterFormPart = styled(Flex)`
  padding: 5px 10px;
  width: 100%;
  justify-content: center;
  
  .MuiFormControl-root {
    width: 100%;
  }
`

export const InputNumberContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`

export const InputNumberButtonsContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const InputNumberToggleButtonsGroup = styled(ToggleButtonGroup)`

`

export const InputNumberOperationLabel = styled(Flex)`
    font-weight: 400;
`

export const InputNumberToggleButton = styled(ToggleButton)`
  &&& {
    padding: 0;
    width: 25px;
    height: 25px;
  }
`

export const InputNumberSearchContainer = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

export const InputNumberSearchOperation = styled(Flex)`
  min-width: 20px;
  max-width: 20px;
`

export const InputNumberPart = styled(InputNumberSearch)`
  flex: 2;
`

export const FilterSelectContainer = styled(SelectText)`
  &&& {
    margin-top: 16px;
  }
`


export const FilterMultiSelectContainer = styled(SelectText)`
  &&& {
    .MuiSelect-select {
      width: 100%;
      padding-left: 0;
      padding-bottom: 2px;
      min-height: 60px;
      max-width: 230px;
    }
  }
`


export const FilterMultiSelectSelectedContainer = styled(Box)`
 &&& {
   display: flex;
   justify-content: flex-start;
   flex-wrap: wrap;
   max-height: 60px;
   padding: 0 4px 0 4px;
   width: 100%;
   overflow-y: auto;
 }
`

export const MultiSelectChip = styled(Chip)`
  &&& {
    margin: 0 3px 4px 0;
    padding: 0;
    min-width: 80px;
    max-width: 80px;
    max-height: 25px;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
`
