import { CASiteIds } from "def/index";

export enum MARKET_PLACE_SHIPPING_STATUS_VALUES  {
    Pending= 0,
    InProgress= 10,
    Complete= 30,
    CompletedWithoutNotification= 40,
    Failed= -1
}

export enum CAOrderShippingStatusValues  {
    Unshipped = 'Unshipped',
    Shipped = 'Shipped',
    PartiallyShipped = 'PartiallyShipped',
    PendingShipment = 'PendingShipment',
    Canceled = 'Canceled',
    ThirdPartyManaged = 'ThirdPartyManaged'
}

export const CAOrderCheckoutStatus = [
    { label: 'NotVisited', description: 'NotVisited', value: 'NotVisited' },
    { label: 'Completed', description: 'Completed', value: 'Completed' },
    { label: 'Visited', description: 'Visited', value: 'Visited' },
    { label: 'Disabled', description: 'Disabled', value: 'Disabled' },
    { label: 'Completed&Visited', description: 'CompletedAndVisited', value: 'CompletedAndVisited' },
    { label: 'CompletedOffline', description: 'CompletedOffline', value: 'CompletedOffline' },
    { label: 'OnHold', description: 'OnHold', value: 'OnHold' }
]

export const CAOrderPaymentStatus = [
    { label: 'Not Submitted', description: 'NotYetSubmitted', value: 'NotYetSubmitted' },
    { label: 'Cleared', description: 'Cleared', value: 'Cleared' },
    { label: 'Submitted', description: 'Submitted', value: 'Submitted' },
    { label: 'Failed', description: 'Failed', value: 'Failed' },
    { label: 'Deposited', description: 'Deposited', value: 'Deposited' }
]

export const CAOrderShippingStatus = [
    { label: 'Unshipped', description: 'Unshipped', value: CAOrderShippingStatusValues.Unshipped },
    { label: 'Shipped', description: 'Shipped', value: CAOrderShippingStatusValues.Shipped },
    { label: 'Partially', description: 'PartiallyShipped', value: CAOrderShippingStatusValues.PartiallyShipped },
    { label: 'Pending', description: 'PendingShipment', value: CAOrderShippingStatusValues.PendingShipment },
    { label: 'Canceled', description: 'Canceled', value: CAOrderShippingStatusValues.Canceled },
    { label: '3rd Party', description: 'ThirdPartyManaged', value: CAOrderShippingStatusValues.ThirdPartyManaged }
]

export const FULFILMENT_TYPE = [
    { label: 'Ship', value: 'Ship' },
    { label: 'Pickup', value: 'Pickup' },
    { label: 'ShipToStore', value: 'ShipToStore' },
    { label: 'Courier', value: 'Courier' }
]

export const DELIVERY_STATUS = [
    { label: 'NoChange', value: 'NoChange' },
    { label: 'InTransit', value: 'InTransit' },
    { label: 'ReadyForPickup', value: 'ReadyForPickup' },
    { label: 'Complete', value: 'Complete' },
    { label: 'Canceled', value: 'Canceled' },
    { label: 'ThirdPartyManaged', value: 'ThirdPartyManaged' },
    { label: 'Confirmed', value: 'Confirmed' },
    { label: 'PickAndPack', value: 'PickAndPack' },
    { label: 'LabelCreated', value: 'LabelCreated' }
]

export const MARKET_PLACE_SHIPPING_STATUS = [
    { label: 'Pending', value: 'Pending' },
    { label: 'InProgress', value: 'InProgress' },
    { label: 'Complete', value: 'Complete' },
    { label: 'CompletedWithoutNotification', value: 'CompletedWithoutNotification' },
    { label: 'Failed', value: 'Failed' }
]

export const CaSiteAccounts = [
    { id: 17179, siteName: 'Amazon Seller Central - US', siteId: CASiteIds.AMAZON, siteAccountName: 'Syracuse Unlimited', siteAccShort: 'SU'},
    { id: 17526, siteName: 'Amazon Seller Central - US', siteId: CASiteIds.AMAZON, siteAccountName: 'Sammy\'s Club', siteAccShort: 'Sammy'},
    { id: 20441, siteName: 'Amazon Seller Central - US', siteId: CASiteIds.AMAZON, siteAccountName: 'C.&.H', siteAccShort: 'C.&.H'},
    { id: 20469, siteName: 'Amazon Seller Central - US', siteId: CASiteIds.AMAZON, siteAccountName: 'KD\'s', siteAccShort: 'KD\'s'},
    { id: 17714, siteName: 'Amazon Seller Central - US', siteId: CASiteIds.AMAZON, siteAccountName: 'Pacific Office Gear', siteAccShort: 'POG'},
    { id: 17131, siteName: 'eBay US Auction', siteId: CASiteIds.EBAY_AUCTION, siteAccountName: 'eBay US Auction 1 (stockpileusa)', siteAccShort: 'eBay US Auction 1'},
    { id: 17132, siteName: 'eBay US Fixed Price', siteId: CASiteIds.EBAY, siteAccountName: 'eBay US Fixed Price', siteAccShort: 'eBay US Fixed Price 1'},
    { id: 18832, siteName: 'eBay US Motors Auction', siteId: CASiteIds.EBAY_MOTORS_AUCTION, siteAccountName: 'eBay US Motors Auction 1 (stockpileusa)', siteAccShort: 'eBay US Motors Auction 1'},
    { id: 17348, siteName: 'Shopify', siteId: CASiteIds.SHOPIFY, siteAccountName: 'Shopify 1 (scanprint)', siteAccShort: 'scanprint'},
    { id: 17745, siteName: 'Shopify POS', siteId: CASiteIds.SHOPIFY_POS, siteAccountName: 'Shopify POS (scanprint)', siteAccShort: 'Shopify POS scanprint'},
    { id: 19907, siteName: 'Walmart Marketplace', siteId: CASiteIds.WALMART, siteAccountName: 'Walmart Marketplace 2', siteAccShort: 'Walmart Marketplace 2'},
    { id: 19776, siteName: 'Walmart Marketplace', siteId: CASiteIds.WALMART, siteAccountName: 'Walmart US (43da086c-9636-4587-a85b-ff2ddb078bab)', siteAccShort: 'Walmart US'},
    { id: 17472, siteName: 'Newegg', siteId: CASiteIds.NEW_EGG, siteAccountName: 'Newegg', siteAccShort: 'Newegg'},
    { id: 17471, siteName: 'Newegg Business', siteId: CASiteIds.NEW_EGG_BUSINESS, siteAccountName: 'Newegg Business 1 (V1F8)', siteAccShort: 'Newegg Business 1'},
]

export const CaSiteAccountsOptions = [
    { "label": "SU", "value": 17179 },
    { "label": "Sammy", "value": 17526 },
    { "label": "C.&.H", "value": 20441 },
    { "label": "KD's", "value": 20469 },
    { "label": "POG", "value": 17714 },
    { "label": "eBay US Auction 1", "value": 17131 },
    { "label": "eBay US Fixed Price 1", "value": 17132 },
    { "label": "eBay US Motors Auction 1", "value": 18832 },
    { "label": "scanprint", "value": 17348 },
    { "label": "Shopify POS scanprint", "value": 17745 },
    { "label": "Walmart Marketplace 2", "value": 19907 },
    { "label": "Walmart US", "value": 19776 },
    { "label": "Newegg", "value": 17472 },
    { "label": "Newegg Business 1", "value": 17471 }
]
