import React, {useMemo} from 'react'
import {
    BlueStarLocationRow,
    IngramDataProductNotAuthorized,
    IngramLocationPart,
    IngramRow,
    IngramVendorPartNumber,
    LocationOneRowContainer,
    LocationRow,
    UnitsLocationContainer,
    UnitsLocationContent,
    UnitsLocationContentHeader,
    UnitsLocationHeader,
    UnitsLocationHeaderMpn,
    UnitsLocationHeaderTitle,
    UnitsLocationRow
} from "pages/Legacy/Order/365Plus/UnitsLocations/style";
import {AppDivider} from "style";
import {sheetNamesCategory} from "def";
import {formatPrice} from "Utils";

const UnitLocationComponent = ({title, data}: any) => {
    if (!data.length) return null
    return (
        <>
            <UnitsLocationContentHeader>{title}</UnitsLocationContentHeader>
            {data.map((unit: any, index: number) => <UnitsLocationRow
                key={index}>{unit.sheetName}{unit.sheetQty ? ` - ${unit.sheetQty}` : ''}{unit.grade ? ` - ${unit.grade}` : ''}</UnitsLocationRow>)}
        </>
    )
}

const IngramProductShow = ({label = 'Ingram', ingram, isInvalid, isReplacement}: any) => {

    const [ingramPrice, ingramQty, ingramEta, ingramNotAuthorized, vendorPartNumber, ingramPartNumber] = useMemo(() => [
        ingram?.customerPrice ? `(${formatPrice(ingram?.customerPrice)})` : '($0)',
        ingram?.totalAvailability ? `(${ingram?.totalAvailability})` : '',
        !ingram?.totalAvailability && (ingram?.etaQuantity || ingram?.etaTime) ? {
            etaTime: `(${new Date(ingram.etaTime).toLocaleDateString()})`,
            etaQuantity: `(${ingram.etaQuantity})`
        } as any : undefined,
        !ingram?.isAuthorized,
        ingram?.vendorPartNumber,
        ingram?.ingramPartNumber
    ], [ingram])

    return (
        <LocationOneRowContainer isColumn={isReplacement}>
            <LocationRow isInvalid={isInvalid}>
                <IngramRow>
                    <div>{label}</div>
                    {ingramQty ? <div>{ingramQty}</div> : null}
                    <div>{ingramPrice}</div>
                    {!ingramQty && ingramEta ?
                        <>
                            <div>{ingramEta?.etaTime}</div>
                            <div>{ingramEta?.etaQuantity}</div>
                        </>
                        : null}
                </IngramRow>
                {ingramNotAuthorized && <IngramDataProductNotAuthorized/>}
            </LocationRow>
            {isReplacement && <IngramVendorPartNumber>{vendorPartNumber} / {ingramPartNumber}</IngramVendorPartNumber>}
        </LocationOneRowContainer>
    )
}

const IngramLocation = ({row}: any) => {

    const ingramProduct = useMemo(() => row.original.ingramProduct, [row])

    return (
        <IngramLocationPart>
            <UnitsLocationContentHeader>Ingram</UnitsLocationContentHeader>
            <IngramProductShow label={'Prod.'} ingram={ingramProduct?.items?.[0]} isInvalid={!ingramProduct?.isValid}/>
            {ingramProduct?.items?.[0]?.replacement ?
                <IngramProductShow isReplacement label={'Rep.'}
                                   isInvalid={!ingramProduct?.items?.[0].replacement?.active && !!ingramProduct?.items?.[0].replacement.isDiscontinued}
                                   ingram={ingramProduct?.items?.[0].replacement}/>
                : null}
        </IngramLocationPart>
    )
}

const UnitsLocations = ({row}: any) => {


    const [isSecured, isUnsecured, unitData, unitsLocation, mpn, isIngram, blueStar] = useMemo(() => {

        let qtyBySheet = row.original.qtyBySheet || []
        return [
            row.original?.isSecured,
            row.original?.isUnsecured,
            (() => {
                return qtyBySheet.reduce((acc: any, q: any) => {
                    const data = {
                        ...q,
                        sheetName: sheetNamesCategory.find(x => x.value === q?.sheetId)?.sheet || ''
                    }
                    if (q?.isSecured) {
                        acc.sec.push(data)
                    }
                    if (q?.isUnsecured) {
                        acc.unsec.push(data)
                    }
                    return acc
                }, {
                    sec: [],
                    unsec: []
                } as any) as any
            })(),
            (() => {
                const arr = []
                if (row?.original?.caQuantity) arr.push(`CA - ${row?.original?.caQuantity}`)
                return arr
            })(),
            row.original.mpn,
            row.original?.ingramProduct,
            (() => {
                if (!row?.original?.blueStarQty && !row?.original?.blueStarPrice) return
                return {
                    qty: row?.original?.blueStarQty,
                    price: formatPrice(row?.original?.blueStarPrice)
                }
            })()
        ]
    }, [row])

    return (
        <UnitsLocationContainer>
            <UnitsLocationHeader>
                <UnitsLocationHeaderTitle>Units Location</UnitsLocationHeaderTitle>
                <UnitsLocationHeaderMpn>{mpn}</UnitsLocationHeaderMpn>
            </UnitsLocationHeader>
            <AppDivider/>
            <UnitsLocationContent>
                {isSecured ? <UnitLocationComponent title={'Secured'} data={unitData.sec}/> : <></>}
                {isUnsecured ? <UnitLocationComponent title={'Unsecured'} data={unitData.unsec}/> : <></>}
                {isIngram ? <IngramLocation row={row}/> : <></>}
                {unitsLocation.length ? <><UnitsLocationContentHeader>Other</UnitsLocationContentHeader>
                    {blueStar ? (
                        <BlueStarLocationRow>
                            <div>BlueStar</div>
                            <div>({blueStar.qty || 0})</div>
                            <div>({blueStar.price})</div>
                        </BlueStarLocationRow>
                    ) : <></>}
                    {unitsLocation.map((location: any, index: number) => <UnitsLocationRow
                        key={index}>{location}</UnitsLocationRow>)}
                </> : <></>}
            </UnitsLocationContent>
        </UnitsLocationContainer>
    )
}

export default UnitsLocations
