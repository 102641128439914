import OptiScore from "pages/Legacy/Marketing/components/OptiScore";
import React from "react";
import AmazonLink from "pages/Legacy/Marketing/components/AmazonLink";
import TdCellEllipsis from "components/Table/TableCells/TdCellEllipsis";
import {formatPrice, formatQuantity} from "Utils";
import {FieldColumnType} from "components/Table/TableHeader/FilterSortCell/d";
import {PriceHeader, SmallHeader,} from "./style";
import OptiscoreHeader from "components/TableHeaders/OptiscoreHeader";
import BasePrice from "pages/Legacy/components/BasePrice";
import Bsr from "pages/Legacy/components/Bsr";
import RestrictedFields from "pages/RestrictedTableFields";


export const requestOptions = {
    sort: {
        field: 'rowIndex',
        direction: 'ASC'
    },
}

// @ts-ignore
export const tableColumns = [

    {
        Header: 'BSR',
        accessor: 'amazonBsr',
        canSort: true,
        canFilter: true,
        align: 'center',
        fieldType: FieldColumnType.number,
        style: {
            minWidth: 75,
            width: 75
        },
        headerStyle: {
            paddingLeft: 10
        },
        Cell: (props: any) => <Bsr row={props.row}/>//getAmazonBsr(props.value)
    },
    ...RestrictedFields,
    {
        Header: <OptiscoreHeader code={"USA"}/>,
        HeaderExport: 'OptiScore US',
        accessor: 'amazonOptiScore',
        canSort: true,
        canFilter: true,
        fieldType: FieldColumnType.multiselection,
        style: {
            minWidth: 55,
            width: 55,
        },
        Cell: (props: any) => <OptiScore row={props.row}/>
    },
    {
        Header: <OptiscoreHeader code={"CAN"}/>,
        HeaderExport: 'OptiScore CA',
        accessor: 'amazonOptiScoreCA',
        canSort: true,
        canFilter: true,
        fieldType: FieldColumnType.multiselection,
        style: {
            minWidth: 55,
            width: 55
        },
        Cell: (props: any) => <OptiScore isCa row={props.row}/>
    },
    {
        Header: 'ASIN',
        accessor: 'asin',
        canFilter: true,
        align: 'center',
        style: {
            minWidth: 110,
            maxWidth: 110,
            textAlign: 'center'
        },
        canSort: true,
        Cell: (props: any) => <AmazonLink value={props.value} row={props.row}/>
    },

    {
        Header: <SmallHeader>HA QTY</SmallHeader>,
        HeaderExport: 'QTY',
        accessor: 'quantity',
        canSort: true,
        canFilter: true,
        fieldType: FieldColumnType.number,
        align: 'center',
        style: {
            width: 60,
            minWidth: 60,
        },
        Cell: (props: any) => props.value !== '0' ? formatQuantity(props.value) : ''
    },

    {
        Header: <SmallHeader>Sold</SmallHeader>,
        HeaderExport: 'QTY',
        accessor: 'quantitySold',
        canSort: true,
        canFilter: true,
        fieldType: FieldColumnType.number,
        align: 'center',
        style: {
            width: 60,
            minWidth: 60,
        },
        Cell: (props: any) => props.value !== '0' ? formatQuantity(props.value) : ''
    },

    {
        Header: <SmallHeader>Q.Left</SmallHeader>,
        HeaderExport: 'QTY',
        accessor: 'quantityLeft',
        canSort: true,
        canFilter: true,
        fieldType: FieldColumnType.number,
        align: 'center',
        style: {
            width: 60,
            minWidth: 60,
        },
        Cell: (props: any) => props.value !== '0' ? formatQuantity(props.value) : ''
    },

    {
        Header: <PriceHeader>Min Price</PriceHeader>,
        HeaderExport: 'Min Price',
        accessor: 'minPrice',
        canSort: true,
        canFilter: true,
        fieldType: FieldColumnType.number,
        align: 'right',
        style: {
            width: 90,
            minWidth: 90,
        },
        Cell: (props: any) => <BasePrice value={props.value} row={props.row}/>
    },

    {
        Header: <PriceHeader>LP Price</PriceHeader>,
        HeaderExport: 'LP Price',
        accessor: 'amazonFirstPrice',
        canSort: true,
        canFilter: true,
        fieldType: FieldColumnType.number,
        align: 'right',
        style: {
            width: 90,
            minWidth: 90,
        },
        Cell: (props: any) => <BasePrice value={props.value} row={props.row}/>
    },
    {
        Header: 'MPN',
        accessor: 'mpn',
        canSort: true,
        canFilter: true,
        style: {
            minWidth: 140,
        },
        Cell: (props: any) => <TdCellEllipsis {...props} />
    },
    {
        Header: 'Model',
        accessor: 'modelNumber',
        canSort: true,
        canFilter: true,
        style: {
            minWidth: 120
        },
        Cell: (props: any) => <TdCellEllipsis {...props} />
    }

]
