import React, { useMemo }    from 'react'
import { EbayLinkContainer } from "pages/Legacy/Marketing/components/EbayLink/style";


const EbayLink = ({ value }: { value: string }) => {

    const [epid,link] = useMemo(()=> [
        value,
        value ? `https://www.ebay.com/itm/${value}` : ''
    ], [value])

    if(!epid) return <div></div>

    return (
     <EbayLinkContainer href={link} target={'_blank'}>
         {epid}
     </EbayLinkContainer>
    )
}

export default EbayLink
