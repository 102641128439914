import styled, { css } from "styled-components";
import { Flex } from "style";
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';

export const CategoryContainer = styled(Flex)<{isHovered?: boolean}>`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  
  ${props => props.isHovered && css`
    cursor: pointer;
  `}
`

export const CategoryContainerHasHover = styled(HighlightAltIcon)`
  &&& {
    font-size: 10px;
    opacity: 0.4;
    position: absolute;
    bottom: 2px;
    left: 0;
  }
`