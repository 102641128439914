import {
    API,
    rootApi
} from "api/index";

export const getPermissions = async () => {
    return (await API.get(`${rootApi}/users/getFrontedPermissions`))?.data?.result || {}
}


export const updateAccessRolePermission = async (data: any) => {
  return (await API.post(`${rootApi}/access-role/accessRolePermissionActiveInactive`, {...data}))
}

export const updateAccessRoleRoute = async (data: any) => {
    return (await API.post(`${rootApi}/access-role/accessRoleRouteActiveInactive`, {...data}))
}

export const updateAccessUserRoute = async (data: any) => {
  return (await API.post(`${rootApi}/users/changeAccessRouteActivity`, {...data}))
}

export const updateAccessUserPermission = async (data: any) => {
  return (await API.post(`${rootApi}/users/changeAccessPermissionActivity`, {...data}))
}
