import styled              from "styled-components";
import {TablePagination}            from "@mui/material";
import { Flex } from "style";

export const TablePaginationContainer = styled(TablePagination).attrs({component: "div"})`
  
  &&& {
    min-width: 360px;
    max-height: 40px;
    
    > div {
      max-height: 40px;
      min-height: 40px;
      padding-left: 0;
    }
    
  }
  
  
`


export const TablePaginationEmptyContainer = styled(Flex)`
  min-width: 380px;
`
