import styled, { css } from "styled-components";
import { Flex }        from "style";
import {
    Box,
    Tab,
    Tabs
}                      from "@mui/material";
import { blue }        from "@mui/material/colors";

export const TabContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(100% - 50px);
  width: 100%;
  padding: 5px 10px;
`

export const TabHeaderContainer = styled(Box)`
  &&& {
    background-color: #f1f3f4;
    border-top: 1px solid #eee;
    display: inline-flex;
    margin-top: 0;
    min-height: 40px;
    padding: 0 3px;
    width: 100%;
  }
`;

export const MaterialTabs = styled(Tabs).attrs({ variant: "scrollable" })`
  &&& {
    background-color: #f1f3f4;
    padding: 0 5px;
  }
`

export const TabHeaderTab = styled(Tab)`
  &&& {
    border: 1px solid #e8eaed;
    border-bottom: none;
    border-radius: 0;
    border-top: none;
    color: #222;
    flex: initial;
    font-size: 13px;
    font-weight: 500;
    height: 39px;
    min-width: 90px;
    outline: 3px solid #0000;
    text-transform: none;
    width: auto;

    &:hover {
      background-color: rgb(255 255 255 / 69%);
      box-shadow: 0px -4px 5px -4px black;
    }

    &.Mui-selected {
      color: ${blue["700"]};
      background-color: #fff;
      border-top-color: #f1f3f4;
      box-shadow: 0 1px 3px 1px #3c404326;
    }
  }
`

export const TabsContainer = styled(Flex)<{ isTopHeader?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  ${props => !props.isTopHeader && css`
    flex-direction: column-reverse;
  `}
`

export const TabPanelContainer = styled.div`
  flex: 2;
  height: 100%;
  width: 100%;
`
