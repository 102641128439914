import styled, { css } from "styled-components";
import { Flex } from "style";
import { grey } from "@mui/material/colors";
import InputComponent from "components/Input";
import Button from '@mui/material/Button';

export const Container = styled(Flex)`
  flex-direction: column;
`

export const FilterData = styled(Flex)`
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 25px 10px;

`

export const ContainerOneFilter = styled(Flex)`
  flex-direction: column;
  flex: 1;
  max-width: 40%;
  min-width: 120px;
  text-align: center;
`

export const FilterName = styled.div`
  font-weight: bold;
  padding: 6px 4px;
  background-color: #1976d2;
  color: #fff;
  text-align: center;
  border-radius: 4px;
`

export const FilterValues = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid ${grey[200]};
`

export const FilterValue = styled(Flex)`
  padding: 4px 8px;
  width: 100%;
  align-items: center;
  justify-content: center;

  &:nth-child(even) {
    background: rgba(227, 242, 253, 0.6) !important
  }
`

export const SaveFilterContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`

export const InputFilter = styled(InputComponent)`
  width: 100%;
  padding: 10px 25px;
  max-width: 400px;
`

export const CanBeSavedButton = styled(Button)`
  &&& {
    margin-top: 20px;
  }
`

export const SavedButton = styled(Button)`
  &&& {
    margin-top: 20px;
  }
`
