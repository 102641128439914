import React                  from 'react'
import { TTabHeaderProps }    from "components/Tabs/d";

import {
    TabHeaderContainer,
    TabHeaderTab,
    MaterialTabs
} from "components/Tabs/style";

const TabHeader = ({ tabs, value, onChange }: TTabHeaderProps) => {

    if(!tabs?.length) return <></>

    return (
        <TabHeaderContainer >
            <MaterialTabs  value={value} onChange={onChange}>
                {tabs.map((tab, index) => {
                        const id = `${tab.id}-${index}`
                        return <TabHeaderTab
                            key={id}
                            label={tab.label}
                            id={id}
                            aria-controls={`${tab.id}-tabpanel-${index}`}
                        />
                    }
                )}
            </MaterialTabs>
        </TabHeaderContainer>
    )
}

export default TabHeader
