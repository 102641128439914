


export default {
    WHITE: '#fff',
    BLACK: '#000000',
    PALETTE: {
        WHITE: {
            _50: '#fefefe',
        },
        RED: {
            _50: '#FFEBEE',
            _100: '#FFCDD2',
            _200: '#EF9A9A',
            _300: '#E57373',
            _400: '#EF5350',
            _500: '#F44336',
            _600: '#E53935',
            _700: '#D32F2F',
            _800: '#C62828',
            _900: '#B71C1C',
        },
        GRAY: {
            _50: '#FAFAFA',
            _100: '#F5F5F5',
            _200: '#EEEEEE',
            _300: '#E0E0E0',
            _400: '#BDBDBD',
            _500: '#9E9E9E',
            _600: '#757575',
            _700: '#616161',
            _800: '#424242',
            _900: '#212121',
            _950: '#1C1C1E',
        },
        BLUE: {
            _50: '#E3F2FD',
            _100: '#B3E5FC',
            _200: '#90CAF9',
            _300: '#64B5F6',
            _400: '#42A5F5',
            _500: '#2196F3',
            _600: '#1E88E5',
            _700: '#1976D2',
            _800: '#1565C0',
            _900: '#0D47A1',
        },
        CYAN: {
            _50: '#E0F7FA',
            _300: '#4DB6AC',
            _500: '#26A69A',
            _800: '#00838F',
            _900: '#006064',
        },
        INDIGO: {
            _50: '#E8EAF6',
            _700: '#303F9F',
            _900: '#1A237E',
        },
        GREEN: {
            _50: '#E8F5E9',
            _400: '#66BB6A',
            _600: '#43A047',
            _700: '#388e3c',
            _800: '#2E7D32',
            _900: '#1B5E20',
        },
        ORANGE: {
            _300: '#FFB74D',
            _500: '#FF9800',
            _800: '#EF6C00',
        },
        LIGHT_BLUE: {
            _25: '#e7f8ff',
            _50: '#E1F5FE',
            _100: '#B3E5FC',
            _200: '#81D4FA',
            _300: '#4FC3F7',
            _400: '#29B6F6',
            _700: '#0288D1',
            _800: '#0277BD',
            _900: '#01579B',
        },
        BLUE_GRAY: {
            _50: '#ECEFF1',
            _100: '#CFD8DC',
            _200: '#B0BEC5',
            _300: '#90A4AE',
            _500: '#607D8B',
            _800: '#37474F',
        },
        BLUE_DARK: {
            _700: '#2C4758',
        },
    },
}
