import styled, { css } from "styled-components";
import { Flex } from "style";
import TableSearch from "components/Table/Search";
import { CircularProgress } from "@mui/material";

export const TableFooterContainer = styled(Flex)<{ isEnd?: boolean }>`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 2px;
  min-height: 45px;
  ${props => props.isEnd && css`
    justify-content: flex-end;
  `}
`

export const LeftPart = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
`

export const RightPart = styled(Flex)`
  position: relative;
  justify-content: flex-end;
  align-items: center;
  padding-left: 30px;
`

export const TableMiniProgress = styled(CircularProgress).attrs({
  size: '20px'
})`
  position: absolute;
  left: 5px;
`

export const StyledTableSearch = styled(TableSearch)`
  margin-left: 20px;
`
