import styled, { css } from "styled-components";
import InputText       from "components/Input";
import SelectText          from "components/Select";
import { Flex } from "style";
import ButtonIconComponent from "components/Button/ButtonIcon";
import { blue } from "@mui/material/colors";

export const TDefaultColumnFilter = styled(InputText)`
  background-color: white;
  width: 100%;
`

export const TSelectColumnFilter = styled(SelectText)<{labelIcon?: boolean}>`
  background-color: white;
  width: 100%;
  
    ${props => props.labelIcon && css`
      .MuiListItemText-root {
        display: none;
      }
    `}
`


export const FiltersContainer = styled(Flex)`
    position: absolute;
    bottom: 5px;
    right: 5px;
`


export const FilterButton = styled(ButtonIconComponent)`
  &&& {
    background-color: ${blue['800']};
    color: #fff;
    border: 2px solid ${blue['500']};
    box-shadow: 0 2px 2px #888;
    margin-top: 2px;
    &:hover {
      transform: scale(1.08);
    }
    
    &:active {
      transform: scale(0.92);
    }
  }
`
