import React, {
  useState,
  useCallback
} from 'react'
import {
  TableActionsButtonContainer,
  TableActionsSpeedDail
} from "components/Table/TableActionsButton/style";
import {
  SpeedDialIcon,
  SpeedDialAction
} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';

const TableActionsButton = ({
  actions
}: any) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  return (
    <TableActionsButtonContainer>
      <TableActionsSpeedDail
        ariaLabel={"Table Actions"}
        icon={<SpeedDialIcon icon={<FileDownloadIcon />} openIcon={<CloseIcon />}/>}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action: any) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={()=> {
              action.handler()
              handleClose()
            }}
          />
        ))}
      </TableActionsSpeedDail>
    
    </TableActionsButtonContainer>
  )
}

export default TableActionsButton