import React, {
    Fragment
} from "react";
import {useSelector} from 'react-redux'
import { Skeleton }              from "@mui/material";
import styled                    from "styled-components";
import { _selectorUIIsSkeleton } from "store/ui/helpers";

const SkeletonContainer = styled(Skeleton)`
  &&& {
    max-width: 100%;
    max-height: 100%;
  }
`


const SkeletonHoc = ({
    is = false,
    children,
}: React.PropsWithChildren<{ is?: boolean }>) => {
    const isSkeleton = useSelector(_selectorUIIsSkeleton)
    if (!isSkeleton && !is) return <Fragment>{children}</Fragment>;
    return <SkeletonContainer animation={isSkeleton ? 'wave' : false}>{children}</SkeletonContainer>;
};

export default SkeletonHoc;
