import React, {
    useState,
    useCallback,
    useRef,
    useEffect,
    useMemo,
    useContext,
}                             from 'react'
import InputNumber              from "components/Input/InputNumber";
import {
    InputNumberContainer,
    InputNumberButtonsContainer,
    InputNumberToggleButtonsGroup,
    InputNumberToggleButton,
    InputNumberOperationLabel,
    InputNumberSearchContainer,
    InputNumberSearchOperation,
    InputNumberPart
}                       from "components/Table/TableHeader/FilterSortCell/style";
import TableDataContext from "components/Table/context/context";

export const InputNumberSearch = ({defaultValue, handlerSearch, clearSearch, ...rest}: any) => {
    const ref = useRef()
    const [value, setValue] = useState(defaultValue as string || "")
    const timer = useRef(0)
    const triggerSearch = useCallback((value: string, time?: number) => {
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {
            handlerSearch(value)
        }, time || 400) as any
    }, [handlerSearch, timer])

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist()
        setValue(e.target.value)
        triggerSearch(e.target.value)
    }, [setValue, triggerSearch])

    const setFocus = useCallback(() => {
        if (!ref || !ref.current) return
        (ref.current as any).focus()
    }, [ref])

    const handlerClear = useCallback(() => {
        if(clearSearch) clearSearch()
        else handlerSearch('')
        setValue('')
        setFocus()
    }, [setValue, handlerSearch, clearSearch, setFocus])

    useEffect(() => {
        if (value?.length) return
        if (!value?.length) handlerClear()
    }, [value, onChange, handlerClear])


    return (
        <InputNumber
            {...rest}
            defaultValue={defaultValue as string}
            onClear={handlerClear}
            onChange={onChange}
            useHelperText={false}
        />
    )

}

const FilterInputNumber = ({ column }: any) => {

    const { filter, setFilter, clearFilter } = useContext(TableDataContext)


    const [defaultValue, defaultOperation] = useMemo(()=> {
        if(!filter || !filter?.[column?.id]) return ['',null]
        const value = filter?.[column?.id]
        const oper =  value.replace(/\d/gm,'')
        const val = value.replace(/\D/gm,'')
        return [val, oper]
    },[filter, column])

    const [data, setData] = useState<any>({
        operation: defaultOperation || null,
        value: ''
    })


    const [value, operation] = useMemo(()=> [data.value, data.operation],[data])

    const handlerSetOperation = (
        event: React.MouseEvent<HTMLElement>,
        operation: string | null,
    ) => {
        setData((v:any)=> ({...v, operation}));
    };

    const handlerSearch = useCallback(
        (value: string) => {
            setData((v:any)=> ({...v, value}))
        },
        [setData],
    );

    const clearSearch = useCallback(() => {
        filter[column?.id] && clearFilter(column?.id)
        setData((v:any)=> ({
           ...v,
           value: ''
        }))
    }, [filter, column, clearFilter, setData])


    useEffect(() => {
      const data = operation !== null ? `${operation}${value}` : value
      if(!value || data === filter[column?.id]) return
        setFilter({
            field: column?.id,
            data
        })
    },[setFilter, column?.id, value, operation, filter, setData])


    return (
        <InputNumberContainer>
            <InputNumberButtonsContainer>
                <InputNumberOperationLabel>Operation</InputNumberOperationLabel>
                <InputNumberToggleButtonsGroup
                    value={operation}
                    exclusive
                    onChange={handlerSetOperation}
                    aria-label="operation"
                    placeholder={'Operation'}
                >
                    {['=','>', '<', '>=', "<="].map((x, index) =>(
                        <InputNumberToggleButton value={x} aria-label={x} key={index}>
                            {x}
                        </InputNumberToggleButton>
                    ))}
                </InputNumberToggleButtonsGroup>
            </InputNumberButtonsContainer>
            <InputNumberSearchContainer>
                <InputNumberSearchOperation>{operation}</InputNumberSearchOperation>
                <InputNumberPart
                    handlerSearch={handlerSearch}
                    clearSearch={clearSearch}
                    defaultValue={defaultValue}
                />
            </InputNumberSearchContainer>
        </InputNumberContainer>

    )
}


export default FilterInputNumber
