import React, {
    useMemo,
    useContext
} from 'react'
import { DragHandle }     from "components/DraggableTable/DragHandler";
import {
    TDCellContainer,
    TDExpandIconOpen,
    TDCollapseIconOpen,
    TDExpandedContainer,
    TDExpandedIconBox
}                        from "components/Table/style";

const TDCell = ({
    cell,
    isDragHandle = false,
    style = {},
    expand,
    expandProps,
    isAllRowsExpanded,
    width
}: any) => {
    
    const widthObj = Object.assign({}, width ? { width } : {})
    const notRenderInExpand = useMemo(()=> cell?.row?.depth > 0 && cell?.column?.hideInExpand ,[cell])
    
    
    
    return (
        <TDCellContainer align={cell?.column?.align} {...cell.getCellProps()} style={{ ...cell?.column?.style, ...style, ...widthObj }}>
            {isDragHandle && <DragHandle isDragging />}
            {!expand  ? !notRenderInExpand ?  cell.render('Cell'): <></> :(
                <TDExpandedContainer>
                    {!isAllRowsExpanded ? <TDExpandedIconBox {...expandProps} >
                        {cell.row.isExpanded ? <TDCollapseIconOpen /> : <TDExpandIconOpen />}
                    </TDExpandedIconBox> : null }
                    {cell.render('Cell')}
                </TDExpandedContainer>
            )}
        </TDCellContainer>
    )
}

export default TDCell
