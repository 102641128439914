import styled, { css } from "styled-components";
import { Flex } from "style";
import {
  LabelPart,
  RenderLabelValueContainer,
  ValuePart
} from "components/Utils/Render/style";

export const RecordValidityContainer =  styled(Flex)<{isUsed?: boolean}>`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: ${props => !props.isUsed ? '450px' : '120px'};
  max-width: ${props => !props.isUsed ? '450px' : '120px'};
  padding: 10px;
`


export const RecordValidityHeader = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`
export const RecordValidityHeaderTitle = styled(Flex)`
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: #7c7c7c;
`
export const RecordValidityHeaderValue = styled(Flex)`
  line-height: 1;
  font-weight: 800;
  letter-spacing: 0.4px;
`


export const RecordValidityContent = styled(Flex)<{isUsed?: boolean}>`
  flex-direction: column;
  flex: 2;
  width: 100%;
  margin-top: 5px;
  &&& {
    ${RenderLabelValueContainer} {
      width: 100%;
      gap: ${props=> !props.isUsed ? '10px' : '0px'};

      ${LabelPart} {
        min-width: 150px;
        margin-right: 0;
      }

      ${ValuePart}{
        min-width: ${props=> !props.isUsed ? '250px' : '100px'};
        ${props => props.isUsed && css`
          max-width: 120px;
        `}
      }
    }
  }
  
`

export const RecordValidityValueContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
  
  > div {
    flex: 1;
    min-width: 90px;
    
    &:nth-child(2) {
      flex: 2;
      text-align: center;
    }
  }
  
`

export const RecordValidityRow = styled(Flex)`
  padding: 2px;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #eee;
`