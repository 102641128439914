import React, { useMemo }             from 'react'
import { WalmartVisibilityContainer } from "pages/Legacy/Marketing/components/WalmartVisibility/style";


const WalmartVisibility = ({value: visibility}: any) => {

    const color = useMemo(()=> {
        if(!visibility) return 'transparent'
        if (visibility === 'AC_PU') return '#38761d'
        if (visibility.endsWith('RE')) return '#93c47d'
        return 'transparent'
    },[visibility])

    return (
        <WalmartVisibilityContainer color={color}>
            {visibility}
        </WalmartVisibilityContainer>
    )
}


export default WalmartVisibility
