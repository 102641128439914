import styled, { css } from "styled-components";
import Table from "components/Table";
import { Flex } from "style";
import { TDCellContainer } from "components/Table/style";
import FlagComp from "react-world-flags";
import { FontAwesomeIcon as FontAwesomeIconComponent } from "@fortawesome/react-fontawesome";

export const Order365Table = styled(Table)`
  &&& {
    max-width: 1800px;
    margin: 0px auto;
    
    &&&& ${TDCellContainer} {
      position: relative;
    }
    
  }
`

export const Order365InfoContainer = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`

export const Order365InfoIcon = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`


export const SmallHeader = styled.span`
  font-size: 10px;
`

export const BasePriceHeader = styled.span`
  font-size: 10px;
`

export const LastDateSoldHeader = styled.span`
  font-size: 9px;
`










export const HeaderTitle = styled.div`
   display: none;
`

export const HeaderIcons = styled.div`

`
