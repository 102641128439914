import styled from "styled-components";
import { FontAwesomeIcon as FontAwesomeIconComponent } from "@fortawesome/react-fontawesome";
import FlagComp from "react-world-flags";

export const OptiScoreHeaderContainer = styled.div`
 
`

export const FontAwesomeIcon = styled(FontAwesomeIconComponent)`
   font-size: 16px;
   position: absolute;
   top: 0px;
   left: 20px;
`

export const FlagHeader = styled(FlagComp)`
  width: 17px;
  height: 16px;
  position: absolute;
  top: -10px;
  left: 1px;
`

export const TextHeader = styled.div`
    position: absolute;
    top: 2px;
    left: 2px;
    font-size: 10px;
`
