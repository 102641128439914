import React, { useMemo } from 'react'
import {
  AmazonStoresListContainer,
  AmazonStoreListItemStatusCircle,
  AmazonStoreListItemAvatar,
  AmazonStoreListItem,
  AmazonStoresContainer,
  AmazonStoresHeader,
  AmazonStoresTitle
} from "pages/Legacy/Order/365/AmazonStoreStatus/style";
import {
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@mui/material";
import { AppDivider } from "style";
import { formatDateTime } from "Utils";


const AmzStores = ({stores}: any) => {

  const data = useMemo(()=> stores.map((s: any) => {

      const timeNow = new Date().getTime()
      const timeRestricted = new Date(s.lastTimeRestrictionChecked).getTime()
      const  diff = Math.abs(timeNow - timeRestricted)

      return {
          id: s.id,
          isRestricted: s.isStoreRestricted || diff > 1000 * 60 * 60 * 1,
          time: formatDateTime(s.lastTimeRestrictionChecked),
          seller: s.name
      }
  }),[stores])

  return (
    <AmazonStoresContainer>
      <AmazonStoresHeader>
        <AmazonStoresTitle>Amazon Store Statuses</AmazonStoresTitle>
      </AmazonStoresHeader>
      <AppDivider />
      <AmazonStoresListContainer >
        {data.map((d: any, index: number) => (
          <AmazonStoreListItem key={index}>
            <AmazonStoreListItemAvatar>
              <AmazonStoreListItemStatusCircle isRestricted={d.isRestricted}/>
            </AmazonStoreListItemAvatar>
            <ListItemText
              primary={d.seller}
              secondary={d.time}
            />
          </AmazonStoreListItem>
        ))}
      </AmazonStoresListContainer>
    </AmazonStoresContainer>

  )
}


export default AmzStores
