import React, {useEffect, useState} from 'react'
import {
    CloseIcon,
    Container,
    CountrySide,
    DataPart,
    Flag,
    LinkToReason,
    ShowDataRoot, ShowDataType,
    TextReason,
    Title
} from "./style";
import {getAmazonRestrictedInfo} from "api/amazon";
import {Clear} from "@mui/icons-material";
import {_selectorGetSellerById} from "store/sellers/helpers";
import {useSelector} from "react-redux";
import {capitalize} from "lodash";

const ShowData = ({
       flagCode = "USA",
       data = []
                  }) => {


    return <CountrySide>
        <Flag code={flagCode}/>
        { data.map((d:any) => (
            <ShowDataRoot>
                <ShowDataType>{capitalize((d.conditionType || "").split("_").join(" "))}</ShowDataType>
                <div>
                    {d.reasons.map((p:any) => {
                        const link = p.links?.[0]?.resource
                        return !link ? <TextReason key={p.reasonCode}>{p.message}</TextReason> :
                        <LinkToReason key={p.reasonCode} href={link} target="_blank">{p.message}</LinkToReason>
                    })}
                </div>
            </ShowDataRoot>
        ))}
    </CountrySide>
}


const AmazonRestrictedInfo = ({asin, sellerId = 5, className, handlerClose, isUsed}: {
    isUsed?: boolean,
    asin: string,
    sellerId?: number,
    className?: string,
    handlerClose: () => void
}) => {

    const seller = useSelector(_selectorGetSellerById(sellerId))
    const [data, setData] = useState([])

    useEffect(() => {
        if (!asin) return
        getAmazonRestrictedInfo(asin, sellerId).then(v => {
            setData(v)
        })
    }, [asin, sellerId])


    const resUS = data.find((d: any) => d.marketplaceId === 1)
    const resCA = data.find((d: any) => d.marketplaceId === 2)


    const arrayResUs = (resUS as any)?.dataJson?.[!isUsed ? "new" : "used"] || []
    const arrayResCa = (resCA as any)?.dataJson?.[!isUsed ? "new" : "used"]  || []

    return (
        <Container className={className}>
            <Title>Restricted Resources {seller?.name || ""}  </Title>
            <CloseIcon onClick={handlerClose}>
                <Clear/>
            </CloseIcon>
            <DataPart>
                <ShowData flagCode={"USA"} data={arrayResUs} />
                <ShowData flagCode={"CAN"} data={arrayResCa} />
            </DataPart>
        </Container>
    )
}

export default AmazonRestrictedInfo
