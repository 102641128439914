import React                 from 'react'
import { TTabPanelProps }    from "components/Tabs/d";
import { TabPanelContainer } from "components/Tabs/style";

const TabPanel = ({ children, value, index, active, ...rest }: TTabPanelProps) => (
    <TabPanelContainer
        role="tabpanel"
        hidden={value !== active}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...rest}
    >
            {children}
    </TabPanelContainer>
)

export default TabPanel
