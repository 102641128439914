import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useContext
} from 'react'
import {
  OptiScoreContainer,
  OptiScoreText
} from "pages/Legacy/Marketing/components/OptiScore/style";
import { Popover } from "@mui/material";
import AmazonProductOffers from "pages/Legacy/Marketing/components/AmazonProductOffers";
import {
  getAmazonByAsin,
  getAmazonProductByAsin,
  syncAmazonApiByAsin
} from "api/amazon";
import { useLoading } from "hooks/useLoading";
import TableDataContext from "components/Table/context/context";

const OptiScoreNew = ({ row, isCa }: any) => {

  const [product, setProduct] = useState<any>({} as any)
  const { setRefetch } = useContext(TableDataContext)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const id = !!anchorEl ? 'simple-popover' : undefined;
  const { setLoading, isLoading, resetLoading } = useLoading()

  const syncProductData = useCallback(async (asin: string) => {
    if (!asin) return
    try {
      setLoading({
        text: 'Synchronization.. Please wait!'
      })
      const result = await syncAmazonApiByAsin(asin)
      setProduct(result || {})
      setRefetch()
    } catch (e) {
      console.log(e)
    } finally {
      resetLoading()
    }

  }, [setLoading, setRefetch, setProduct, resetLoading])

  const optiScore = useMemo(() => {
    if (!row?.original?.asin) return ''
    return !isCa ? row?.original?.amazonOptiScore : row?.original?.amazonOptiScoreCA
  }, [row, isCa])

  const isFbaError = useMemo(() => {
    if (Number(optiScore)) return false
    return !isCa ? !!row?.original?.fbaErrorUS : !!row?.original?.fbaErrorCA
  }, [row, isCa, optiScore])

  const asin = useMemo(() => row?.original?.asin, [row])

  const color = useMemo(() => {
    const val = Number(optiScore)
    if (val === 4) return '#93c47d'
    if (val === 5) return '#6aa84f'
    if (val === 6) return '#38761d'
    return 'transparent'
  }, [optiScore])

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    asin && setAnchorEl(event.currentTarget);
  }, [asin]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchData = useCallback(async (asin: string) => {
    try {
      setLoading()
      const result = await getAmazonByAsin(asin)
      setProduct(result || { asin })
    } catch (e) {
      console.log(e)
    } finally {
      resetLoading()
    }
  }, [setProduct, setLoading, resetLoading])

  useEffect(() => {
    if (!asin || !anchorEl) return
    fetchData(asin).then()
  }, [asin, anchorEl, fetchData])

  return (
    <OptiScoreContainer color={color} isFbaError={isFbaError}>
      <OptiScoreText onClick={handleClick}>
        {optiScore}
      </OptiScoreText>
      {asin && !isLoading && <Popover
        id={id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <AmazonProductOffers product={product} syncProductData={syncProductData} />
      </Popover>}
    </OptiScoreContainer>
  )
}

export default OptiScoreNew
