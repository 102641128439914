import styled, { css } from "styled-components";
import { Flex } from "style";
import LockIcon from "@mui/icons-material/Lock";
import {
  blue,
  grey
} from "@mui/material/colors";

export const UnitsLocationContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 280px;
  max-width: 280px;
  padding: 10px;
`

export const UnitsLocationHeader = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`

export const UnitsLocationHeaderTitle = styled(Flex)`
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: #7c7c7c;
`

export const UnitsLocationHeaderMpn = styled(Flex)`
  line-height: 1;
  font-weight: 800;
  letter-spacing: 0.4px;
`

export const UnitsLocationContent = styled.div`
  flex: 2;
  display: block;
  word-break: break-all;
  white-space: pre-line;
  min-width: 260px;
  max-width: 260px;
  margin-top: 5px;
`

export const UnitsLocationContentHeader = styled.div`
  line-height: 1;
  font-weight: 800;
  letter-spacing: 0.4px;
  padding: 8px 0;
  color: #7c7c7c;
`

export const UnitsLocationRow = styled.div`
  padding: 2px;
  border-bottom: 1px solid #eee;
  position: relative;
  max-width: 100%;
`


export const IngramLocationPart = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`

export const LocationOneRowContainer = styled(Flex)<{isColumn?: boolean}>`
  justify-content: flex-start;
  align-items: start;
  padding: 2px;
  border-bottom: 1px solid #eee;
  width: 100%;
  gap: 2px;
  
  ${props => props.isColumn && css`
    flex-direction: column;
  `}
`

export const BlueStarLocationRow = styled(Flex)`
  position: relative;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 2px 0;
  border-bottom: 1px solid #eee;
`

export const LocationRow = styled(Flex)<{ isInvalid?: boolean }>`
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  ${props => props.isInvalid && css`
    color: rgb(150, 15, 15);
  `}
`



export const IngramRow = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`

export const IngramDataProductNotAuthorized = styled(LockIcon)`
  color: rgb(150, 15, 15);

  &&& {
    font-size: 16px;
  }
`

export const IngramVendorPartNumber = styled.div`
  color: ${grey[700]};
  text-underline: none;
  font-size: 10px;
  cursor: pointer;
  
`