import { Flex } from "style";
import styled   from "styled-components";
import { blue } from "@mui/material/colors";
import ButtonIconComponent             from "components/Button/ButtonIcon";

export const ExportCSVContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
`

export const ButtonIcon = styled(ButtonIconComponent)`
  &&& {
    background-color: ${blue['800']};
    color: #fff;
    border: 2px solid ${blue['500']};
    box-shadow: 0 2px 2px #888;
    margin-top: 2px;
    &:hover {
      transform: scale(1.08);
    }
    
    &:active {
      transform: scale(0.92);
    }
  }
`
