
import _styled    from "styled-components";
import {
    Paper,
    CircularProgress,
  
}                 from "@mui/material";
import { styled } from "@mui/material/styles";

export const SpinnerContainer = _styled.div`
      position: absolute;
      background: rgba(192, 192, 192, 0.6);
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 20000;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
`

export const SpinnerProgress = styled(CircularProgress)(({ theme }) => ({

}));

export const SpinnerText = _styled.div`
    margin-top: 15px;
    color: #fefefe;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-shadow: 0.8px 1px 0px #646464;
`
