import React, {memo, useCallback, useEffect, useState} from 'react'
import {Popover} from "@mui/material";
import {AmazonStoreStatusButtonIcon, AmazonStoreStatusContainer} from "pages/Legacy/Order/365/AmazonStoreStatus/style";
import StorefrontIcon from '@mui/icons-material/Storefront';
import AmzStores from "pages/Legacy/Order/365/AmazonStoreStatus/AmzStores";
import {_selectorGetSellers} from "store/sellers/helpers";
import {useSelector} from "react-redux";
import useFetchSellers from "store/sellers/useFetchSellers";

const AmazonStoreStatus = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const id = !!anchorEl ? 'simple-popover' : undefined;

    const {fetchSellers, isLoading} = useFetchSellers()


    useEffect(() => {
        if (!anchorEl) return
        fetchSellers().then()
    }, [anchorEl])

    let sellers = useSelector(_selectorGetSellers) || []
    sellers = sellers.filter(d => d.useMongo)

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AmazonStoreStatusContainer>
            <AmazonStoreStatusButtonIcon title={'Amazon Store Statuses'} onClick={handleClick}>
                <StorefrontIcon/>
            </AmazonStoreStatusButtonIcon>
            {sellers.length && !isLoading ? <Popover
                id={id}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <AmzStores stores={sellers}/>
            </Popover> : <></>}
        </AmazonStoreStatusContainer>
    )
}

export default memo(AmazonStoreStatus)
