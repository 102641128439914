import styled, { css } from "styled-components";
import { Flex } from "style";
import { blue } from "@mui/material/colors";
import FlagComp from "react-world-flags";
import {
  IconButtonProps,
  IconButton
} from "@mui/material";


export const Container = styled(Flex)`
  justify-content: stretch;
  flex-direction: column;
  min-height: 200px;
  padding: 8px;
  margin-bottom: 10px;
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`
export const Flag = styled(FlagComp)`
   position: absolute;
   top: 0;
   left: 0;
   width: 20px;
   height: 16px;
  `

export const CountrySide = styled(Flex)`
  flex-direction: column;
  position: relative;
  flex: 1;
  max-width: 350px;
  min-width: 300px;
  overflow: hidden;
  border: 1px solid rgba(128, 128, 128, 0.06);
  margin: 2px;
  padding-top: 20px;
  

`

export const DataPart = styled(Flex)`
  flex-direction: row;
  position: relative;
  font-size: 14px;
  margin: 8px;
 
`


export const LinkToReason = styled.a`
  color: ${blue['700']};
  padding:  5px;
`

export const TextReason = styled.div`
  padding:  5px;

`

export const CloseIcon = styled((props: IconButtonProps) => (
  <IconButton  {...props} />
))(({ theme }) => ({
  '&.MuiIconButton-root': {
    position: "absolute",
    top: 5,
    right: 5
  }
}))


export const ShowDataRoot = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   padding: 10px 0 4px 10px;
`
export const ShowDataType = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 1px;
`
