import {
    TStoreAuth,
    TUser,
    TAction
} from "store/auth/d";
import {
    AUTH_SET_USER,
    AUTH_CLEAR_USER
} from "store/auth/types";

export const initState: TStoreAuth = {
    user: {} as TUser
}

const authState =  (state = initState, action: TAction) => {
    switch(action.type) {
        default:
            return state;
        case AUTH_SET_USER: {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            }
        }
        case AUTH_CLEAR_USER: {
            return {
                ...state,
                user: {}
            }
        }
    }
}

export default authState
