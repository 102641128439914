import React from 'react'
import {
  AmazonProductOfferSellerCell,
  CircleGreen,
  AmazonProductOfferSellerCellText,
  EmptyCircle
} from "pages/Legacy/Marketing/components/AmazonProductOffers/style";
import { useSelector } from "react-redux";
import {_selectorIsOurSeller, _selectorOurSeller} from "store/sellers/helpers";


const OfferSellerCell = ({row}: {row: any}) => {
  const isOur = useSelector(_selectorIsOurSeller(row?.SellerId))
  const ourSeller = useSelector(_selectorOurSeller(row?.SellerId))
  return (
    <AmazonProductOfferSellerCell>
      {isOur ? <CircleGreen isOur/> : <EmptyCircle/> }
         <AmazonProductOfferSellerCellText>{ourSeller && <small>({ourSeller.name})</small>}{row.SellerId}</AmazonProductOfferSellerCellText>
    </AmazonProductOfferSellerCell>
  )
}

export default OfferSellerCell
