import React from 'react'
import { Spinner } from "components/Spinner";
import { get }     from "lodash";

export const ComponentLazy = (props: any) => {
    const {
        component,
        ...rest
    } = props
    const C = component
    return (get(component, '$$typeof') === Symbol.for('react.lazy')) ? <React.Suspense fallback={<Spinner />}><C {...rest} /></React.Suspense> : <C {...rest} />
}
