import React from "react";
import {
  faAmazon,
} from "@fortawesome/free-brands-svg-icons";

import {
  OptiScoreHeaderContainer,
  FlagHeader,
  FontAwesomeIcon, TextHeader
} from "./style";


const OptiscoreHeader =  ({className, code, text}:{className?: string, code: string, text?:string}) => <OptiScoreHeaderContainer>
          <FontAwesomeIcon icon={faAmazon} />
         <FlagHeader code={code} />
         <TextHeader>{text}</TextHeader>
</OptiScoreHeaderContainer>
export default OptiscoreHeader
