import React from 'react'
import {
    FilterFormContainer,
    FilterFormPart
} from "components/Table/TableHeader/FilterSortCell/style";
import { FieldColumnType, } from "components/Table/TableHeader/FilterSortCell/d";
import InputSearch from "components/Table/TableHeader/FilterSortCell/InputSearch";
import FilterInputNumber from "components/Table/TableHeader/FilterSortCell/FilterInputNumber";
import FilterSelect from "components/Table/TableHeader/FilterSortCell/FilterSelect";
import FilterMultiSelect from "components/Table/TableHeader/FilterSortCell/FilterMultiSelect";
import FilterDatePicker from "components/Table/TableHeader/FilterSortCell/FilterDatePicker";

const _FilterComponent = (fieldType: FieldColumnType) => {
    switch (fieldType) {
        case FieldColumnType.number:
            return FilterInputNumber
        case FieldColumnType.selection:
        case FieldColumnType.value:
            return FilterSelect
        case FieldColumnType.multiselection:
        case FieldColumnType.multiselectionNum:
            return FilterMultiSelect
        case FieldColumnType.date:
        case FieldColumnType.onlyDate:
            return FilterDatePicker
        default:
            return InputSearch
    }
}

const FilterForm = ({ col }: any) => {
    const FilterComponent = _FilterComponent(col?.fieldType) as any
    return (
        <FilterFormContainer>
            <FilterFormPart>
                <FilterComponent column={col} />
            </FilterFormPart>
        </FilterFormContainer>
    )
}

export default FilterForm
