import { createSelector } from "reselect";
import { TReduxStore }    from "store/index";
import { TStoreSellers }  from "store/sellers/d";

export const _selectorGetSellers = createSelector((state: TReduxStore): TStoreSellers => state.sellers, sellers => sellers.sellers)

export const _selectorOurSeller = (sellerId: string) => createSelector((state: TReduxStore): TStoreSellers => state.sellers, sellers => (sellers.sellers || []).find(x=> x.amazonId === sellerId))
export const _selectorIsOurSeller = (sellerId: string) => createSelector((state: TReduxStore): TStoreSellers => state.sellers, sellers => !!(sellers.sellers || []).find(x=> x.amazonId === sellerId))
export const _selectorGetSellerById = (sellerId: number) => createSelector((state: TReduxStore): TStoreSellers =>
  state.sellers, sellers => (sellers.sellers || []).find(x=> x.id === sellerId)) || {}
