import React, { useCallback } from 'react'
import {
  AmazonProductOfferContainer,
  AmazonProductHeader,
  AmazonProductAsin,
  AmazonProductOffersPart,
  AmazonOfferLabel,
  AmazonOfferValue,
  AmazonProductDataTime,
  AmazonProductSyncDataButton,
  AmazonProductOffersUSPart,
  AmazonProductOffersCAPart,
  AmazonProductOfferTitle,
  CountryFlags,
} from "pages/Legacy/Marketing/components/AmazonProductOffers/style";
import { StaticTable } from "components/Table/TableComponent";
import {
  formatPrice,
  formatDateTime
} from "Utils";
import OfferSellerCell from "pages/Legacy/Marketing/components/AmazonProductOffers/OfferSellerCell";
import BuyBoxWinnerOurOfferCircle from "pages/Legacy/Marketing/components/AmazonProductOffers/BuyBoxWinnerOurOfferCircle";
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import Flag from "react-world-flags";

const AmazonProductOffers = ({product, syncProductData, isCa}: {product?: any, syncProductData?: any, isCa?: boolean})=> {

  const columns = [
    {
      Header: 'WB',
      accessor: 'IsBuyBoxWinner',
      style: {
        minWidth: 30,
        maxWidth: 30
      },
      Cell: (props: any) => <BuyBoxWinnerOurOfferCircle value={props.value} row={props.row.original} />
    },
    {
      Header: 'Seller',
      accessor: 'SellerId',
      align: 'left',
      style: {
        minWidth: 180,
        maxWidth: 180
      },
      Cell: (props: any) => <OfferSellerCell row={props.row?.original} />
    },
    {
      Header: 'Price',
      accessor: 'ListingPrice',
      align: 'right',
      Cell: (props: any) => formatPrice(props.row?.original?.ListingPrice?.Amount)
    },
    {
      Header: 'SubCondition',
      accessor: 'SubCondition',
      align: 'center'
    }
  ]


  const syncHandler = useCallback(()=> {
    if(!product?.asin) return
    syncProductData(product.asin).then()
  },[product, syncProductData])

 const _tableData = product.dataJson?.offers || []
  const _tableDataCa =  product.dataJson?.offersCA || []


  return (
    <AmazonProductOfferContainer>
      <AmazonProductHeader>
        <AmazonProductAsin>
          <AmazonOfferLabel>ASIN</AmazonOfferLabel>
          <AmazonOfferValue>{product.asin}</AmazonOfferValue>
        </AmazonProductAsin>
        <AmazonProductDataTime>
          <AmazonOfferLabel>Last Time Sync</AmazonOfferLabel>
          <AmazonOfferValue>{formatDateTime(product.dataJsonTime)}</AmazonOfferValue>
        </AmazonProductDataTime>
        <AmazonProductSyncDataButton title={'Sync for new data'} size="small" onClick={syncHandler}>
          <CloudSyncIcon />
        </AmazonProductSyncDataButton>
      </AmazonProductHeader>
      <AmazonProductOffersPart>
        <AmazonProductOffersUSPart>
          <AmazonProductOfferTitle>
            United States
            <CountryFlags code={"USA"} />
          </AmazonProductOfferTitle>
          <StaticTable
            visibleColumns={columns}
            tableData={_tableData}
            totalRecords={_tableData.length}
            tableBordered
            isVisibilityHeader={false}
          />
        </AmazonProductOffersUSPart>
        <AmazonProductOffersCAPart>
          <AmazonProductOfferTitle >
            Canada
            <CountryFlags code={"CAN"} />
          </AmazonProductOfferTitle>
          <StaticTable
            visibleColumns={columns}
            tableData={_tableDataCa}
            totalRecords={_tableDataCa.length}
            tableBordered
            isVisibilityHeader={false}
          />
        </AmazonProductOffersCAPart>
      </AmazonProductOffersPart>
    </AmazonProductOfferContainer>
  )
}


export default AmazonProductOffers
