import React, { useCallback } from 'react'
import { Popover } from "@mui/material";
import ButtonIcon from "components/Button/ButtonIcon";
import { Tune } from "@mui/icons-material";
import FilterPopUpForm from "components/Table/Filters/PopUpForm";
import {
  FiltersContainer,
  FilterButton
} from "components/Table/Filters/style";



const Filters = ()=> {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const id = !!anchorEl ? 'simple-popover' : undefined;
  
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  },[setAnchorEl]);
  
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  },[setAnchorEl]);
  
  
  return (
    <FiltersContainer>
      <FilterButton onClick={handleClick}>
        <Tune />
      </FilterButton>
      <Popover
        id={id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <FilterPopUpForm handleClose={handleClose}/>
      </Popover>
    </FiltersContainer>
  )
}

export default Filters
