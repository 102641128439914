import {
    applyMiddleware,
    combineReducers,
    createStore
}                                       from 'redux'
import thunk, { ThunkMiddleware }       from 'redux-thunk'
import { composeWithDevTools }          from 'redux-devtools-extension'
import { Dispatch }                     from "react";
import { getUser }                      from "api/users";
import cronDefinitions                  from "store/cronDefinitions"
import auth                             from 'store/auth'
import ui                               from 'store/ui'
import sellers                          from 'store/sellers'
import casl                         from 'store/casl'
import amazon                         from 'store/amazon'
import {
    TStoreAuth,
    TUser
}                                       from "store/auth/d";
import { TStoreCronDefinition }         from "store/cronDefinitions/d";
import { TStoreUi }                     from "store/ui/d";
import {
    LocalStorage,
    STORAGE_TOKEN
}                                       from "utils/LocalStorage";
import { _actionAuthSetUser }           from "store/auth/actions";
import {
    pick,
    merge
} from "lodash";
import { TStoreSellers }                from "store/sellers/d";
import { getSellers }                   from "api/sellers";
import { _actionSetSellers }            from "store/sellers/action";
import { fetchCronDefinitions }         from "api/cronDefinitions";
import { _actionCronDefinitionSetData } from "store/cronDefinitions/actions";
import { TCASLPermission } from "store/casl/d";
import {
    getAmazonMarketPlaces,
    getAmazonBrands,
    getAmazonSearchCategories
} from "api/amazon";
import { TStoreAmazon } from "store/amazon/d";
import {
    _actionSetAmazonMarkets,
    _actionSetAmazonBrands,
    _actionSetAmazonSearchCategories
} from "store/amazon/action";

export type TReduxStore = {
    cronDefinitions: TStoreCronDefinition,
    auth: TStoreAuth,
    ui: TStoreUi,
    sellers: TStoreSellers,
    casl: TCASLPermission,
    amazon: TStoreAmazon
}

const rootReducer = combineReducers({
    cronDefinitions,
    auth,
    ui,
    sellers,
    casl,
    amazon
})

const middleware = thunk as ThunkMiddleware

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(middleware)))

const currentState = {
    isInit: false
}

const getUiData = () => {
    const { getData } = LocalStorage
    const data = getData(STORAGE_TOKEN)
    if (!data) return
    return data
}

const reduxGetUser = async (dispatch: Dispatch<any>) => {
    const uiData = getUiData()
    if (currentState.isInit || !uiData) return
    try {
        const { result } = await getUser(uiData?.user?.id) as any
        if (!result) return
        const user = pick(result, ['id', 'email', 'firstName', 'lastName', 'accessRoleId']) as TUser
        dispatch(_actionAuthSetUser(user))
        if (!uiData?.user?.firstName){
            LocalStorage.saveData({
                ...uiData,
                user: {
                    ...uiData.user,
                    ...user
                }
            } as any, STORAGE_TOKEN)
        }
    } catch (e) {
        console.log(e)
    }
}

const reduxGetSellers = async (dispatch: Dispatch<any>) => {
    try {
        const result = await getSellers() as any
        if (!result) return
        dispatch(_actionSetSellers(result))
    } catch (e) {
        console.log(e)
    }
}

const reduxGetAmazonBrands = async (dispatch: Dispatch<any>) => {
    try {
        const result = await getAmazonBrands() as any
        if (!result) return
        dispatch(_actionSetAmazonBrands(result))
    } catch (e) {
        console.log(e)
    }
}


const reduxGetAmazonMarketplaces = async (dispatch: Dispatch<any>) => {
    try {
        const result = await getAmazonMarketPlaces() as any
        if (!result) return
        dispatch(_actionSetAmazonMarkets(result))
    } catch (e) {
        console.log(e)
    }
}
const reduxGetCronDefinitions = async (dispatch: Dispatch<any>) => {
    if (currentState.isInit) return
    try {
        const result = await fetchCronDefinitions() as any
        if (!result) return
        dispatch(_actionCronDefinitionSetData(result))
    } catch (e) {
        console.log(e)
    }
}

export const reduxInitAmazon = async (dispatch: Dispatch<any>) => {
    reduxGetAmazonMarketplaces(dispatch).then()
    reduxGetAmazonBrands(dispatch).then()

}

export const reduxInitAwait = async (dispatch: Dispatch<any>) => {
    const uiData = getUiData()
    if (!uiData) return
    dispatch(_actionAuthSetUser(merge({},
        uiData?.userId ? {
        id: uiData.userId,
        roleId: uiData.roleId,
        firstName: '',
        lastName: ''
    }: uiData?.user ? uiData.user : {})))
     await reduxGetUser(dispatch).then()
    reduxInitAmazon(dispatch).then()
    reduxGetSellers(dispatch).then()
    reduxGetCronDefinitions(dispatch).then()
    currentState.isInit = true
}

export const reduxInit = (dispatch: Dispatch<any>) => {
    reduxInitAwait(dispatch).then()
}

store.subscribe(() => {
    if (!currentState.isInit) {
        return
    }
})

export default store
