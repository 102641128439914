import {
    API,
    rootApi
}                from "api/index";
import {
    TUser,
    TUserChangePasswordRequest
} from "api/users/d";

export const getUsers = async () : Promise<any> =>  (await API.get(`${rootApi}/users`) as any)?.data?.result || []


export const getUser = async (id: number) =>  {
    const response = await API.get(`${rootApi}/users/${id}`) as any
    return response?.data || {}
}

export const updateUser = async (updatedUser: any) => {
    const response = await API.post(`${rootApi}/users/updateData`, updatedUser) as any
    return response?.data || {}
}

export const insertUser = async (user: TUser) => {
    const response = await API.post(`${rootApi}/users/signUp`, user) as any
    return response?.data || {}
}

export const resetPassword = async (id: number) => {
    const response = await API.post(`${rootApi}/users/resetPassword`, {id})
    return response?.data?.result || {}
}

export const changePassword = async (data: TUserChangePasswordRequest) => {
    const response = await API.post(`${rootApi}/users/changePassword`, data)
    return response?.data?.result || {}
}
