import {
    TStoreCronDefinition,
    TAction,
    TCronDefinition
} from "store/cronDefinitions/d";
import { CRON_DEFINITIONS_SET_DATA } from "store/cronDefinitions/types";

export const initState: TStoreCronDefinition = {
    data: []
}

const cronDefinitionsState = (state = initState, action: TAction) => {
    switch(action.type) {
        default:
            return state;
        case CRON_DEFINITIONS_SET_DATA: {
            return {
                ...state,
                data: [...action.payload as TCronDefinition[]]
            }
        }
    }
}

export default cronDefinitionsState
