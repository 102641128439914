import {
    useDispatch,
    useSelector
}                             from "react-redux";
import { _selectorUILoading } from "store/ui/helpers";
import {
    useCallback,
    useMemo
} from "react";
import {
    _actionUiSetLogin,
    _actionUiResetLogin
}                             from "store/ui/action";
import { TLoading }           from "store/ui/d";

export const useLoading = () => {

    const dispatch = useDispatch()
    const loading = useSelector(_selectorUILoading)

    const setLoading = useCallback((payload?: TLoading) => {
        dispatch(_actionUiSetLogin(payload))
    },[dispatch])

    const resetLoading = useCallback(() => {
        dispatch(_actionUiResetLogin())
    },[dispatch])

    return useMemo(() => ({
        ...loading,
        setLoading,
        resetLoading
    }), [loading, setLoading, resetLoading])
}
