import React, {
    useContext,
    useCallback,
    useMemo
} from 'react'
import Pagination from "components/Table/Pagination";
import TableDataContext from "components/Table/context/context";
import {
    TableFooterContainer,
    LeftPart,
    TableMiniProgress,
    RightPart
} from "components/Table/TableFooter/style";
import ExportToCSV from "components/ExportToCSV";
import API from "api";
import {
    omit,
    pick
} from "lodash";
import { formatDate } from "Utils";
import TableSearch from "components/Table/Search";
import { useSelector } from "react-redux";
import { _selectorUIIsMiniLoading } from "store/ui/helpers";
import TableActionsButton from "components/Table/TableActionsButton";
import { useTableActionsDownload } from "hooks/useTableActionsDownload";

const TableFooter = ({ totalRecords, rowsPerPageOptions, modelName,  title, csvFileName, FooterChildren, actionDownloadDefinition }: { actionDownloadDefinition?: any, csvFileName?: string, title?: string, totalRecords: number, rowsPerPageOptions?: Array<number | { value: number; label: string }>, modelName?: string, FooterChildren?: any }) => {

    const isMiniLoading = useSelector(_selectorUIIsMiniLoading)

    const { page, perPage, setPage, setPerPage, visibleColumns, requestOptions, optionsEntry, getAllColumnsWithIncludedModels, isExportCsv, isGlobalSearch, tableSearchTooltip } = useContext(TableDataContext)
    
    
    const getData = useCallback((data = {} as any) => {
        const dd = {
            ...omit(requestOptions, ['page', 'perPage', 'filter','sort']),
            page: 1,
            perPage: 50000,
            ...pick(data, ['filter', 'sort'])
        }
        return API.getModelAllCount(modelName, dd, true)
    }, [modelName, requestOptions])
    
    
    const headers = useMemo(() => [...getAllColumnsWithIncludedModels(visibleColumns)], [visibleColumns, getAllColumnsWithIncludedModels])
    
    
    const actions = useTableActionsDownload({
        headers,
        getData,
        modelName,
        tableTitle: csvFileName || title || modelName,
        requestOptions,
        actionDownloadDefinition,
        optionsEntry
    })
    

    const fileName = useMemo(() => {
        if (!csvFileName) return `${title}${formatDate(new Date())}`
        return csvFileName
    }, [csvFileName, title])
    
    
    const ExportComponent = useMemo(() => {
        if(actionDownloadDefinition) return <TableActionsButton actions={actions} />
        return <ExportToCSV
          filename={fileName}
          getData={getData}
          headers={headers}
          requestOptions={requestOptions}
        />
    },[fileName, getData, headers, actionDownloadDefinition, actions])
  

    return (
        <TableFooterContainer isEnd={!isExportCsv}>
            <LeftPart>
                {isExportCsv ? ExportComponent :  <></>}
                {isGlobalSearch ? <TableSearch tooltipText={tableSearchTooltip} /> : <></>}
            </LeftPart>
            {!!FooterChildren ? FooterChildren  : null}
            <RightPart>
                {!!isMiniLoading ? <TableMiniProgress size={'20px'} /> : null}
                <Pagination
                  totalRecords={totalRecords}
                  page={page}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  setPage={setPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                />
            </RightPart>
        </TableFooterContainer>
    )
}

export default TableFooter
