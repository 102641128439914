import styled from "styled-components";
import {
  Box,
  Typography,
  Button
} from "@mui/material";
import { Flex } from "style";
import ButtonIcon from "components/Button/ButtonIcon";
import { grey }   from "@mui/material/colors";
import ButtonMUI from '@mui/material/Button';

export const FilterPopUpFormContainer = styled(Box)`
  &&& {
    min-width: 450px;
    min-height: calc(100vh - 50px);
    max-height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px 10px;
  }
`

export const FilterPopUpHeader = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`

export const FilterHeaderTitle = styled(Typography).attrs({
  variant: 'h5'
})`
`

export const FilterPopUpCloseIcon = styled(ButtonIcon).attrs({
  size: 'small'
})`

`

export const FilterPopUpContent = styled(Flex)`
  padding: 20px 5px;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
`

export const FilterPopUpActions = styled(Flex)`
  justify-content: flex-end;
  align-items: center;
`

export const SavedFiltersLabel = styled(Flex)`
  font-size: 14px;
  padding-bottom: 15px;
`



export const SavedFiltersContainer = styled(Flex)`
  width: 100%;
  border-top: 1px solid  ${grey['300']};
  padding: 10px 5px;
  flex-direction: column;
`


export const ButtonDelete = styled(ButtonMUI)``
