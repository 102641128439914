import styled, { css } from "styled-components";
import { Flex }        from "style";
import Colors from "def/Colors";

export const OptiScoreContainer = styled(Flex)<{color: string, isFbaError ?: boolean}>`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  ${props => props.color && css`
    background-color: ${props.color};
  `}
  
  ${props => props.isFbaError && css`
    color: ${Colors.PALETTE.RED._800} !important;
    background: ${Colors.PALETTE.RED._50} !important;
  `}
`

export const OptiScoreText = styled(Flex)<{onClick: any}>`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
