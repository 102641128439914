import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
    green,
    lightBlue,
    red
} from "@mui/material/colors";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { fetchCronDefinitions } from "api/cronDefinitions";
import { getSellers } from "api/sellers";
import {
    CaSiteIdsOptions,
    SHEET_TAB_IDS
} from "def";
import {
    CAOrderShippingStatus,
    CaSiteAccountsOptions,
    CAOrderPaymentStatus
} from "def/channelAdvisor";
import { amazonWinningBuyBoxOptions } from "def/amazon";
import { topSellersActiveOptions } from "def/topSellers";
import { getAllStatusBySheetTab } from "api/productGroupStatus";
import { getCategoriesBySheetId } from "helpers";

export enum FilterLinks {
    users= '/users',
    monitoringCronLogs= '/monitoring/cron-logs',
    monitoringFeedback= '/monitoring/feedback',
    topSellers= '/products/top-sellers',
    orders= '/orders',
    legacyUnsecuredInventory= '/google-sheets/unsecured-inventory-unsecured-inventory',
    legacyMarketingInitialDrop = '/google-sheets/marketing-initial-drop',
    legacyMarketingArchiveDrop = '/google-sheets/marketing-archive-drop',
    legacyMarketingAlex = '/google-sheets/marketing-alex',
    legacySecuredInvNew = '/google-sheets/secured-inventory-new',
    legacySecuredInventory365Plus= '/google-sheets/secured-inventory-365-plus',
    optiScoreArchive = '/google-sheets/opti-score-archive-drop',
    optiScoreInitialDrop = '/google-sheets/opti-score-initial-drop',
    optiScoreNew = '/google-sheets/opti-score-new',
    optiScoreOrder365 = '/google-sheets/opti-score-365',
    optiScoreOrder365Plus = '/google-sheets/opti-score-365-plus',
    optiScoreSecuredNew = '/google-sheets/opti-score-secured-new',
    optiScoreSecuredUsed = '/google-sheets/opti-score-secured-used'
}

export enum FieldColumnType {
    string = 'string',
    number ='number',
    value = 'value',
    selection = 'selection',
    multiselection = 'multiselection',
    multiselectionNum = 'multiselectionNum',
    date = 'date',
    onlyDate = 'onlyDate'
}

export const CronLogsFilterStatusOptions = [
    {
        label: 'Success',
        value: 'success',
        icon: { icon: CheckCircleOutlineIcon, color: green["700"] }
    },
    {
        label: 'Working',
        value: 'working',
        icon: { icon: PunchClockIcon, color: lightBlue["400"] }
    },
    {
        label: 'Failure',
        value: 'failure',
        icon: { icon: HighlightOffIcon, color: red["800"] }
    }
]

export const UserFilterStatusOptions =  [
    {
        label: 'DELETED',
        value: '0'
    },
    {
        label: 'ACTIVE',
        value: '1'
    },
    {
        label: 'EMAIL VERIFICATION',
        value: '2'
    }
]

export const WalmartVisibilityOptions = [
    {label: 'ACTIVE PUBLISHED (AC_PU)', value: 'AC_PU'},
    {label: 'ACTIVE UNPUBLISHED (AC_UN)', value: 'AC_UN'},
    {label: 'ACTIVE SYSTEM PROBLEM (AC_SY)', value: 'AC_SY'},
    {label: 'RETIRED UNPUBLISHED (RE_UN)', value: 'RE_UN'},
    {label: 'RETIRED SYSTEM PROBLEM (RE_SY)', value: 'RE_SY'}
]

export const AmazonVisibility = [
    {label: 'LISTED', value: 'Listed'},
    {label: 'LISTABLE', value: 'Listable'},
    {label: 'UNKNOWN', value: 'Unknown'}
]

export const amazonOptiScore = [
    {
        label: '0  NOT LISTED',
        value: '0'
    },
    {
        label: '1  no any WBB',
        value: '1'
    },
    {
        label: '2  Not BP, WBB Other Seller',
        value:  '2'
    },
    {
        label: '3  Our BP,  WBB Other Seller ',
        value: '3'
    },
    {
        label: '4  Our BP,  No WBB',
        value:  '4'
    },
    {
        label: '5  Our WBB, Not our BP',
        value: '5'
    },
    {
        label: '6  We Have Wining Buy Box(WBB)&Best Price(BP)',
        value: '6'
    }
  
]

export const walmartOptiScore = [
    {
        label: '1  Not LP,  WBB Other Seller ',
        value: 1
    },
    {
        label: '2  Our WBB, Not our LP',
        value: 2
    },
    
    {
        label: '3  We Have Wining Buy Box(WBB)&Lowest Price(LP)',
        value: 3
    }
]

export const newEggOptiScore = [
    {
        label: 'Not Listed',
        value: 0
    },
    {
        label: '1 ~ Listed - We have competition with a better price than us',
        value: 1
    },
    {
        label: '2 ~ Listed - We have competition but we have the best price',
        value: 2
    },
    
    {
        label: '3 ~ Listed - We don\'t have any competition',
        value: 3
    }
]

export const shopifyOptiScore = [
    { label: 'Not Listed', value: 0},
    { label: 'Listed', value: 1}
]


export const getStatusGroupOptionsBySheetTabId = async (sheetTabId: SHEET_TAB_IDS)=> {
    const res = await getAllStatusBySheetTab(sheetTabId)
    return res.map((status) => ({
        label: status.label,
        value: `${status.id}`
    }))
}


export const FilterDefaultOptions = {
    winningBuyBox: {
        options: amazonWinningBuyBoxOptions
    },
    walmartVisibility: {
        options: WalmartVisibilityOptions
    },
    amazonVisibility: {
        options: AmazonVisibility
    },
    amazonOptiScore: {
        options: amazonOptiScore
    },
    walmartOptiScore: {
        options: walmartOptiScore
    },
    newEggOptiScore: {
        options: newEggOptiScore
    }
} as any


export const ThCellFilterFormComponentProps = {
    [FilterLinks.users]: {
        status: {
            options: UserFilterStatusOptions
        }
    },
    [FilterLinks.monitoringCronLogs]: {
        status: {
            options: CronLogsFilterStatusOptions
        },
        cronName: {
            options: async ()=> {
                const res = await fetchCronDefinitions()
                return res.map(cron => ({
                    label: cron.cronName,
                    value: cron.id
                }))
            }
        }
    },
    [FilterLinks.topSellers]: {
        active: {
            options: topSellersActiveOptions
        }
    },
    [FilterLinks.monitoringFeedback]: {
        storeId: {
            options: async ()=> {
                const res = await getSellers()
                return res.map(seller => ({
                    label: seller.name,
                    value: `${seller.sheetNumber}`
                }))
            }
        }
    },
    [FilterLinks.orders]: {
        siteId: {
            options: CaSiteIdsOptions
        },
        siteAccountId: {
            options: CaSiteAccountsOptions
        },
        shippingStatus: {
            options: CAOrderShippingStatus
        },
        paymentStatus: {
            options: CAOrderPaymentStatus
        }
    },
    [FilterLinks.legacyUnsecuredInventory]: {
        statusGroupId: {
            options: () => getStatusGroupOptionsBySheetTabId(SHEET_TAB_IDS.UNSECURED)
        }
    },
    [FilterLinks.legacyMarketingInitialDrop]: {
        statusGroupId: {
            options: () => getStatusGroupOptionsBySheetTabId(SHEET_TAB_IDS.INITIAL_DROP)
        }
    },
    [FilterLinks.legacyMarketingArchiveDrop]: {
        statusGroupId: {
            options: () => getStatusGroupOptionsBySheetTabId(SHEET_TAB_IDS.ARCHIVE_DROP)
        }
    },
    [FilterLinks.legacyMarketingAlex]: {
        statusGroupId: {
            options: () => getStatusGroupOptionsBySheetTabId(SHEET_TAB_IDS.ALEX)
        }
    },
    [FilterLinks.legacySecuredInvNew]: {
        statusGroupId: {
            options: () => getStatusGroupOptionsBySheetTabId(SHEET_TAB_IDS.NEW)
        }
    },
    [FilterLinks.optiScoreArchive]: {
        statusGroupId: {
            options: () => getStatusGroupOptionsBySheetTabId(SHEET_TAB_IDS.ARCHIVE_DROP)
        }
    },
    [FilterLinks.optiScoreInitialDrop]: {
        statusGroupId: {
            options: () => getStatusGroupOptionsBySheetTabId(SHEET_TAB_IDS.INITIAL_DROP)
        }
    },
    [FilterLinks.optiScoreNew]: {
        statusGroupId: {
            options: () => getStatusGroupOptionsBySheetTabId(SHEET_TAB_IDS.NEW)
        }
    },
    [FilterLinks.optiScoreOrder365]: {
        statusGroupId: {
            options: () => getStatusGroupOptionsBySheetTabId(SHEET_TAB_IDS.SHEET_365)
        },
        category: {
            options: getCategoriesBySheetId(SHEET_TAB_IDS.SHEET_365_PLUS)
        },
        isShopify: {
            options: shopifyOptiScore
        }
    },
    [FilterLinks.optiScoreSecuredNew]: {
        statusGroupId: {
            options: () => getStatusGroupOptionsBySheetTabId(SHEET_TAB_IDS.SHEET_365)
        },
        category: {
            options: getCategoriesBySheetId(SHEET_TAB_IDS.SHEET_365_PLUS)
        },
        isShopify: {
            options: shopifyOptiScore
        }
    },
    [FilterLinks.optiScoreSecuredUsed]: {
        statusGroupId: {
            options: () => getStatusGroupOptionsBySheetTabId(SHEET_TAB_IDS.SHEET_365)
        },
        category: {
            options: getCategoriesBySheetId(SHEET_TAB_IDS.SHEET_365_PLUS)
        },
        isShopify: {
            options: shopifyOptiScore
        }
    },
    [FilterLinks.legacySecuredInventory365Plus]: {
        statusGroupId: {
            options: () => getStatusGroupOptionsBySheetTabId(SHEET_TAB_IDS.SHEET_365)
        },
        sheetId: {
            options: getCategoriesBySheetId(SHEET_TAB_IDS.SHEET_365_PLUS)
        },
        isShopify: {
            options: shopifyOptiScore
        },
        amazonOptiScore: {
            options: amazonOptiScore
        },
        amazonOptiScoreCA: {
            options: amazonOptiScore
        }
    }
} as any
