import styled, { css } from "styled-components";
import {
    Box
} from "@mui/material";
import { Flex }        from "style";
import { grey }        from "@mui/material/colors";
import AmazonLink      from "pages/Legacy/Marketing/components/AmazonLink";

export const ProductPreviewContainer = styled(Box)`
  padding: 10px 20px;
  max-width: 1600px;
  margin: auto;
  max-height: calc(100% - 10px);
  overflow-y: auto;
`

export const ProductBox = styled(Box)`
  &&& {
    width: 100%;
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const ProductRow = styled(Flex)<{ content?: string, gap?: string }>`
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 5px 15px 5px;
  justify-content: space-between;
  flex-wrap: wrap;

  ${props => props?.content && css`
    justify-content: ${props.content};
  `}

  ${props => props?.gap && css`
    gap: ${props.gap};
  `}
`

export const ProductDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid #f8f8f8;
  padding-bottom: 15px;
  margin-bottom: 15px;
`

export const ProductDetailsBox = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const ProductDetailsAmazonLink = styled(AmazonLink)`
  text-align: left;
`

export const ProductDetailsTitleLabel = styled(Flex)`
  letter-spacing: .01786em;
  color: ${grey["600"]};
  min-width: 100px;
  text-transform: uppercase;
  margin-bottom: 10px;
  width: 100%;
`


/** channel details */

/** orders part */

export const ProductPOsOrdersBox = styled(Flex)`
  flex: 2;
  width: 100%;
  padding: 15px;
  justify-content: space-between;
  align-items: flex-start;

  > div {
    min-width: 48%;
    max-width: 48%;
    max-height: 480px;
  }
  > span {
    min-width: 48%;
    max-width: 48%;
    min-height: 200px;
    max-height: 480px;
  }
`

export const ProductSupplySaleOrdersEmptyBoxes = styled(Flex)`
`

/** export tabs empty */


export const ProductEmptyPage = styled(Box)`
  width: 100%;
  height: calc(100vh - 80px);
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const ProductEmptyPageText = styled(Flex)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: ${grey['600']};
  font-size: 200%;
`
