import React from 'react'
import {
  THCellContainer,
  THCellLabelSortContainer,
  THCellLabelContainer,
  ThCellLabel,
  ColumnVisibilityHolder,
  THCellResizer
} from "./style";
import ThCellButtonFilter from "components/Table/TableHeader/ThCellButtonFilter";
import TableColumnsVisibility from "components/Table/ColumnVisibility";
import { omit } from "lodash";

const ThCell = ({
  column,
  align = 'center',
  isVisibilityHeader = false,
  index,
  width,
  isResizable
}: any) => {
  
  const widthObj = Object.assign({}, width ? { width } : {})
  
  return (
    <THCellContainer variant={'head'} align={align}  {...column.getHeaderProps()} style={{
      ...omit(column.style, ['padding', 'paddingTop', 'paddingBottom']),
      ...widthObj,
      ...(omit(column?.headerStyle, ['padding', 'paddingTop', 'paddingBottom']) || {})
    }} index={index}>
      {isVisibilityHeader && <ColumnVisibilityHolder> <TableColumnsVisibility /> </ColumnVisibilityHolder>}
      <THCellLabelContainer>
        <THCellLabelSortContainer
          isFilterSort={column?.canFilter || column?.canSort}
        >
          <ThCellLabel>{column.render('Header')}</ThCellLabel>
        </THCellLabelSortContainer>
        {(column?.canFilter || column?.canSort) && <ThCellButtonFilter col={column} />}
      </THCellLabelContainer>
      {isResizable && <THCellResizer  {...column.getResizerProps()} isResizing={column.isResizing} /> }
    </THCellContainer>
  )
}

export default ThCell
