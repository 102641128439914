import React, {
    useCallback,
    useMemo
}                            from 'react'
import { ExportToCsv }       from 'export-to-csv';
import { TExportToCsvProps } from "components/ExportToCSV/d";
import { useLoading }        from "hooks/useLoading";
import {
    get,
    isString
} from "lodash";
import GetAppIcon            from '@mui/icons-material/GetApp';
import { processError }      from "utils/Error";
import { Tooltip }           from "@mui/material";
import {
    ExportCSVContainer,
    ButtonIcon
}                            from "./style"


const getRequestData = (headers: any, data: any) => {
    return data.map((x: any) => {
        return headers.reduce((acc: any,h: any) => {
            const value = get(x, h.key,'')
            return {
                ...acc,
                [h.realKey]: !value ? '' : value
            }
        },{} as any)
    })
}


const ExportToCSV = ({
    headers,
    filename,
    getData,
    requestOptions = {} as any
}: TExportToCsvProps) => {
    const { setLoading, resetLoading } = useLoading()

    const csvHeaders = useMemo(() => [...headers].filter(x=> !x.notShowExport).map((x: any) => ({
        label: isString(x.Header) ? x.Header : (x.HeaderExport || ''),
        key: !x?.model ? x.accessor : `${x.model}.${x?.fieldName ? x.fieldName : x.accessor}`,
        realKey: x.accessor
    })), [headers])

    const handlerOnClick = useCallback(async () => {
        if (!getData) return
        try {
            setLoading()
            const { result } = await getData({...requestOptions})
            const arr = getRequestData(csvHeaders,result?.rows)
            const options = {
                filename: filename,
                fieldSeparator: ';',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: filename,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: false,
                headers: csvHeaders.map((x: any) => x.label)
            };
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(arr);
        } catch (e) {
            processError(e)
            return
        } finally {
            resetLoading()
        }
    }, [resetLoading, setLoading, filename, getData, csvHeaders])

    return (
        <Tooltip title={'Export CSV'} placement={'top'}>
            <ExportCSVContainer>
                <ButtonIcon onClick={handlerOnClick}>
                    <GetAppIcon />
                </ButtonIcon>
            </ExportCSVContainer>
        </Tooltip>

    )
}

export default ExportToCSV
