import {
    TStoreSellers,
    TAction
}                              from "store/sellers/d";
import { SELLERS_SET_SELLERS } from "store/sellers/types";

const initial = {
    sellers: []
} as TStoreSellers

const sellerState = (state = initial, action: TAction): TStoreSellers => {

    switch (action.type) {

        case SELLERS_SET_SELLERS: {
            const sellers = action.payload as any
            return {
                ...state,
                sellers
            }
        }


        default:
            return state
    }

}

export default sellerState
