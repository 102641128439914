import {
    green,
    lightGreen,
    yellow,
    orange,
    red
} from "@mui/material/colors";

export const DEFAULT_PASSWORD = 'Cheapr2021$$'
export const ADMIN_ROLE_ID = 2

export enum VISIBILITY {
    NONE = 'Unknown',
    LISTED = 'Listed',
    LISTABLE = 'Listable'
}

export enum AmazonWinningBuyBox  {
    WINNING_BUY_BOX_OURS= 0xF,
    WINNING_BUY_FIRST_OUT_POSITION= 0x7,
    WINNING_BUY_BOX_EXISTS= 0x3,
    WINNING_BUY_BOX_NOT_EXISTS= 0x1,
    WINNING_BUY_BOX_UNDEFINED= 0
}

export const amazonWinningBuyBox = [
    { label: 'No-Result', value: 0 }, // result is not available
    { label: 'Not Exists', value: 1 }, // Product not have winningBoxPart
    { label: 'Winning Box - exists', value: 3 }, // WiningBox exists but not belongs to our client
    { label: 'First Position', value: 7 }, // Our client is first position but winning box not exist
    { label: 'Winning Box - Ours', value: 15 } // Our client is Wng
]

export const winningBuyBoxTooltipLegend = [
    '1 - no buybox ... we don\'t have the best price',
    '2 - buybox but we\'re not winning the buybox and we don\'t have the best price',
    '3 - buybox but we\'re not winning the buybox ~ we do have the best price',
    '4 - no buybox but we have the best price',
    '5 - winning buybox but we don\'t have the best price',
    '6 - winning buybox and we have the best price'
]

export const BsrTimeTooltipLegend = [
    { color: green[800], text: 'up to 15 min' },
    { color: lightGreen[300], text: 'up to 30 min' },
    { color: yellow[800], text: 'up to 1h' },
    { color: orange[800], text: 'up to 2h' },
    { color: red[800], text: 'greater than 2h' }
]


export enum CASiteIds {
    AMAZON=640,
    AMAZON_CA=644,
    SEARS=664,
    NEW_EGG=665,
    NEW_EGG_BUSINESS=926,
    EBAY_AUCTION=1,
    EBAY=576,
    EBAY_MOTORS_AUCTION=568,
    SHOPIFY=862,
    SHOPIFY_POS=917,
    NEW_EGG_CA=927,
    WALMART=996,
    INGRAM=89700012
}

export const CaSiteIdsOptions = [
    { label: 'Amazon', value: CASiteIds.AMAZON },
    //{ label: 'Amazon CA', value: CASiteIds.AMAZON_CA },
    { label: 'Sears', value: CASiteIds.SEARS },
    { label: 'Newegg', value: CASiteIds.NEW_EGG },
    { label: 'eBay', value: CASiteIds.EBAY },
    { label: 'Shopify', value: CASiteIds.SHOPIFY },
    //{ label: 'Newegg CA', value: CASiteIds.NEW_EGG_CA },
    { label: 'Walmart', value: CASiteIds.WALMART },
    { label: 'Ingram', value: CASiteIds.INGRAM }
]


export enum SHEET_TAB_IDS  {
    UNSECURED= 1,
    AUTHORIZED= 2,
    ALEX= 3,
    ARCHIVE_DROP= 4,
    INITIAL_DROP= 5,
    NEW= 0x41,
    USED= 0x42,
    FBA = 0x43,
    SECURED_LIMBO = 0x45,
    SECURED_UNASSESSED = 0x46,
    TOP_SELLERS = 0x48,
    SECURED_USED_NEW = 0x49,
    SHEET_365 = 0x71,
    SHEET_365_PLUS=0x99
}

export const SheetTabsOptions = [
    { label: 'Unsecured Inventory', value: SHEET_TAB_IDS.UNSECURED },
    { label: 'Alex', value: SHEET_TAB_IDS.ALEX },
    { label: 'Archive Drop', value: SHEET_TAB_IDS.ARCHIVE_DROP },
    { label: 'Initial Drop', value: SHEET_TAB_IDS.INITIAL_DROP },
    { label: 'New', value: SHEET_TAB_IDS.NEW }
]


export enum ORDER_365_CATEGORY {
    TOP_SELLERS=60,
    FBA_NEW= 66,
    SECURED= 67,
}


export const sheetNamesCategory = [
    { sheet: 'New', value: SHEET_TAB_IDS.NEW },
    { sheet: 'FBA', value: SHEET_TAB_IDS.FBA },
    { sheet: 'Limbo', value: SHEET_TAB_IDS.SECURED_LIMBO },
    { sheet: 'Used', value: SHEET_TAB_IDS.USED },
    { sheet: 'Tagged', value: SHEET_TAB_IDS.UNSECURED },
    { sheet: 'Risk', value: SHEET_TAB_IDS.ALEX },
]

export const categoriesBySheets = [
    {
        sheetId: SHEET_TAB_IDS.SHEET_365,
        categories: [
            { label: 'Secured', value: `${ORDER_365_CATEGORY.SECURED}` },
            { label: 'FBA New', value: `${ORDER_365_CATEGORY.FBA_NEW}` },
        ] as any
    },
    {
        sheetId: SHEET_TAB_IDS.SHEET_365_PLUS,
        categories: [
            { label: 'Secured', value: `1`},
            { label: 'Unsecured', value: `2` },
            { label: 'S & U', value: `3` },
        ] as any
    }
]
