import styled from "styled-components";
import { Flex } from "style";
import { blue } from "@mui/material/colors";
import ButtonIconComponent             from "components/Button/ButtonIcon";
import {
  CircularProgress
}                 from "@mui/material";
export const CountDownContainer = styled.div`
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      z-index: 10000;
`

export const CountDownBlurBackground = styled.div`
  display: flex;
  background: rgba(192, 192, 192, 0.8);
  width: 100%;
  height: 100%;
  z-index: 20000;
  backdrop-filter: blur(3px);
  position: absolute;
`

export const CountDownSpinner = styled(CircularProgress)`
  &&& {
    z-index: 25000;
  }
`;

export const CountDownHeaderText = styled.div`
  margin-bottom: 15px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-shadow: 0.8px 1px 0px #575757;
  z-index: 25000;
`

export const CountDownText = styled.div`
  margin-top: 15px;
  color: #ffffff;
  font-size: 32px;
  font-weight: 800;
  letter-spacing: 0.5px;
  text-shadow: 0.8px 1px 0px #575757;
  z-index: 25000;
`



export const SyncProductsManualContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
`

export const SyncButtonIcon = styled(ButtonIconComponent)`
  &&& {
    background-color: ${blue['800']};
    color: #fff;
    border: 2px solid ${blue['500']};
    box-shadow: 0 2px 2px #888;
    margin-top: 2px;
    &:hover {
      transform: scale(1.08);
    }
    
    &:active {
      transform: scale(0.92);
    }
  }
`