import React, {
    useState,
    useCallback,
    useEffect,
    useContext,
    useMemo
} from 'react'
import InputDatePicker  from "components/Input/InputDatePicker";
import TableDataContext from "components/Table/context/context";

const FilterDatePicker = ({column}: any) => {

     const [value,setValue] = useState<string|null>(null)
    const {filter, setFilter, clearFilter} = useContext(TableDataContext)
    const {id} = column

    const onChange= useCallback((date: any)=> {
        setValue(date)
    },[setValue])

    const onClear = useCallback(()=> {
        id && clearFilter(id)
        setValue(null)
    },[id, setValue, clearFilter])


    const label = useMemo(()=> `Search ${typeof column.Header === 'string' ? `${column.Header}` : ''}`,[column])

    useEffect(()=> {
        if(!filter[column?.id])return
        setValue(new Date(filter[column?.id]).toISOString())
    },[])

    useEffect(()=> {
        if (!value || !id || value === filter[id]) return
        setFilter({
            field: id,
            data: new Date(value).getTime()
        })
    },[id, value, filter, setFilter])

    return (
       <InputDatePicker
            isClearable={column.isClearable}
            value={value}
            onChange={onChange}
            onClear={onClear}
            label={label}
            variant="standard"
       />
    )
}


export default FilterDatePicker
