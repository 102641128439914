import styled, { css } from "styled-components";
import { Flex }        from "style";

export const WalmartVisibilityContainer = styled(Flex)<{color: string}>`
  height: 100%;
  justify-content: center;
  align-items: center;

  ${props => props.color && css`
    background-color: ${props.color};
  `}
`
