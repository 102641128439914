import styled, { css } from "styled-components";
import { Flex }        from "style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const OptiScoreContainer = styled(Flex)<{color: string}>`
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
 
  ${props => props.color && css`
    background-color: ${props.color};
  `}
`

export const FontAwesomeIconLink= styled(FontAwesomeIcon)`
  font-size: 10px;
`

