import {
    VISIBILITY,
    AmazonWinningBuyBox
} from "def";

const OUR_OFFER_ID = '10000004536'

export const wbEligibleAmazon = (row: any) => {
    const timeMin = new Date()
    timeMin.setDate(timeMin.getDate() - 30)
    if (row?.lastTimeWinningBuyBox > timeMin) return 'Y'
    return 'N'
}


export const getEbayColorText = (row: any) => {
    if (!row) return undefined
    if (row.ebayEndDate) {
        const date = new Date(row.ebayEndDate)
        let dateT = date.getTime()
        const current = new Date().getTime()
        if (current > dateT) {
            return {
                color: '#f4c7c3',
                text: 'Ended'
            }
        }
        const oneDay = 1000 * 60 * 60 * 24
        let _date = Math.floor(dateT / oneDay)
        const _current = Math.floor(current / oneDay)
        if (_date === _current) {
            return {
                color: 'blue',
                text: 'Ending Today'
            }
        }
        date.setDate(date.getDate() + 1)
        dateT = date.getTime()
        _date = Math.floor(dateT / oneDay)
        if (_date === _current) {
            return {
                color: 'transparent',
                text: 'Ending Day After'
            }
        }
        return {
            color: 'transparent',
            text: 'Active'
        }
    }
    if ((Number(row.ebayQuantity) === 0 && row?.ebayCountDownChecked) || !row?.active) {
        return {
            color: 'red',
            text: 'Sold'
        }
    }
    return {
        color: 'transparent',
        text: 'Active'
    }
}

export const visibilityForAmazon = (row: any) => {
    if (!row.asin) return ''
    const timeMin = new Date()
    timeMin.setDate(timeMin.getDate() - 30)
    if (row.amazonActive) return VISIBILITY.LISTED
    if (row.amazonStoreLastActive > timeMin) return VISIBILITY.LISTABLE
    return VISIBILITY.NONE
}

export const getOptimizeScoreForAmazon = (row: any) => {
    if (!row.amazonActive) return 0
    switch (row?.winningBuyBox) {
        case AmazonWinningBuyBox.WINNING_BUY_BOX_OURS: {
            if (row.lowestPriceFlag) return 6
            return 5
        }
        case AmazonWinningBuyBox.WINNING_BUY_BOX_EXISTS: {
            if (row.lowestPriceFlag) return 4
            return 3
        }
        case AmazonWinningBuyBox.WINNING_BUY_BOX_NOT_EXISTS:
        case AmazonWinningBuyBox.WINNING_BUY_FIRST_OUT_POSITION: {
            if (row.lowestPriceFlag) return 2
            return 1
        }
        default:
            return 0
    }
}


export const walmartVisibility = (walmart: any) => {
    if (!walmart?.walmartLifeCycleStatus || !walmart?.walmartPublishStatus) return ''
    return `${walmart?.walmartLifeCycleStatus.substring(0, 2)}_${walmart?.walmartPublishStatus.substring(0, 2)}`
}

export const getWalmartOptimizeScore = (walmart: any) => {
    if (!walmart.walmartActive) return ''
    if (walmart.walmartBuyBoxSellerId === OUR_OFFER_ID) {
        return walmart.walmartLowestPriceFlag ? 6 : 5
    }

    if (walmart.walmartBuyBoxSellerId !== null) {
        return walmart.walmartLowestPriceFlag ? 4 : 3
    }
    return walmart.walmartLowestPriceFlag ? 2 : 1
}


