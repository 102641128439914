import styled, { css } from "styled-components";
import { Flex }        from "style";
import { grey } from "@mui/material/colors";

export const LabelPart = styled(Flex)<{isUpperCase?: boolean, isCenter?: boolean}>`
  max-height: 20px;
  color: ${grey["600"]};
  opacity:0.8;
  width: 100%;
  
  ${props => props.isUpperCase && css`
    text-transform: uppercase;
  `}

  ${props => props.isCenter && css`
    justify-content: center;
  `}
  
`

export const RenderLabelValueContainer = styled(Flex)<{ isRow?: boolean}>`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 15px;

  ${props => props.isRow && css`
    flex-direction: row;
    
    ${LabelPart} {
      min-width: 120px;
      max-width: 180px;
      margin-right: 10px;
    }
  `}
  
`



export const ValuePart = styled(Flex)<{isNotValue?: boolean, align?: 'center' | 'flex-start' | 'flex-end', valueColor?: string}>`
  color: ${grey["700"]};
  font-size: inherit;
  width: 100%;
  justify-content: flex-start;
  
  ${props => props.isNotValue  && css`
    justify-content: center;
  `}
  
  ${props => props?.align && css`
    justify-content: ${props.align};
  `}
  ${props => props?.valueColor && css`
    color: ${props.valueColor};
  `}
`

export const EmptyValue = styled.span`
  opacity: 0.4;
  text-align: center;
`
