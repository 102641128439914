import React, { useMemo } from 'react'
import {
  BsrContainer,
  BsrTimerContainer,
  BsrTimeIcon,
  BsrTimeIconOff,
  RecordValidityPart,
  RecordValidityCircle
} from "pages/Legacy/components/Bsr/style";
import { getAmazonBsr } from "helpers";
import {
  green,
  lightGreen,
  orange,
  red,
  yellow
} from "@mui/material/colors";
import RecordValidity from "pages/Legacy/Order/365/RecordValidity";
import { PopoverHover } from "components/Popover";


const Bsr = ({row}: any)=> {
  
  const [bsr, timeFetched, asin, ourSeller] = useMemo(()=>[
    getAmazonBsr(row?.original?.amazonBsr),
    row?.original?.lastTimeFetch,
    row?.original?.asin,
    row?.original?.amazonOurSeller
  ],[row])
  
  
  const {timeColor, diffMinutes} = useMemo(()=> {
    const currentTime = new Date().getTime()
    const time = new Date(timeFetched).getTime()
    const diffMinutes = Math.round((currentTime - time) / 60000)
    const timeColor = (() => {
      if(diffMinutes <= 15) return green[800]
      if(diffMinutes <= 30) return lightGreen[300]
      if(diffMinutes <= 60) return yellow[800]
      if(diffMinutes <= 120) return orange[800]
      return red[800]
    })()
    return {
      timeColor,
      diffMinutes
    }
  },[timeFetched])
  
  const recordValidity = useMemo(()=>  !!ourSeller || diffMinutes <= 30,[ourSeller, diffMinutes])
  
  if(!ourSeller) {
    return (
      <BsrContainer>
        {bsr}
        <BsrTimerContainer timeColor={timeColor} isAsin={!!asin}>
          {asin ? <BsrTimeIcon /> : <BsrTimeIconOff />}
        </BsrTimerContainer>
        {recordValidity ? <RecordValidityPart value={recordValidity}>
          <RecordValidityCircle />
        </RecordValidityPart> : <></> }
      </BsrContainer>
    )
  }
  
  return (
    <PopoverHover Component={<RecordValidity row={row} />}>
      <BsrContainer isHovered>
        {bsr}
        <BsrTimerContainer timeColor={timeColor} isAsin={!!asin}>
          {asin ? <BsrTimeIcon /> : <BsrTimeIconOff />}
        </BsrTimerContainer>
        {recordValidity ? <RecordValidityPart value={recordValidity}>
          <RecordValidityCircle />
        </RecordValidityPart> : <></> }
      </BsrContainer>
    </PopoverHover>
    
  )
}

export default Bsr