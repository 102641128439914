import React, {memo,  useMemo} from 'react'
import { useTableForTabs } from "hooks/useTableForTabs";
import {
  tableColumns,
  requestOptions
} from "./helpers";
import {
  Table,
  Order365InfoIcon,
  Order365InfoContainer
} from "./style";
import { EnumTableIdsInSystem } from "pages/d";
import AmazonTooltip from "pages/product/components/AmazonTooltip";
import AmazonStoreStatus from "pages/Legacy/Order/365/AmazonStoreStatus";
import { fnFilters } from "pages/Legacy/Order/365/helpers";
import useRestrictionsAmazon from "hooks/useRestrictionsAmazon";
import SyncProductsManual from "pages/Legacy/components/SyncProductsManual";

const BufferQty = ({ isVisible }: any) => {

  const { needRefetch, setFetchDone, hideLoading } = useTableForTabs({ isVisible })

  const FooterChildren = useMemo(() => (<Order365InfoContainer>
    <Order365InfoIcon>
      <AmazonTooltip position={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} />
      <AmazonStoreStatus />
    </Order365InfoIcon>
    <SyncProductsManual />
  </Order365InfoContainer>), [isVisible])

  const {postFetchFn} = useRestrictionsAmazon()
  return (
    <Table
      needRefetch={needRefetch}
      hideLoading={hideLoading}
      preventFetch={!isVisible}
      refetchDone={setFetchDone}
      columns={tableColumns}
      title={'Buffer QTY'}
      modelName={'view_sheet_buffer_qty'}
      storageKey={'view_sheet_buffer_qty'}
      requestOptions={requestOptions}
      isGlobalSearch
      tableBordered
      tableSystemID={EnumTableIdsInSystem.booksBuffersBufferQty}
      FooterChildren={FooterChildren}
      isResizable
      fnFilters={fnFilters}
      postFetchFn={postFetchFn}
      actionDownloadDefinition={{
        restrictions: false,
        fbaErrors: false
      }}
    />
  )
}

export default memo(BufferQty)
