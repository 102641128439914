import styled, { css } from "styled-components";
import { Flex } from "style";
import ButtonIconComponent             from "components/Button/ButtonIcon";
import {
  blue,
  green
} from "@mui/material/colors";
import {
  List,
  ListItemAvatar,
  ListItem
} from "@mui/material";

export const AmazonStoreStatusContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
`

export const AmazonStoreStatusButtonIcon = styled(ButtonIconComponent)`
  &&& {
    background-color: ${blue['800']};
    color: #fff;
    border: 2px solid ${blue['500']};
    box-shadow: 0 2px 2px #888;
    margin-top: 2px;
    &:hover {
      transform: scale(1.08);
    }
    
    &:active {
      transform: scale(0.92);
    }
  }
`

export const AmazonStoresContainer = styled(Flex)`
  min-width: 200px;
  padding: 5px;
  flex-direction: column;
  justify-content: flex-start;
`

export const AmazonStoresHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`

export const AmazonStoresTitle = styled(Flex)`
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: #7c7c7c;
`

export const AmazonStoresListContainer = styled(List)`

`

export const AmazonStoreListItem = styled(ListItem)`
  &&& {
      padding: 0 5px;
      gap: 10px;
      border-bottom: 1px solid #eee;
  }
`;

export const AmazonStoreListItemAvatar = styled(ListItemAvatar)`
  &&& {
    min-width: 25px;
  }
`

export const AmazonStoreListItemStatusCircle = styled(Flex)<{isRestricted?: boolean}>`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: radial-gradient(circle at -1% 57.5%, rgb(19, 170, 82) 0%, rgb(0, 102, 43) 90%);
  
  ${props => props.isRestricted && css`
    background: radial-gradient(circle at -1% 57.5%,  rgb(247, 0, 0) 0%, rgb(150, 15, 15) 90%);
  `}
`

