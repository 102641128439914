import React, { memo } from 'react'
import TabHeader from "components/Tabs/TabHeader";
import TabContent from "components/Tabs/TabContent";
import { TabsContainer } from "components/Tabs/style";
import withTabs from "components/Tabs/useTabs";

const Tabs = memo((props: any) => {
  
  const { activeTab, tabs, handleChange } = props
  const isTopHeader = props?.tabHeaderPosition === 'top';
  return (
    <TabsContainer isTopHeader={isTopHeader}>
      <TabHeader tabs={tabs} value={activeTab} onChange={handleChange} isTopHeader={isTopHeader} />
      <TabContent tabs={tabs} activeTab={activeTab} />
    </TabsContainer>
  )
})

export default withTabs(Tabs)
