import { TAction } from "store/amazon/d";
import {
    AMAZON_SET_MARKET_PLACES,
    AMAZON_SET_SEARCH_CATEGORIES,
    AMAZON_SET_BRANDS
} from "store/amazon/types";
import {
    TAmazonCategory,
    TAmazonBrand,
    TAmazonSearchCategory
} from "api/amazon/d";

export const _actionSetAmazonMarkets = (markets: any) : TAction => ({
    type: AMAZON_SET_MARKET_PLACES,
    payload: markets
})

export const _actionSetAmazonSearchCategories = (amazonCategories: TAmazonSearchCategory[]) : TAction => ({
    type: AMAZON_SET_SEARCH_CATEGORIES,
    payload: amazonCategories
})

export const _actionSetAmazonBrands = (amazonBrands: TAmazonBrand[]) : TAction => ({
    type: AMAZON_SET_BRANDS,
    payload: amazonBrands
})
