import React, {useMemo} from 'react'
import {PopoverHover} from "components/Popover";
import UnitsLocations from "pages/Legacy/Order/365Plus/UnitsLocations";
import {CategoryContainer, CategoryContainerHasHover} from "pages/Legacy/Order/365Plus/Category/style";

const Order365Category = ({value, row, noUsed}: any) => {

    const categoryStr = useMemo(() => {
        const arr = []
        if (row.original?.isSecured && row.original?.isUnsecured) return 'S & U'
        if (row.original?.isSecured) arr.push('Secured')
        if (row.original?.isUnsecured) arr.push('Unsecured')
        return arr.join('')
    }, [row])


    const [isUnitLocation, caQuantity, blueStarQty] = useMemo(() => [
        row.original?.isSecured || row.original?.isUnsecured,
        row.original?.caQuantity,
        row.original?.blueStarQty
    ], [row])


    if (!isUnitLocation || !categoryStr) {

        if (caQuantity || blueStarQty) {
            return (
                <PopoverHover Component={<UnitsLocations row={row} noUsed={noUsed}/>}>
                    <CategoryContainer isHovered>
                        <CategoryContainerHasHover/>
                        {categoryStr}
                    </CategoryContainer>
                </PopoverHover>
            )
        }

        return (
            <CategoryContainer>
                {categoryStr}
            </CategoryContainer>
        )
    }


    return (
        <PopoverHover Component={<UnitsLocations row={row} noUsed={noUsed}/>}>
            <CategoryContainer isHovered>
                <CategoryContainerHasHover/>
                {categoryStr}
            </CategoryContainer>
        </PopoverHover>
    )
}


export default Order365Category
