import React from 'react'
import {
  AmazonProductOfferIsByBox,
  CircleGreen
} from "pages/Legacy/Marketing/components/AmazonProductOffers/style";
import { Tooltip } from "@mui/material";



const BuyBoxWinnerOurOfferCircle = ({value, row}: any) => {
  if(!value) return null
  return (
    <AmazonProductOfferIsByBox >
      <Tooltip title={"BuyBoxWinner"} placement="bottom">
        <CircleGreen/>
      </Tooltip>
    </AmazonProductOfferIsByBox>
  )
}

export default BuyBoxWinnerOurOfferCircle
