import styled from "styled-components";
import { Flex } from "style";
import { grey }           from "@mui/material/colors";
import {
    InfoRounded
}                         from "@mui/icons-material";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";

export const AmazonTooltipContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  min-width: 400px;
  max-width: 400px;
  padding: 10px;
`

export const AmazonTooltipRow = styled(Flex)`
  width: 100%;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  padding: 0 10px 3px 10px;
  border-bottom: 0.5px solid #f3f3f3;
  margin-bottom: 5px;
  gap: 15px;
`
export const AmazonTooltipColumn = styled(Flex)`
  width: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
  font-size: inherit;
`

export const AmazonTooltipHeader = styled(Flex)`
  height: 100%;
  width: 100%;
  color: ${grey['500']};
  border-bottom: 0.5px solid #f3f3f3;
  margin-bottom: 5px;
`

export const AmazonTooltipValue = styled(Flex)`
  flex-direction: column;
  align-items: center;
  letter-spacing: 0.2px;
`

export const Row = styled(Flex)`
  width: 100%;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-bottom: 5px;
`

export const AmazonTooltipInfoIcon = styled(InfoRounded)`
  cursor: pointer;
  color: ${grey['500']};
`

export const BsrTimerContainer = styled(Flex)<{timeColor?: string}>`
  &&& {
    font-size: 10px;
    opacity: 0.8;
    color: ${props => props.timeColor || 'inherit'};
    margin-right: 5px;
  }
`;

export const BsrTimeIcon = styled(AlarmOnIcon)`

`

export const BsrTimeText = styled(Flex)`

`
