import {
    API,
    rootApi
}                           from "api/index";
import { TCronDefinitions } from "./d";

export const fetchCronDefinitions = async (): Promise<TCronDefinitions[]> => {
    const response = await API.get(`${rootApi}/cron-definitions`)
    return response?.data?.result || []
}
