

import {
    API,
    rootApi
}                              from "api/index";
import { TProductStatusGroup } from "api/productGroupStatus/d";

export const getAllStatusBySheetTab = async (sheetTabId: number) : Promise<TProductStatusGroup[]> => {
    return (await API.post(`${rootApi}/sheet_product_main/getAllStatusBySheetTab`, {sheetTabId}))?.data?.result || []
}
