import { styled }      from "@mui/material/styles";
import {
    Select,
    MenuItemProps,
    MenuItem,
    ListItemIcon,
    ListItemText,
    ListItemTextProps,
    ListItemIconProps,
    IconButtonProps,
    IconButton,
    InputLabelProps,
    InputLabel,
    FormHelperTextProps,
    FormHelperText
} from "@mui/material";
import { SelectProps } from "@mui/material/Select/Select";



export const SelectContainer = styled((props: SelectProps) => (
    <Select {...props} />
))(({ theme, value} : any) => ({
    '& .MuiSelect-select': {
        position: 'relative',
        fontSize: '100%',
        textAlign: 'left',
        padding: '0 0 0 15px',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        //minHeight:  value?.length ? 34 : 'auto',
        minHeight: 34,

        '& .MuiListItemIcon-root': {
            display: 'none'
        },

        '& .MuiSvgIcon-root': {
            fontSize: 20,
            paddingBottom: 3
        }
    },
    '& .MuiInput-select': {
        height: '100%',
        width: '100%',
    },
    '&.MuiInput-root': {
        height: '100%',
        minHeight: 34,
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',

        '& .MuiSelect-select': {
            height: '100%',
            minHeight: 34
        }
    },
    '& .MuiSelect-iconStandard': {
        bottom: 2,
        right: -1,
        top: 'auto',
    },
    '& .MuiSelect-nativeInput': {
      display: 'none'
    }
}));

export const SelectInputLabel = styled((props: InputLabelProps) => (
    <InputLabel  {...props} />
))(({ theme }) => ({
    '&.MuiInputLabel-root': {
        transform: 'none',
        fontSize: '75%'
    }
}))
export const SelectMenuItem = styled((props: MenuItemProps) => (
    <MenuItem divider {...props} />
))(({ theme }) => ({
    '&.MuiMenuItem-root': {
        padding: '6px',
        position: 'relative',
        fontSize: '80%',
        maxWidth: '100%',

        '& .MuiSvgIcon-root': {
            marginLeft: 3,
            fontSize: 20
        },

        'svg': {
            width: 20,
            height: 20
        }
    }
}))

export const SelectMenuItemText = styled((props: ListItemTextProps) => (
    <ListItemText {...props} />
))(({ theme }) => ({
    '&.MuiListItemText-root': {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '15px'

    },
    '& .MuiListItemText-primary': {

    }
}))

export const SelectMenuItemIcon = styled((props: ListItemIconProps) => (
    <ListItemIcon {...props} />
))(({ theme }) => ({
    '&.MuiListItemIcon-root': {
        position: 'absolute',
        right: 2,
        minWidth: 'auto',
        justifyContent: 'flex-end'
    }
}))

export const SelectHelperText = styled((props: FormHelperTextProps) => (
    <FormHelperText {...props} />
))(({ theme }) => ({
    '&.MuiFormHelperText-root': {
        minHeight: '20px',
        marginLeft: 0,
        marginRight: 0,
    }
}))




export const SelectClearButton = styled((props: IconButtonProps)=> (
    <IconButton {...props}/>
))(({ theme }) => ({
    '&.MuiIconButton-root': {
        height: 20,
        width: 20,
        padding: 2,
        position: 'absolute',
        zIndex: 1,
        right: 0,
        top: -4,
    }
}))
