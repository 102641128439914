import React, {
    useContext,
    useMemo,
    memo,
    useEffect
} from 'react'
import {
    THCellButtonFilterContainer,
    ThCellFilterButton,
    ThCellFilterButtonSortIcon,
    ThCellFilterButtonFilterIcon,
    ThCellFilterButtonIcon
} from "components/Table/TableHeader/style";
import { Popover }           from "@mui/material";
import FilterSortCell        from "components/Table/TableHeader/FilterSortCell";
import TableDataContext      from "components/Table/context/context";



const ThCellButtonFilter = ({ col }: any) => {
    const {sort, filter} = useContext(TableDataContext)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const id = !!anchorEl ? 'simple-popover' : undefined;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const [isSort, isFilter, isAsc] = useMemo(()=> [sort?.field === col?.id, !!filter[col?.id], sort?.direction],[sort,filter, col])

    return (
        <THCellButtonFilterContainer>
            <ThCellFilterButton size="small" aria-describedby={id} onClick={handleClick} isActive={isSort || isFilter} _asc={isAsc}>
                {!isSort && !isFilter ? <ThCellFilterButtonIcon /> : isSort ? <ThCellFilterButtonSortIcon /> : <ThCellFilterButtonFilterIcon />}
            </ThCellFilterButton>
            <Popover
                id={id}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <FilterSortCell col={col} onClose={handleClose} />
            </Popover>
        </THCellButtonFilterContainer>
    )
}



export default ThCellButtonFilter
