import React, { useMemo }      from 'react'
import {
    AmazonLinkContainer,
    AmazonLinkUS,
    AmazonLinkCA
} from "pages/Legacy/Marketing/components/AmazonLink/style";
import Flag from "react-world-flags";


const AmazonLink = ({ value, className, isCa = true, row }: { value: string, className?: string, isCa?: boolean, row?: any }) => {

    const [asin,link] = useMemo(()=> [
        value,
        !/^[A-Za-z]+$/.test(value) && value !== 'N/A' ? `https://www.amazon.com/dp/${value}`: ''
    ], [value])
    
    const [isInvalidAsinUS, isInvalidAsinCA] = useMemo(()=> [
      row?.original?.isInvalidAsinUS,
      row?.original?.isInvalidAsinCA
    ],[row])

    if(!asin || !link) return <div>N/A</div>

    return (
        <AmazonLinkContainer title={'Amazon'} className={className}  isCa={isCa}>
            <AmazonLinkUS href={link} target={'_blank'} isInvalid={isInvalidAsinUS}>
                {asin}
            </AmazonLinkUS>
          {isCa && <AmazonLinkCA title={'Amazon CA'} href={`https://www.amazon.ca/dp/${value}`} target={'_blank'} isInvalid={isInvalidAsinCA}>
              <Flag code={"CAN"} />
            </AmazonLinkCA> }
        </AmazonLinkContainer>
    )
}

export default AmazonLink
