import React, {
  useContext
} from 'react'
import {
  FilterPopUpFormContainer,
  FilterPopUpHeader,
  FilterPopUpContent,
  FilterHeaderTitle,
  FilterPopUpCloseIcon,
  SavedFiltersContainer,
  SavedFiltersLabel,
  ButtonDelete
} from "components/Table/Filters/PopUpForm/style";
import { Clear, Delete as DeleteIcon } from "@mui/icons-material";
import { AppDivider } from "style";
import TableDataContext from "components/Table/context/context";
import SavedFilter from "components/Table/Filters/SavedFilter";
import ShowOneFilter from "components/Table/Filters/ShowOneFilter";


const FilterPopUpForm = ({handleClose}: any)=> {
  
  const data  = useContext(TableDataContext)
  
  const {savedFilters, filter, clearAllFilters } = data;
  const savedFilterKeys = Object.keys(savedFilters)
  
  const isFiltersExists = !!Object.keys(filter).length
  
  return (
    <FilterPopUpFormContainer>
      <FilterPopUpHeader>
        <FilterHeaderTitle>Filters</FilterHeaderTitle>
        { isFiltersExists && <ButtonDelete variant="outlined" startIcon={<DeleteIcon />}  onClick={clearAllFilters}>
          Clear
        </ButtonDelete> }
        
        
        <FilterPopUpCloseIcon onClick={handleClose}>
          <Clear />
        </FilterPopUpCloseIcon>
      </FilterPopUpHeader>
      <AppDivider />
      <FilterPopUpContent>
         <ShowOneFilter  filter = { filter } />
        <SavedFiltersContainer>
          <SavedFiltersLabel>SAVED FILTERS</SavedFiltersLabel>
          {savedFilterKeys.map(p => <SavedFilter id={p} key ={p} />)}
        </SavedFiltersContainer>
      </FilterPopUpContent>
    </FilterPopUpFormContainer>
  )
}


export default FilterPopUpForm
