import styled           from "styled-components";
import {
    Box,
    TableCell
} from "@mui/material";
import { Flex }         from "style";


export const TableEmptyCell = styled(TableCell)`
  &&& {
    border-bottom: none;
    position: relative;
    height: calc(100vh - 200px);
  }
`

export const TableEmptyContainer = styled(Box)`
  &&& {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
  }
`

export const TableEmptyIcon = styled(Flex)`
      
    &&& {
      margin-bottom: 20px;
      
      > svg {
        font-size: 120px;
      }
    }
`

export const TableEmptyMessage = styled(Flex)`
  font-size: 26px;
`
