import { SelectProps }      from "@mui/material/Select/Select";
import { SvgIconComponent } from "@mui/icons-material";

export const selectDropdownMaxHeight = 350;

export type TSelectOptionIcon = {
    icon: SvgIconComponent,
    color?: string
    isComponent?: boolean
}

export type TSelectOptions = {
    value: string|number
    label: string
    icon?: TSelectOptionIcon
    Component?: any
}


export type TSelectProps = {
    options: TSelectOptions[],
    label?: string,
    fullWidth?: boolean
    onClear?: ()=> void
    helperText?: string
    useHelperText?: boolean
    error?: string|boolean
    labelIcon?: boolean
    loading?: boolean
} & SelectProps
