import RestrictedField from "pages/Legacy/Marketing/components/RestrictedField";


const _RestrictedFields = (restProps: {isUsed?: boolean}) => [
   {
        Header: "RES CH",
        accessor: 'isRestrictedCh',
        sellerID: 4
    },
    {
        Header: "RES JT",
        accessor: "isRestrictedJT",
        sellerID: 8
    },
    {
        Header: "RES LL",
        accessor: "isRestrictedLL",
        sellerID: 7
    },
    {
        Header: "RES FF",
        accessor: "isRestrictedFF",
        sellerID: 6
    },
    {
        Header: "RES WX",
        accessor: "isRestrictedWX",
        sellerID: 9
    },
    {
        Header: "RES DV",
        accessor: "isRestrictedDV",
        sellerID: 10
    }
].map(x => ({

    Header: x.Header,
    accessor: x.accessor,
    canFilter: false,
    canSort: false,
    style: {
        minWidth: 60,
        width: 60,
    },
    Cell: (props: any) => <RestrictedField {...props}  sellerID={x.sellerID} {...restProps} />
  }))

const RestrictedFields = _RestrictedFields({})
export const RestrictedFieldsUsed = _RestrictedFields({isUsed: true})

export default RestrictedFields
