import React, {
  useContext,
  useCallback,
  useState
} from 'react'
import {
  Tooltip
} from "@mui/material";
import {
  SyncProductsManualContainer,
  SyncButtonIcon,
  CountDownContainer,
  CountDownText,
  CountDownHeaderText,
  CountDownBlurBackground,
  CountDownSpinner
} from "pages/Legacy/components/SyncProductsManual/style";
import TableDataContext from "components/Table/context/context";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import API from "api";
import {syncAmazonApiByAsin} from "api/amazon";


const SyncProductsManual = () => {
  const [countDown, setCountDown] = useState<number>(0)
  const [count, setCount] = useState<number>(0)

  const { modelName, requestOptions, setRefetch } = useContext(TableDataContext)

  const resetCounters = useCallback(()=> {
    setCount(0)
    setCountDown(0)
  },[setCount, setCountDown])

  const handlerOnClick = useCallback(async () => {
    try {
      setCountDown(1)
      const { result } = await API.getModelAllCount(modelName, requestOptions, true)
      const data = result.rows.filter((x: any)=> !!x.asin).filter((x: any)=> {
        const currentTime = new Date().getTime()
        const time = new Date(x.lastTimeFetch).getTime()
        const diffMinutes = Math.round((currentTime - time) / 60000)
        return diffMinutes > 5
      })
      if(data.length) {
        setCount(data.length)
        for (const r of data) {
          try {
              await syncAmazonApiByAsin(r.asin)

          }catch (e) {
            console.log(e)
            /** **/
          }finally {
            setCountDown((v: number) => v + 1)
          }
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      resetCounters()
      setRefetch()
    }
  }, [requestOptions, setRefetch, setCount, setCountDown, resetCounters])

  const CountDown = ({ countDown, count }: { countDown?: number, count: number }) => {
    return (
      <CountDownContainer>
        <CountDownBlurBackground />
        <CountDownHeaderText>Synchronization.. Please wait</CountDownHeaderText>
        <CountDownSpinner size={'4rem'} />
        <CountDownText>{ count ? `Remaining ${countDown} / ${count}` : ''}</CountDownText>
      </CountDownContainer>
    )
  }

  return (
    <>
      {countDown ? <CountDown countDown={countDown} count={count} /> : null}
      <Tooltip title={'Sync products'} placement={'top'}>
        <SyncProductsManualContainer>
          <SyncButtonIcon onClick={handlerOnClick}>
            <CloudSyncIcon />
          </SyncButtonIcon>
        </SyncProductsManualContainer>
      </Tooltip>
    </>

  )
}

export default SyncProductsManual
