import React from 'react'
import {
  AmazonTooltipContainer,
  AmazonTooltipRow,
  AmazonTooltipHeader,
  AmazonTooltipValue,
  AmazonTooltipInfoIcon,
  AmazonTooltipColumn,
  Row,
  BsrTimeIcon,
  BsrTimerContainer,
  BsrTimeText
} from "pages/product/components/AmazonTooltip/style";
import {
  winningBuyBoxTooltipLegend,
  BsrTimeTooltipLegend
} from "def";
import { PopoverHover } from "components/Popover";

const AmazonTooltip = ({
  position = { vertical: 'bottom', horizontal: 'center', },
  transformOrigin = { vertical: 'top', horizontal: 'left' }
}: any) => {
  
  const ComponentPopover = () => (
    <AmazonTooltipContainer>
      <AmazonTooltipRow>
        <AmazonTooltipColumn>
          <AmazonTooltipHeader>Winning Buy Box</AmazonTooltipHeader>
          <AmazonTooltipValue>
            {winningBuyBoxTooltipLegend.map((x, key) =>
              <Row key={key}>{x}</Row>
            )}
          </AmazonTooltipValue>
        </AmazonTooltipColumn>
        <AmazonTooltipColumn>
          <AmazonTooltipHeader>Clock Colors</AmazonTooltipHeader>
          <AmazonTooltipValue>
            {BsrTimeTooltipLegend.map((x, key) => (<Row key={key}>
                <BsrTimerContainer timeColor={x.color}>
                  <BsrTimeIcon />
                </BsrTimerContainer>
                <BsrTimeText>{x.text}</BsrTimeText>
              </Row>
            ))}
          </AmazonTooltipValue>
        </AmazonTooltipColumn>
      </AmazonTooltipRow>
    </AmazonTooltipContainer>
  )
  
  return (
    <PopoverHover position={position} transformOrigin={transformOrigin} Component={<ComponentPopover />}>
      <AmazonTooltipInfoIcon />
    </PopoverHover>
  
  )
}

export default AmazonTooltip
