import styled, { css, keyframes } from "styled-components";
import { Flex } from "style";
import {
  grey,
  green,
  blue
} from "@mui/material/colors";
import ButtonIcon from "components/Button/ButtonIcon";
import { Sync, Delete } from "@mui/icons-material";
import { Button } from "@mui/material";
import { CircularProgress } from "@mui/material";

const roll =  keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const FilterName = styled.div``

export const State= styled.div`

`

export const SyncButton = styled(ButtonIcon).attrs({
  color: 'primary'
})`

`

export const FilterNameContainer = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  flex: 2;
`


export const SyncIcon = styled(Sync)<{active?: boolean, isUsed?: boolean}>`
  &&& {
    color: ${grey["500"]};
    margin-right: 10px;
    
    ${props => props?.isUsed && css`
      color: ${blue['700']};
    `}
    
    ${props => props?.active && css`
      transform: rotate(30deg);
      animation: ${roll} 1.5s infinite;
    `}
    
  }
`
export const FilterButtonContainer = styled(Flex)`
  justify-content: flex-end;
  align-items: center;
  margin-left: 10px;
  min-width: 160px;
`

export const DeleteIcon = styled(Delete)`
  &&& {
    cursor: pointer;
    color: ${grey["500"]};
    margin-left: 10px;

    &:hover {
      transform: scale(1.0, 1.08);
    }
  }
`

export const DeleteConfirmContainer = styled(Flex)<{ isShow?: boolean }>`
  justify-content: space-between;
  align-items: center;
  transition: ease-in-out 1s;
  gap: 10px;
  opacity: 0;
  
  ${props => props?.isShow && css`
    opacity: 1;
  `}
  
`

export const ConfirmButton = styled(Button).attrs({
  color: 'primary',
  size: 'small',
  variant: 'contained'
})`
  &&& {
    max-height: 27px;
    padding: 4px 0;
    flex: 1;
  }
`

export const ButtonSpinner = styled(CircularProgress)`
  &&& {
    color: white;
  }
`

export const CancelButton = styled(Button).attrs({
  color: 'error',
  size: 'small',
  variant: 'contained'
})`
  &&& {
    max-height: 27px;
    padding: 4px 0;
    flex: 1;
  }
`

export const Container = styled(Flex)<{isUsed: boolean}>`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${grey[600]};
  font-weight: normal;
  font-size: 12px;
  padding: 4px 2px;
  border-bottom: 1px solid ${grey[100]};
  cursor: pointer;
  margin-top: 8px;
  white-space:nowrap;
  min-height: 37px;



  ${State} {
      border: 1px solid ${grey[300]};
      border-radius: 20px;
      padding: 4px 12px;
      margin-left: 10px;
      
      &:hover {
        transform: scale(1.0, 1.08);
        ;
     }
  }

  ${({ isUsed }) => isUsed && css`
       color: ${green[800]} ;
       font-weight: bold;
       font-size: 12px;
       border-bottom: 1px solid ${green[100]};

        ${State} {
          border:0px solid transparent;
        }
    
  `}
`
