import {
  SHEET_TAB_IDS,
  categoriesBySheets
} from "def";
import { NA_BSR_VALUE } from "def/amazon";
import {
  ThCellFilterFormComponentProps,
  FilterDefaultOptions
} from "components/Table/TableHeader/FilterSortCell/d";

export const getCategory365 = (data: any) => {

}

export const getCategoriesBySheetId = (sheetId = SHEET_TAB_IDS.SHEET_365_PLUS ) => categoriesBySheets.find(x=> +x.sheetId === +sheetId)?.categories || []

export const getCategoryValueBySheetId = (value: string|number, sheetId: number) => {
  const categories = categoriesBySheets.find(x=> x.sheetId === sheetId)?.categories || []
  if(!categories?.length) return
  return categories.find((c: any) => c.value === `${value}`)?.label || ''
}

export const _getCategoryValueBySheetId = (value: string|number, sheetId: number) => {
  const categories = categoriesBySheets.find(x=> x.sheetId === sheetId)?.categories || []
  if(!categories?.length) return
  const category = categories.find((c: any) => c.value === `${value}` || c.value.includes(`${value}`))
  return category?.label || ''
}

export const getAmazonBsr = (bsr: number) => {
  if(bsr === NA_BSR_VALUE) return 'N/A'
  if(bsr >= 100000) return ''
  return bsr
}

export const getFilterMap = ({
  pathname,
  tab,
  id
}:any) => {
  if(!id) return []
  let path = pathname
  if(tab) path = `${path}-${tab}`
  const props = ThCellFilterFormComponentProps?.[path]?.[id] || FilterDefaultOptions[id];
  if(!props) return []
  return ( ()=> {
    let opt = [] as any
    if(typeof props?.options === 'function') return []
    else opt = props?.options || []
    return opt
  })()
}
