import {
    red,
    green
}                             from "@mui/material/colors";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon       from "@mui/icons-material/HighlightOff";

export const ONE_DAY = 24

export const topSellersActiveOptions = [
    {
        label: 'Active',
        value: 1,
        icon: {
            icon: CheckCircleOutlineIcon,
            color: green["700"]
        }
    },
    {
        label: 'Inactive',
        value: 0,
        icon: {
            icon: HighlightOffIcon,
            color: red["700"]
        }
    }
]

export enum TopSellersTimeIntervals {
    LAST_30_M = 0.5,
    LAST_H = 1,
    LAST_2H = 2,
    LAST_4H = 4,
    LAST_12H = 12,
    LAST_DAY = ONE_DAY,
    LAST_2D = 2*ONE_DAY,
    LAST_3D = 3*ONE_DAY,
    LAST_4D = 4*ONE_DAY,
    LAST_15D = 15*ONE_DAY
}

export const topSellersTimeIntervalOptions = [
    { label: 'Last 30m', value: TopSellersTimeIntervals.LAST_30_M },
    { label: 'Last hour', value: TopSellersTimeIntervals.LAST_H },
    { label: 'Last 2h', value: TopSellersTimeIntervals.LAST_2H },
    { label: 'Last 4h', value: TopSellersTimeIntervals.LAST_4H },
    { label: 'Last 12h', value: TopSellersTimeIntervals.LAST_12H },
    { label: 'Last day', value: TopSellersTimeIntervals.LAST_DAY },
    { label: 'Last 2 day', value: TopSellersTimeIntervals.LAST_2D },
    { label: 'Last 3 day', value: TopSellersTimeIntervals.LAST_3D },
    { label: 'Last 4 day', value: TopSellersTimeIntervals.LAST_4D },
    { label: 'Last 15 day', value: TopSellersTimeIntervals.LAST_15D }
]

