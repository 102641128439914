import {
    NotExistsWB,
    WBExists,
    FirstPositionIcon,
    OurWBIcon
}                              from "pages/topSellers/components/WinningBuyBox/BuyBoxIcons";
import { AmazonWinningBuyBox } from "def/index";

export const NA_BSR_VALUE = 1000001

export const amazonWinningBuyBoxOptions = [
    { label: 'No-Result', value: AmazonWinningBuyBox.WINNING_BUY_BOX_UNDEFINED }, // result is not available
    { label: 'Not Exists', value: AmazonWinningBuyBox.WINNING_BUY_BOX_NOT_EXISTS, icon:  { icon: NotExistsWB, isComponent: true } }, // Product not have winningBoxPart
    { label: 'Winning Box - exists', value: AmazonWinningBuyBox.WINNING_BUY_BOX_EXISTS, icon: { icon: WBExists, isComponent: true  } }, // WiningBox exists but not belongs to our client
    { label: 'First Position', value: AmazonWinningBuyBox.WINNING_BUY_FIRST_OUT_POSITION, icon: { icon : FirstPositionIcon, isComponent: true } }, // Our client is first position but winning box not exist
    { label: 'Winning Box - Ours', value: AmazonWinningBuyBox.WINNING_BUY_BOX_OURS, icon: { icon : OurWBIcon, isComponent: true } } // Our client is Wng
]


export enum ProductsTypes {
    PRINTERS = 0,
    SCANNERS = 1
}
export enum AmazonItemMarketId {
    US = 1,
    CA = 2
}


export enum ProductMarket {
    US = 'US',
    CA = 'CA'
}