import React, {
  useCallback,
  useState,
  useEffect
} from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TInputDatePickerProps } from "components/Input/d";
import {
  TextField,
  InputAdornment
} from "@mui/material";
import { InputDatePickerClearIcon } from "components/Input/style";
import moment from "moment";

const InputDatePicker = ({
  label,
  value,
  onChange: _onChange,
  onClear,
  variant = "standard",
  size = "small",
  error,
  isClearable
}: TInputDatePickerProps) => {
  
  const [val, setVal] = useState<any>(value)
  
  const onChangeData = (data: any) => {
    setVal(data)
  }
  
  const _onClear = useCallback(() => value && onClear(), [value, onClear])
  
  useEffect(() => {
    setVal(value)
  }, [value])
  
  const onAccept = (newValue: any) => {
    if(moment(newValue).isBefore(moment('2005-01-01'))) {
      setVal(new Date())
      return
    }
    _onChange(newValue)
  }
  
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        label={label}
        value={val}
        maxDate={new Date()}
        onAccept={(newValue) => {
          
          _onChange(newValue);
        }}
        onChange={onChangeData}
        disableFuture
        renderInput={(params) => <TextField
          {...params}
          variant={variant}
          size={size}
          error={!!error}
          InputProps={{
            ...params.InputProps,
            readOnly: true,
            startAdornment: isClearable && (
              <InputAdornment
                position="start"
                disablePointerEvents={!value}
                disableTypography={!value}
                onClick={() => _onClear()}
              >
                <InputDatePickerClearIcon color={"inherit"} />
              </InputAdornment>
            )
          }}
        />}
      />
    </LocalizationProvider>
  )
}

export default InputDatePicker

