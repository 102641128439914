import React, {
  forwardRef,
  useEffect,
} from 'react'
import { FormControl, } from "@mui/material";
import {
  TSelectProps,
  selectDropdownMaxHeight
} from "components/Select/d";
import {
  SelectContainer,
  SelectMenuItem,
  SelectMenuItemIcon,
  SelectMenuItemText,
  SelectClearButton,
  SelectInputLabel,
  SelectHelperText
} from "components/Select/style";
import {
  Check,
  Clear,
  Delete
} from "@mui/icons-material";
import SkeletonHoc from "components/Utils/SkeletonHoc";
import { noop as NOOP } from "lodash";

const SelectText = forwardRef(({
  options,
  label,
  onChange = NOOP,
  onClear,
  fullWidth = false,
  variant = "standard",
  value,
  MenuProps,
  error,
  helperText,
  useHelperText = true,
  labelIcon,
  multiple,
  loading,
  ...rest
}: TSelectProps, ref) => {
  
  const _helperText = error ? error : helperText ? helperText : useHelperText ? <>&nbsp;</> : null
  const isValue = !!(multiple ? (value as any)?.length : value)
  const values = ((multiple ? value : [value]) || []) as (string | number)[]
  
  return (
    <SkeletonHoc is={loading}>
      <FormControl fullWidth={fullWidth}>
        {label && <SelectInputLabel error={!!error} id="select-label" shrink>{label}</SelectInputLabel>}
        {isValue && onClear ? <>
          <SelectClearButton size="small" onClick={onClear}>
            <Delete fontSize="small" />
          </SelectClearButton>
        </> : null}
        <SelectContainer
          ref={ref}
          labelId="select-label"
          label={label}
          value={value || ''}
          variant={variant}
          multiple={multiple}
          onChange={onChange}
          error={!!error}
          MenuProps={{
            ...MenuProps,
            sx: {
              ...(MenuProps?.sx || {}),
              maxHeight: selectDropdownMaxHeight
            }
          }}
          {...rest}
        >
          {options.map((option, i) => {
            const selected = values.includes(option.value)
            const icon = option?.icon
            const Component = option?.Component
             return (
              <SelectMenuItem key={i} value={option.value}>
                {
                  Component ?  Component : null
                }
                <SelectMenuItemText>{option.label}</SelectMenuItemText>
                {icon ?
                  !icon?.isComponent ?
                    <icon.icon htmlColor={icon.color || 'inherit'} />
                    : <icon.icon />
                  : selected &&
                  <SelectMenuItemIcon>
                    <Check color="success" fontSize="small" />
                  </SelectMenuItemIcon>
                }
              </SelectMenuItem>
            )
          })}
        </SelectContainer>
        {useHelperText && <SelectHelperText error={!!error}>{_helperText}</SelectHelperText>}
      </FormControl>
    </SkeletonHoc>
  )
})

export default SelectText
