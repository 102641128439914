import styled from "styled-components";
import { FontAwesomeIcon as FontAwesomeIconComponent } from "@fortawesome/react-fontawesome";

export const FontAwesomeIcon = styled(FontAwesomeIconComponent)`
   font-size: 16px;
   position: absolute;
   top: 0px;
   left: 20px;
`


export const FontAwesomeIconShopify = styled(FontAwesomeIcon)`
  left: 30%;
  top: -5px;
`

export const ShopifyHeaderContainer = styled.div``
