import {
    MenuItem,
    IconButton,
    Menu
} from "@mui/material";
import  styled    from "styled-components";
import { Flex }   from "style";
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import {
  ShopifyHeaderContainer,
  FontAwesomeIconShopify
} from "components/TableHeaders/ShopifyHeader/style";
import {
  OptiScoreHeaderContainer,
  FlagHeader,
  FontAwesomeIcon
} from "components/TableHeaders/OptiscoreHeader/style";

export const ColumnVisibilityIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 4px;
  }
`

export const ColumnVisibilityMenu = styled(Menu)`
  & .MuiMenu-list {
    padding: 4px 0;
  }
`

export const ColumnVisibilityMenuItem = styled(MenuItem)`
    &&& {
        padding-right: 5px;
        padding-left: 5px;
    }
`

export const ColumnVisibilityMenuItemIcon = styled(Flex)`
  justify-content: center;
  align-items: center;
  margin: 0px 4px;
`

export const ColumnVisibilityMenuItemLabel = styled(Flex)`
  flex: 2;
  padding-right: 5px;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
   
   ${OptiScoreHeaderContainer} {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        height: 100%;
        width: 100%;
       
       ${FontAwesomeIcon}{
           position: relative;
           top: auto;
           left: auto;
       }
       
        ${FlagHeader}{
            margin-right: 10px;
            position: relative;
            top: auto;
            left: auto;
        }
   }
   ${ShopifyHeaderContainer}{
       display: flex;
       justify-content: center;
       align-content: center;
       height: 100%;
       
       ${FontAwesomeIconShopify}{
           position: relative;
           left: auto;
           top: auto;
       }
   }
`
export const Icon = styled(HorizontalSplitIcon)`
  color: #fff;
`

