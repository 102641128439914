import React            from 'react'
import {
    TableEmptyContainer,
    TableEmptyIcon,
    TableEmptyMessage
}                       from "components/Table/TableEmpty/style";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import PendingIcon from '@mui/icons-material/Pending';

const TableEmpty = ({
    message = 'Fetching data, please wait...',
    isFetch = false
}) => {

    return (
        <TableEmptyContainer>
            <TableEmptyIcon >
                {!isFetch ? <WarningAmberIcon /> : <PendingIcon /> }
            </TableEmptyIcon>
            <TableEmptyMessage>{message}</TableEmptyMessage>
        </TableEmptyContainer>
)
}
export default TableEmpty
