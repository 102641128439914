import { useLoading } from "hooks/useLoading";
import React, {
  useMemo,
  useCallback
} from "react";
import {
  isString,
  get,
  omit,
  merge
} from "lodash";
import { ExportToCsv } from "export-to-csv";
import { processError } from "utils/Error";
import { formatDate } from "Utils";
import {
  tableDownloadRestrictionColumns,
  STORE_TABLE_DOWNLOAD_IDS,
  tableDownloadFbaErrorsColumns
} from "helpers/tableActionDefinitions";
import API from "api";
import TableChartIcon from "@mui/icons-material/TableChart";
import BlockIcon from "@mui/icons-material/Block";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { infoToast } from "components/Utils/Toast";

export type TActionDownloadDefinition = {
  restrictions?: boolean
  fbaErrors?: boolean
}

export type TableActionsDownloadProps = {
  headers: any,
  getData: any,
  tableTitle?: string,
  modelName?: string,
  actionDownloadDefinition?: TActionDownloadDefinition
  requestOptions?: any
  optionsEntry?: any
}
export const useTableActionsDownload = ({
  headers,
  getData,
  tableTitle,
  modelName,
  actionDownloadDefinition,
  requestOptions,
  optionsEntry
}: TableActionsDownloadProps) =>{
  
  const { setLoading, resetLoading } = useLoading()
  
  const csvHeaders = useMemo(() => [...headers].filter(x=> !x.notShowExport).map((x: any) => ({
    label: isString(x.Header) ? x.Header : (x.HeaderExport || ''),
    key: !x?.model ? x.accessor : `${x.model}.${x?.fieldName ? x.fieldName : x.accessor}`,
    realKey: x.accessor,
    fn: x.fn,
  })), [headers])
  
  const getRequestData = (headers: any, data: any) => {
    return data.map((x: any) => {
      return headers.reduce((acc: any,h: any) => {
        let value = get(x, h.key,'')
        if(value && h.fn) {
          value = h.fn(value) || ''
        }
        return {
          ...acc,
          [h.realKey]: !value ? '' : value
        }
      },{} as any)
    })
  }
  
  const downloadCsv = useCallback( (data: any, headersCsv: any, filename = `${tableTitle}${formatDate(new Date())}`) => {
      const options = {
        filename,
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: filename,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: headersCsv.map((x: any) => x.label)
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(data);
  }, [tableTitle])
  
  
  const downloadTableCsv = useCallback(async   () => {
    if (!getData) return
    try {
      setLoading()
      const { result } = await getData({...requestOptions})
      const arr = getRequestData(csvHeaders, result?.rows)
      downloadCsv(arr,csvHeaders)
    } catch (e) {
      processError(e)
      return
    } finally {
      resetLoading()
    }
  }, [resetLoading, setLoading, csvHeaders, getData, downloadCsv])
  
  /**
   * Download restriction csv
   */
  const downloadRestrictionCsv = useCallback(async () => {
    const headers = tableDownloadRestrictionColumns.map((h: any) =>({
      label: h.Header,
      realKey: h.accessor,
      key: h.accessor,
      notShowExport: h?.notShowExport,
      storeId: h.storeId
    }))
    
    const _csvHeaders = headers.filter((x: any)=> !x.notShowExport)
    
    const getRestrictionsData = (data: any) => {
      return data.map((x: any) => {
        return headers.reduce((acc: any, h: any) => {
          const value = (() => {
            const _val = get(x, h.key, '')
            if (!_val) return ''
            if (!h.storeId) return _val
            const storePrefix = (() => {
              switch (h.storeId) {
                case STORE_TABLE_DOWNLOAD_IDS.SU:
                  return 'Su'
                case STORE_TABLE_DOWNLOAD_IDS.CH:
                  return 'Ch'
                case STORE_TABLE_DOWNLOAD_IDS.SAMMY:
                  return 'Sammy'
                case STORE_TABLE_DOWNLOAD_IDS.FF:
                  return 'FF'
                case STORE_TABLE_DOWNLOAD_IDS.LL:
                  return 'LL'
                default:
                  return 'Kds'
              }
            })()
            const isRestrictedCA = x[`isRestricted${storePrefix}CA`]
            const isRestrictedUS = x[`isRestricted${storePrefix}US`]
            if (isRestrictedUS && isRestrictedCA) return 3
            if (!isRestrictedUS) return 2
            return 1
          })()
          
          return {
            ...acc,
            [h.realKey]: value
          }
        }, {} as any)
      })
    }
    
    try {
      setLoading()
      const { result } = await API.getModelAllCount(modelName, {
        page: 1,
        perPage: 50000,
        ...optionsEntry
      }, true)
      const rows = (result?.rows || []).filter((x: any)=> !!x.asin)
      const arr = getRestrictionsData(rows)
      if(!arr.length) {
        infoToast('We have not found any Restrictions at this time.')
      } else {
        downloadCsv(arr,_csvHeaders, `${tableTitle}-Restrictions-${formatDate(new Date())}`)
      }
    } catch (e) {
      processError(e)
      return
    } finally {
      resetLoading()
    }
  }, [tableTitle, modelName, resetLoading, setLoading, downloadCsv])
  
  /**
   * Download FBA Errors csv
   */
  const downloadFbaErrorsCsv =  useCallback(async () => {
    const headers = tableDownloadFbaErrorsColumns.map((h: any) =>({
      label: h.Header,
      realKey: h.accessor,
      key: h.accessor,
      notShowExport: h?.notShowExport,
      isError: h?.isError
    }))
    
    const _csvHeaders = headers.filter((x: any)=> !x.notShowExport)
    
    const getFbaErrorsData = (data: any) => {
      return data.map((x: any) => {
        return headers.reduce((acc: any, h: any) => {
          const value = (() => {
            const _val = get(x, h.key, '')
            if (!_val) return ''
            if(!h.isError) return _val
            return _val ? 'YES': 'NO'
          })()
          
          return {
            ...acc,
            [h.realKey]: value
          }
        }, {} as any)
      })
    }
    
    try {
      setLoading()
      const { result } = await API.getModelAllCount(modelName, {
        page: 1,
        perPage: 50000
      }, true)
      const rows = (result?.rows || []).filter((x: any)=> !!x.asin && (!!x.fbaErrorUS || !!x.fbaErrorCA))
      const arr = getFbaErrorsData(rows)
      if(!arr.length) {
        infoToast('We have not found any FBA errors at this time.')
      } else {
        const name = `${tableTitle}-FBA-ERRORS-${formatDate(new Date())}`
        downloadCsv(arr, _csvHeaders, name)
      }
    } catch (e) {
      processError(e)
      return
    } finally {
      resetLoading()
    }
  }, [tableTitle, modelName, resetLoading, setLoading, downloadCsv])
  
  
  return useMemo(()=> {
    const arr = [{ name: 'Download CSV', icon: <TableChartIcon />, handler: downloadTableCsv}]
    if(actionDownloadDefinition?.restrictions) arr.push( { name: 'Download Restrictions', icon: <BlockIcon />, handler: downloadRestrictionCsv })
    if(actionDownloadDefinition?.fbaErrors) arr.push({ name: 'Download FBA Errors', icon: <ErrorOutlineIcon />, handler: downloadFbaErrorsCsv})
    return arr
  },[actionDownloadDefinition, downloadTableCsv, downloadRestrictionCsv])
  
  
}