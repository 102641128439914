import styled from "styled-components";
import Colors from "def/Colors";

export const NewEggLinkContainer = styled.a`
    color: ${Colors.PALETTE.BLUE_GRAY._100};
    text-decoration: none;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    padding: 5px 2px 2px 5px;
    
  &:hover {
    text-decoration: underline;
  }
`
