import styled, { css } from "styled-components";
import { Flex }        from "style";

export const EbayStatusContainer = styled(Flex)<{color: string}>`
  justify-content: center;
  align-items: center;
  height: 100%;
  color: transparent;
  ${props => props.color && css` 
    background-color: ${props.color};
  `}
`
