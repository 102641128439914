import React           from 'react'
import { TButtonIcon } from "components/Button/d";
import { IconButton }  from "@mui/material";


const ButtonIcon = ({isActive, _asc, children, className, ...rest}: TButtonIcon)=> {

    return (<IconButton className={className} {...rest} >
        {children}
    </IconButton>)
}


export default ButtonIcon
