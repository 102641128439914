import React, { useMemo } from 'react'
import { BasePriceContainer } from "pages/Legacy/components/BasePrice/style";
import { formatPrice } from "Utils";
import { PopoverHover } from "components/Popover";
import PopoverBasePrice from "pages/Legacy/components/BasePrice/PopoverBasePrice";


const BasePrice = ({value, row}: any)=> {
  
  const isDiff = useMemo(()=> {
    if(!row?.original?.amazonOurPrice) return false
    return row?.original?.amazonOurPrice !== value
  },[row, value])
  
  
  return (
    <PopoverHover Component={<PopoverBasePrice row={row} />}>
      <BasePriceContainer isDiff={false}>
        {formatPrice(value)}
      </BasePriceContainer>
    </PopoverHover>
  )
}

export default BasePrice