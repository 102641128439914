import {
    TAction,
    TStoreAmazon
} from "store/amazon/d";
import {
    AMAZON_SET_MARKET_PLACES,
    AMAZON_SET_SEARCH_CATEGORIES,
    AMAZON_SET_BRANDS
} from "store/amazon/types";
import { TAmazonBrand } from "api/amazon/d";

const initial = {
    amazonMarketPlaces: [],
    amazonSearchCategories: [],
    amazonBrands: []
} as TStoreAmazon

const amazonState = (state = initial, action: TAction): TStoreAmazon => {

    switch (action.type) {

        case AMAZON_SET_MARKET_PLACES: {
            const amazonMarketPlaces = action.payload as any
            return {
                ...state,
                amazonMarketPlaces
            }
        }
        
        case AMAZON_SET_SEARCH_CATEGORIES: {
            const categories = action.payload as any
            return {
                ...state,
                amazonSearchCategories: categories
            }
        }
        
        case AMAZON_SET_BRANDS: {
            const amazonBrands = action.payload as TAmazonBrand[]
            return {
                ...state,
                amazonBrands
            }
        }


        default:
            return state
    }

}

export default amazonState
