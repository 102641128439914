import {
  API,
  rootApi
} from "api/index";

export const getSavedTableFilters = async (tableFrontendId: number) =>  {
  const response = await API.post(`${rootApi}/filters-definitions/get-by-frontend-table-id`, {tableFrontendId}) as any
  return response?.data || []
}


export const addTableFilter = async ({
  tableFrontendId,
  filterName,
  filter
}:{
  tableFrontendId: number,
  filterName: string,
  filter: any
  
}) => {
  return (await API.post(`${rootApi}/filters-definitions/insert-update-one`, {
    tableFrontendId,
    filterName,
    filter
  }))
}

export const removeTableFilter = async (id:number) => {
  return API.post(`${rootApi}/filters-definitions/delete-filter`, {
    id
  })
}
