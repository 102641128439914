import { CASL_SET_RULES } from "./types";
import {
    TAction,
    TCASLPermission
} from "./d";
import { TDispatch } from "store/d";
import { TReduxStore } from "store/index";
import { getPermissions } from "api/casl";

export const _actionCaslSetRules=  (payload?: TCASLPermission): TAction => ({
    type: CASL_SET_RULES,
    payload
})


let processingGettingPermissions = false;

export const _actionCaslFetchUserPermissions =
  (sha: string) =>
     (dispatch: TDispatch, getState: ()=>TReduxStore): void => {
          if(!sha || processingGettingPermissions) return;
          const state = getState()
          const currentSha = state.casl.sha
          if(sha === currentSha) return;
          processingGettingPermissions = true;
            getPermissions().then(v => {
              const obj = {
                rules: v.frontedPermissions,
                sha: v.frontedSha
              }
              dispatch(_actionCaslSetRules(obj))
              processingGettingPermissions =false
            }).catch(() => {
               processingGettingPermissions =false
            })
  
    };
