import React, { useMemo, memo } from 'react'
import Tabs               from "components/Tabs";
import BufferQty from "./BufferQty";

const BooksBuffers = () => {

    const tabs = useMemo(() => {
        return [
            {
                id: 'bufferqty',
                label: 'Buffer QTY',
                component: BufferQty,
                value: 0
            }
        ]
    }, [])
    return <Tabs tabs={tabs} tabHeaderPosition={'bottom'} />
}

export default memo(BooksBuffers)
