import React  from 'react'
import {
    ProductEmptyPage,
    ProductEmptyPageText
} from "pages/product/style";


const EmptyPage = () => {

    return (
        <ProductEmptyPage>
            <ProductEmptyPageText>Fetching data. Please wait...</ProductEmptyPageText>
        </ProductEmptyPage>
    )
}

export default EmptyPage
