import React, { useMemo }      from 'react'
import { WalmartLinkContainer } from "pages/Legacy/Marketing/components/WalmartLink/style";


const WalmartLink = ({ value, className }: { value: string, className?: string }) => {

    const [asin,link] = useMemo(()=> [
        value,
        !/^[A-Za-z]+$/.test(value) && value !== 'N/A' ? `https:///www.walmart.com/ip/${value}`: ''
    ], [value])

    if(!asin || !link) return <div>N/A</div>

    return (
        <WalmartLinkContainer className={className} href={link} target={'_blank'}>
            {asin}
        </WalmartLinkContainer>
    )
}

export default WalmartLink
