export const STORAGE_TOKEN = 'Cheaper-local-storage-token-996asdk12'
export const LOCAL_STORAGE_PREFIX = 'Cheapr-local-storage'

export class LocalStorage {

  static getData = (storage : string, suffix ?: string) => {
    if (suffix) {
      storage = `${storage}-${suffix}`
    }
    const data = localStorage.getItem(storage)
    if (!data) {
      return
    }
    try {
      const jsonObj = JSON.parse(data)
      if (!jsonObj) {
        return undefined
      }
      return jsonObj
    } catch (e) { /** some error */ }
    return undefined

  }

  static saveData = (data : any, storage : string, suffix ?: string) => {
    if (suffix) {
      storage = `${storage}-${suffix}`
    }
    localStorage.setItem(storage, JSON.stringify(data))
  }

  static removeItem = (storage : string, suffix ?: string) => {
    if (suffix) {
      storage = `${storage}-${suffix}`
    }
    localStorage.removeItem(storage)
  }
  
  static setTableColumnSettings = (key:string,columns: any[]) => {
    localStorage.setItem(`table-settings-${key}`, JSON.stringify(columns))
  }
  
  static  getTableColumnSettings = (key:string) => {
    const data = localStorage.getItem(`table-settings-${key}`)
    if(!data) return []
    try{
       const jsonObj = JSON.parse(data);
       return !jsonObj?.length ? []: jsonObj
    }catch(e){
       return []
    }
  }
  
  static setTableColumnResizing = (key: string, data: any) => {
    localStorage.setItem(`table-resizing-${key}`, JSON.stringify(data))
  }
  
  static  getTableColumnResizing = (key:string) => {
    const data = localStorage.getItem(`table-resizing-${key}`)
    if(!data) return []
    try{
      const jsonObj = JSON.parse(data);
      return !jsonObj ? {} : jsonObj
    }catch(e){
      return []
    }
  }

}

