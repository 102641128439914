import React, {
    useCallback,
    useMemo,
    useState,
    useEffect
} from 'react'
import {
    InputAdornment,
    Tooltip
}                             from "@mui/material";
import { TInputTextProps }    from "components/Input/d";
import { TextFieldContainer } from "components/Input/style";
import SkeletonHoc            from "components/Utils/SkeletonHoc";

const InputText = ({
    label,
    icon,
    iconRight,
    value,
    onChange,
    variant = 'standard',
    size = 'small',
    error,
    helperText,
    useHelperText = true,
    readOnly,
    loading = false,
    textAlign = 'left',
    selectOnFocus,
    onFocus,
    inputRef,
    ...rest
}: TInputTextProps) => {

    const [selectionOnFocus, setSelectionOnFocus] = useState<boolean>(false)


    const onHandlerChange = useCallback((e: any) => {
        onChange && onChange(e)
    }, [onChange])

    const iconsProps = useMemo(() => Object.assign({}, icon ? {
        startAdornment: icon?.tooltip ? (
            <Tooltip title={icon.tooltip.title || ""} placement={icon.tooltip?.placement || "top"}>
                <InputAdornment position="start" onClick={icon.handler}>
                    <icon.icon color={icon.color || 'inherit'} />
                </InputAdornment>
            </Tooltip>
        ) : ( <InputAdornment position="start" onClick={icon.handler}>
            <icon.icon color={icon.color || 'inherit'} />
        </InputAdornment>)
    } : {}, iconRight ? {
        endAdornment: (
            <InputAdornment position="end" style={{cursor: 'pointer'}} onClick={iconRight.handler}>
                <iconRight.icon color={iconRight.color || 'inherit'} />
            </InputAdornment>
        )
    } : {}), [icon, iconRight])



    const _onFocusHandler = React.useCallback((e: any) => {
        if (selectOnFocus && inputRef && (inputRef as any).current) {
            setSelectionOnFocus(true)
        }
        onFocus && onFocus(e)
    }, [onFocus, selectOnFocus, setSelectionOnFocus])

    const _helperText = useMemo(()=> error ? error : helperText ? helperText : useHelperText ? <>&nbsp;</> : null,[error, helperText, useHelperText, ])


    useEffect(() => {
        if (!selectionOnFocus || !selectOnFocus || !inputRef) {
            return
        }
        if( (inputRef as any).current) {
            const input =(inputRef as any).current.getElementsByTagName('input')
            if(input?.length) input[0].select()
        }
    }, [selectionOnFocus, setSelectionOnFocus, selectOnFocus, inputRef])


    return (
        <SkeletonHoc is={loading}>
            <TextFieldContainer
                ref={inputRef}
                label={label}
                InputProps={{
                    ...iconsProps,
                    readOnly,
                }}
                inputProps={{
                   style: {
                       textAlign
                   }
                }}
                error={!!error}
                value={value || ""}
                onChange={onHandlerChange}
                onFocus={_onFocusHandler}
                variant={variant}
                FormHelperTextProps={{
                    style: {
                        display: 'block',
                        minHeight: '20px'
                    }
                }}
                size={size}
                helperText={_helperText}
                {...rest}
            />
        </SkeletonHoc>
    )
}

export default InputText
