import React, {
  useContext,
  useState,
  useCallback
} from 'react'
import TableDataContext from "components/Table/context/context";
import {
  Container,
  FilterName,
  State,
  SyncIcon,
  FilterNameContainer,
  DeleteIcon,
  FilterButtonContainer,
  DeleteConfirmContainer,
  CancelButton,
  ConfirmButton,
  ButtonSpinner
} from "./style"
import { useLoading } from "hooks/useLoading";


const SavedFilter = ({id}:{id: string}) => {
  const { isMini, setLoading, resetLoading } = useLoading()
  const [needDelete, setNeedDelete] = useState<number>(0)
  const {  savedFilters, filterHash, reuseSavedFilter, removeFilterToBackend  }  = useContext(TableDataContext)
  const item = savedFilters[id]
  const isUsed = id === filterHash
  
  const handlerNeedDelete = useCallback(()=> {
    setNeedDelete(new Date().getTime())
  },[setNeedDelete])
  
  const handlerCancel = useCallback(()=> {
    setNeedDelete(0)
  },[setNeedDelete])
  
  const handlerConfirm = useCallback(async ()=> {
    setLoading({
      isMini: true
    })
    await removeFilterToBackend(item.id)
    setNeedDelete(0)
    resetLoading()
  },[removeFilterToBackend, setLoading, item, setNeedDelete])
  
  if(!item) return null
  return(
   <Container isUsed = { isUsed } >
      <FilterNameContainer onClick={() => reuseSavedFilter(id)}>
        <SyncIcon active={isMini && isUsed} isUsed={isUsed}/>
        <FilterName>{item.filterName}</FilterName>
      </FilterNameContainer>
     <FilterButtonContainer>
       {
         needDelete ? (
             <DeleteConfirmContainer isShow={!!needDelete}>
               <CancelButton onClick={handlerCancel}>CANCEL</CancelButton>
               <ConfirmButton onClick={handlerConfirm}>
                 {isMini ? (<ButtonSpinner size={20}  />): 'DELETE'}
               </ConfirmButton>
             </DeleteConfirmContainer>
           )
           : (
             <>
               <DeleteIcon onClick={handlerNeedDelete} />
               <State onClick={() => reuseSavedFilter(id)}>{isUsed?"CURRENT FILTER":"USE THIS FILTER"}</State>
             </>
           )
       }
     </FilterButtonContainer>
  </Container>)
}

export default SavedFilter
