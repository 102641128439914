import React, {
  PropsWithChildren
} from "react";
import TableDataContextContainer from "components/Table/context";
import { TTableDataProps } from "components/Table/d";
import TableComponent from "components/Table/TableComponent";
import Filters from "components/Table/Filters";

const TableData = ({
  columns,
  modelName,
  title,
  fnFilters,
  needRefetch,
  refetchDone,
  tableBordered,
  hideLoading,
  preventFetch,
  usePagination = true,
  className,
  emitFetchSuccess,
  csvFileName,
  isResetPagination,
  rowsPerPageOptions,
  children,
  useHeader = false,
  FooterChildren,
  useCustomChildren,
  isResizable,
  actionDownloadDefinition,
  postFetchFn,
  ...rest
}: PropsWithChildren<TTableDataProps>) => {


  return (
    <TableDataContextContainer columns={columns} fnFilters={fnFilters} modelName={modelName} {...rest} >
      <TableComponent
        csvFileName={csvFileName}
        preventFetch={preventFetch}
        hideLoading={hideLoading}
        className={className}
        modelName={modelName}
        emitFetchSuccess={emitFetchSuccess}
        title={title}
        needRefetch={needRefetch}
        refetchDone={refetchDone}
        tableBordered={tableBordered}
        usePagination={usePagination}
        isResetPagination={isResetPagination}
        rowsPerPageOptions={rowsPerPageOptions}
        useHeader={useHeader}
        FooterChildren={FooterChildren}
        isResizable={isResizable}
        postFetchFn={postFetchFn}
        actionDownloadDefinition={actionDownloadDefinition}
      >
        {!useCustomChildren && children}
      </TableComponent>
      {useCustomChildren && children}
      { rest.tableSystemID  &&  <Filters /> }
    </TableDataContextContainer>
  )
}

export default TableData
