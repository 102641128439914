import styled, { css } from "styled-components";
import { Flex } from "style";
import Colors from "def/Colors";
import RenderLabelValue from "components/Utils/Render";
import {
  LabelPart,
  ValuePart
} from "components/Utils/Render/style";

export const BasePriceContainer = styled(Flex)<{isDiff?: boolean}>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
  cursor: pointer;
  
  ${props => props.isDiff && css`
    color: ${Colors.PALETTE.RED._800};
  `}
  
`


export const PopoverBasePriceContainer = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 250px;
  max-width: 250px;
  padding: 10px;
  gap: 5px;
`

export const PopoverCaContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3px;
  width: 100%;
`

export const PopoverAmzContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3px;
  width: 100%;
`

export const PopoverTitle = styled(Flex)`
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: #7c7c7c;
  font-size: 10px;
  opacity: 0.6;
`
export const PopoverRow = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const LabelValueComponent = styled(RenderLabelValue)`
  &&& {
    ${LabelPart}{
      font-size: 8.5px;
    }
    ${ValuePart}{
      font-weight: bold;
    }
  }
`