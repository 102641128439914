import React, {
    useEffect,
    useState,
    useCallback,
    useContext,
    useMemo
} from 'react'
import {
    useLocation,
    useSearchParams
} from "react-router-dom";
import {
    ThCellFilterFormComponentProps,
    FilterDefaultOptions
} from "components/Table/TableHeader/FilterSortCell/d";
import TableDataContext                   from "components/Table/context/context";
import { FilterSelectContainer }          from "components/Table/TableHeader/FilterSortCell/style";



const FilterSelect = ({column}: any) => {
    const [value, setValue] = useState<number|string|null>(null)
    const {filter, setFilter, clearFilter} = useContext(TableDataContext)
    const [options, setOptions] = useState([] as any)
    const { pathname } = useLocation()
    const [searchParams] = useSearchParams();
    const {id} = column
    
    const labelHeader = useMemo(()=> column.HeaderExport ? column.HeaderExport : column.Header,[column])

    const onChange= useCallback((e: any)=> {
        const val = e.target.value
        val && setValue(val)
    },[setValue])

    const onClear = useCallback(()=> {
        id && clearFilter(id)
        setValue('')
    }, [setValue, clearFilter, id])


    useEffect(()=> {
        if(!filter[column?.id])return
        setValue(filter[column?.id])
    },[])

    useEffect(() => {
        if(!id) return
        let path = pathname
        const tab = searchParams.get('tab')
        if(tab) path = `${path}-${tab}`
        const props = ThCellFilterFormComponentProps?.[path]?.[id] || FilterDefaultOptions[id];
        if(!props) return
        (async ()=> {
            let opt = [] as any
            if(typeof props?.options === 'function') opt = await props.options()
            else opt = props?.options || []
            setOptions(opt)
        })()
    },[id, pathname, searchParams, setOptions])


    useEffect(()=> {
        if (!value || !id || value === filter[id]) return
        setFilter({
            field: id,
            data: `${value}`
        })
    },[id, value, filter, setFilter])
  
    return <FilterSelectContainer
        label={`Search ${labelHeader}`}
        options={options}
        disabled={!options?.length}
        value={value}
        onChange={onChange}
        onClear={onClear}
        MenuProps={{
            sx: {
                maxWidth: '400px'
            }
        }}
    />

}

export default FilterSelect
