import { TUser }         from "store/auth/d";
import {
    AUTH_SET_USER,
    AUTH_CLEAR_USER
} from "store/auth/types";

export const _actionAuthSetUser = (payload: TUser) => ({
    type: AUTH_SET_USER,
    payload
})

export const _actionAuthClearUser = () => ({
    type: AUTH_CLEAR_USER
})
