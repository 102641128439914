import React, { useMemo }      from 'react'
import { NewEggLinkContainer } from "pages/Legacy/Marketing/components/NewEggLink/style";


const NewEggLink = ({ value, className }: { value: string, className?: string }) => {

    const [itemNumber,link] = useMemo(()=> [
        value,
        !/^[A-Za-z]+$/.test(value) && value !== 'N/A' ? `https://www.newegg.ca/p/${value}`: ''
    ], [value])
    
    

    if(!itemNumber || !link) return <div>N/A</div>

    return (
        <NewEggLinkContainer className={className} href={link} target={'_blank'}>
            {itemNumber}
        </NewEggLinkContainer>
    )
}

export default NewEggLink
