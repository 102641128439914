import styled from "styled-components";
import { Flex } from "style";

export const Container = styled(Flex)`
  height: 100%;
  width: 100%;
  justify-content: space-between;
  padding: 0 2px;
  align-items: center;
  flex-direction: row-reverse;
`

export const FlagContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  width: 16px;
  height: 16px;

  &:hover {
    transform: scale(1.2);
  }

  &:active {
    transform: scale(0.8);
  }
`
