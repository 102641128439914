export enum TABLE_EVENTS {
    changeVisibilityColumn = 'changeVisibilityColumn',
    setPage = 'setTablePage',
    setPerPage = 'setTablePerPage',
    setSortBy = 'setSortBy',
    clearNeedRefetch = 'clearNeedRefetch',
    addFilter='addFilter',
    addMultiFilter = 'addMultiFilter',
    addAdditional = 'addAdditional',
    clearAdditional = 'addAdditional',
    columnsChange = 'columnsChange',
    clearFilter='clearFilter',
    clearSort='clearSort',
    checkCurrentFilterHash='checkCurrentFilterHash',
    setCurrentTableFilters ='setCurrentTableFilters',
    setFilterFromSaved ='setFilterFromSaved',
    clearAllFilters ='clearAllFilters',
    setRefetch = 'setRefetch',
    setResizeColumns = 'setResizeColumns'
}

export type TChangeColumnVisibilityPayLoad = {
    column: string,
    isVisible?: boolean
}

export type TReducerAction = {
    type: TABLE_EVENTS;
    payload?: number | string | null | TChangeColumnVisibilityPayLoad | any;
}

export type TSort = {
    direction: boolean,
    field: string
    model?: string | string[]
}

export type TTableColumn = {
    isVisible: boolean,
    accessor: string
    fieldType?: string
    required?: boolean
}

export type TSavedFilters = {
    filterName: string,
    filter: any,
    active: boolean,
    userId: boolean,
}

export type TReducer = {
    columns: TTableColumn[],
    savedFilters: {
        [key:string]: TSavedFilters
    }
    tableId?: string,
    page?: number
    perPage?: number
    sort?: TSort
    isNeedRefetch?: number
    filter?: any,
    filterHash?: string,
    isExportCsv?: boolean
    isGlobalSearch?: boolean
    additional?: any
    tableSearchTooltip?: string,
    tableSystemID?: number
    columnResizing?: any
}

export type TFilerPayload = {
    field: string
    data: any
}
