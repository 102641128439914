import React, {
  useMemo,
  useContext,
  useCallback
} from 'react'
import TableDataContext from "components/Table/context/context";

import DoneAllIcon from '@mui/icons-material/DoneAll';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
    ColumnVisibilityMenuItemIcon,
    ColumnVisibilityMenuItemLabel,
    ColumnVisibilityMenuItem,
    Icon,
    ColumnVisibilityIconButton,
    ColumnVisibilityMenu
} from "./style";

const TableColumnsVisibility = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { columns: _allColumns, toggleVisibilityColumn, filter, clearFilter } = useContext(TableDataContext)

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const allColumns = useMemo(()=> _allColumns.filter((x: any)=> !x?.required) ,[_allColumns])

  const open = useMemo(() => !!anchorEl, [anchorEl])

  const changeColumnVisibility = useCallback((column: any) => {
    toggleVisibilityColumn(column.accessor)
    if(filter[column?.accessor]) clearFilter(column.accessor)
  }, [toggleVisibilityColumn, filter, clearFilter])

  const totalHeight = allColumns?.length * 32 + 2;


  return (
    <>
      <ColumnVisibilityIconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Icon />
      </ColumnVisibilityIconButton>
      <ColumnVisibilityMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            //maxHeight: ITEM_HEIGHT * 20,
            height: `${totalHeight}px`,
            width: '22ch',
          },
        }}
      >
        {
          allColumns?.map((column: any, index: number) => (
            <ColumnVisibilityMenuItem key={index} divider={allColumns.length !== index + 1} onClick={() => changeColumnVisibility(column)}>
              <ColumnVisibilityMenuItemLabel>{column.Header}</ColumnVisibilityMenuItemLabel>
              <ColumnVisibilityMenuItemIcon>{column.isVisible ? <DoneAllIcon fontSize="small" color={"success"} /> : <CancelOutlinedIcon fontSize="small" color={"error"} />}</ColumnVisibilityMenuItemIcon>
            </ColumnVisibilityMenuItem>
          ))
        }
      </ColumnVisibilityMenu>
    </>
  )
}

export default TableColumnsVisibility
