import React, {
    useState,
    useCallback,
    useMemo
}                          from 'react'
import InputText           from "components/Input/index";
import { TInputTextProps } from "components/Input/d";
import { NOOP }            from "Utils";
import { Clear }           from "@mui/icons-material";



const InputNumber = ({
    onInput = NOOP,
    onChange = NOOP,
    onClear = NOOP,
    defaultValue,
    ...rest
}: TInputTextProps & {onClear: ()=> void}) => {

    const [value, setValue] = useState(defaultValue as string || '')

    const _onInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.persist()
            e.target.value = (e.target.value || "").replace(/[^0-9,.]/g,'')
            onInput && onInput(e);
        },
        [onInput],
    );

    const _onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist()
        setValue(e.target.value)
        onChange && onChange(e)
    }, [setValue, onChange])

    const handlerClear = useCallback(()=> {
        onClear()
        setValue('')
    },[onClear,setValue])

    const iconRight = useMemo(()=>  value ? {
        icon: Clear,
        handler: handlerClear,
        color: 'error'
    } : {
        icon: Clear,
        color: 'default'
    } as any,[value, handlerClear])

    return (
        <InputText
            {...rest}
            iconRight={iconRight}
            value={value}
            onInput={_onInput}
            onChange={_onChange}
        />
    )
}

export default InputNumber
