import React, { useMemo }      from 'react'
import { EbayStatusContainer } from "pages/Legacy/Marketing/components/EbayStatus/style";
import { getEbayColorText }    from "pages/Legacy/Marketing/helpers";
import { Tooltip }             from "@mui/material";

const EbayStatus = ({ row }: { row: any }) => {
    const data = useMemo(() => getEbayColorText(row), [row])
    if (!data) return <></>
    const { color, text } = data
    return (
        <Tooltip title={text || ""} placement="right">
            <EbayStatusContainer color={color}> </EbayStatusContainer>
        </Tooltip>
    )
}

export default EbayStatus
