//import { styled }         from "@mui/material/styles";
import {
    TextField,
    InputAdornment
}                         from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import styled, { css }    from 'styled-components'
import CloseIcon          from "@mui/icons-material/Close";
import { grey }           from "@mui/material/colors";



/*export const TextFieldContainer = styled((props: TextFieldProps) => (
    <TextField  {...props} />
))(({ theme }) => ({
    '& .MuiInput-root': {
        fontSize: '85%',
        minHeight: 34
    },
    '& .MuiFormHelperText-root': {
        minHeight: '20px'
    }
}))*/

export const TextFieldContainer = styled(TextField)<{InputProps?: any}>`
  &&& {
    ${props => props.InputProps?.readOnly && css`
      opacity: 0.7;
      color: ${grey["300"]};
      
    `}
    
    .MuiInput-root {
      font-size: 105%;
      min-height: 34px;
      ${props => props.InputProps?.readOnly && css`
       border-color: ${grey["300"]};
     `}
    }

    .MuiFormHelperText-root {
        min-height: 20px;
    }
  }
`
export const InputDatePickerClearIcon = styled(CloseIcon)`
  &&& {
      cursor: pointer;
  }
`
