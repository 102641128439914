import React, { useCallback } from 'react'
import {
    PopoverHoverBox,
    PopoverHoverChildBox,
    StyledPopover
} from "components/Popover/style";
import { TPopoverHoverProps } from "components/Popover/d";


const PopoverHover = ({
    Component,
    text,
    children,
    className,
    position = { vertical: 'bottom', horizontal: 'center', },
    transformOrigin = { vertical: 'top', horizontal: 'left' }
}: TPopoverHoverProps)=> {

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    },[setAnchorEl]);

    const handlePopoverClose = useCallback(() => {
        setAnchorEl(null);
    },[setAnchorEl]);
    

    return (
        <PopoverHoverBox className={className}>
            <PopoverHoverChildBox
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {children}
            </PopoverHoverChildBox>
            <StyledPopover
                sx={{
                    pointerEvents: 'none',
                }}
                open={!!anchorEl}
                anchorEl={anchorEl}
                anchorOrigin={position}
                transformOrigin={transformOrigin}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {
                    Component ? Component :
                        text ? text : null
                }
            </StyledPopover>
        </PopoverHoverBox>
    );
}

export default PopoverHover
