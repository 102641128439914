import React, {
    useContext,
    useCallback,
    useMemo
}                        from 'react'
import {
    FilterSortColumnContainer,
    SortListContainer,
    SortListItemButton,
    SortClearButton,
    FilterSortHeader,
    FilterSortTitle,
    ClosePopupIcon
} from "components/Table/TableHeader/FilterSortCell/style";
import {
    ListItemText,
    Divider
} from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import FilterForm        from "components/Table/TableHeader/FilterSortCell/FilterForm";
import TableDataContext  from "components/Table/context/context";
import { Clear }         from "@mui/icons-material";

const FilterSortCell = ({ col, onClose }: any) => {

    const { sort, setSortBy, clearSort } = useContext(TableDataContext)

    const changeSort = useCallback((direction: boolean = false) => {
        setSortBy(col?.id, direction)
    }, [setSortBy, sort, col])

    const isSorted = useMemo(() => {
        if (sort?.field === col?.id) return !sort?.direction ? 1 : 2
        return 0
    }, [sort, col])

    const handlerClearSort = useCallback(() => {
        clearSort()
    }, [clearSort])
    
    const CustomFilter = col?.customFilter

    return (
        <FilterSortColumnContainer>
            <FilterSortHeader>
                <FilterSortTitle>{col?.Header}</FilterSortTitle>
                {!!isSorted && (<SortClearButton endIcon={<Clear />}  onClick={() => handlerClearSort()}>
                   Clear
                </SortClearButton>)}
                <ClosePopupIcon onClick={onClose}>
                    <Clear />
                </ClosePopupIcon>
            </FilterSortHeader>
            <Divider />
            {col?.canSort && (<SortListContainer>
                <SortListItemButton selected={isSorted === 1} onClick={() => changeSort()}>
                    <ListItemText primary={<div>Sort A <sub><ArrowRightAltIcon /></sub>Z</div>} />
                </SortListItemButton>
                <SortListItemButton selected={isSorted === 2} onClick={() => changeSort(true)}>
                    <ListItemText primary={<div>Sort Z <sub><ArrowRightAltIcon /></sub> A</div>} />
                </SortListItemButton>
            </SortListContainer>)}
            <Divider />
            {CustomFilter && <CustomFilter col={col} />}
            {col?.canFilter && <FilterForm col={col} />}
        </FilterSortColumnContainer>
    )
}

export default FilterSortCell
