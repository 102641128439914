export enum STORE_TABLE_DOWNLOAD_IDS {
  KDS = 1,
  CH = 2,
  SU = 3,
  SAMMY = 4,
  FF=5,
  LL=6
}

export const tableDownloadRestrictionColumns = [
  {
    Header: 'Asin',
    accessor: 'asin'
  },
  {
    Header: 'Rest. KD\'S',
    accessor: 'isRestrictedKds',
    storeId: STORE_TABLE_DOWNLOAD_IDS.KDS
  },
  {
    Header: 'Rest. C&H',
    accessor: 'isRestrictedCh',
    storeId: STORE_TABLE_DOWNLOAD_IDS.CH
  },
  {
    Header: 'Rest. SU',
    accessor: 'isRestrictedSu',
    storeId: STORE_TABLE_DOWNLOAD_IDS.SU
  },
  {
    Header: 'Rest. SAMMY',
    accessor: 'isRestrictedSammy',
    storeId: STORE_TABLE_DOWNLOAD_IDS.SAMMY
  },
  {
    Header: 'Rest. FF',
    accessor: 'isRestrictedFF',
    storeId: STORE_TABLE_DOWNLOAD_IDS.FF
  },
  {
    Header: 'Rest. LL',
    accessor: 'isRestrictedLL',
    storeId: STORE_TABLE_DOWNLOAD_IDS.LL
  }
] as any


export const tableDownloadFbaErrorsColumns = [
  {
    Header: 'Asin',
    accessor: 'asin'
  },
  {
    Header: 'High Price US',
    accessor: 'fbaErrorUS',
    isError: true
  },
  {
    Header: 'High Price CA',
    accessor: 'fbaErrorCA',
    isError: true
  }
]