import React, {
    useState,
    useCallback,
    useEffect,
    useContext
}                       from 'react'
import SearchInput      from "components/Input/SearchInput";
import TableDataContext from "components/Table/context/context";

const InputSearch = ({ column }: any) => {

    const { filter, setFilter, clearFilter } = useContext(TableDataContext)
    const [search, setSearch] = useState('')

    const handlerSearch = useCallback((value: string) => setSearch(value), [setSearch])

    const clearSearch = useCallback(() => {
        filter[column?.id] && clearFilter(column?.id)
        setSearch('')
    }, [filter, column, clearFilter, setSearch])


    useEffect(() => {
        if (!search || search === filter[column?.id]) return
        setFilter({
            field: column?.id,
            data: search
        })
    }, [setFilter, search, filter])


    return (
        <SearchInput
            isClearable={column.isClearable}
            handlerSearch={handlerSearch}
            clearSearch={clearSearch}
            defaultValue={filter[column?.id]}
        />
    )
}

export default InputSearch
