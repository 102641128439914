import styled from "styled-components";
import {
  SpeedDial,
  Box
} from "@mui/material";
import { blue } from "@mui/material/colors";

export const TableActionsButtonContainer = styled(Box)`
  position: relative;
  min-width: 40.5px;
  min-height: 40.5px;
  width: 40.5px;
  height: 40.5px;
`

export const TableActionsSpeedDail = styled(SpeedDial)`
  position: absolute;
  left: 0;
  bottom: 0;
  &&& {
    height: 100%;
    width: 100%;
    .MuiSpeedDial-fab {
      min-height: 100%;
      min-width: 100%;
      width: 100%;
      height: 100%;
      background-color: ${blue['800']};
      color: #fff;
      border: 2px solid ${blue['500']};
      box-shadow: 0 2px 2px #888;
      margin-top: 2px;
        &:hover {
          transform: scale(1.08);
        }

        &:active {
          transform: scale(0.92);
        }
    }
    
    .MuiSpeedDial-actions {
      justify-content: center;
      align-items: center;
    }
    
  }
`