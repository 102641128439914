import React, { useMemo } from 'react'
import {
  PopoverBasePriceContainer,
  PopoverCaContainer,
  PopoverTitle,
  PopoverRow,
  PopoverAmzContainer,
  LabelValueComponent
} from "pages/Legacy/components/BasePrice/style";
import { AppDivider } from "style";
import {
  formatPrice,
} from "Utils";
import moment from "moment-timezone";


const PopoverBasePrice = ({row}: any) => {
  
  const [caAmazonPrice, updatedDateAmzPrice, amazonOurPrice, lastTimePriceUS] = useMemo(()=> [
    row?.original?.caAmzPrice,
    row?.original?.updateDateAmzPrice ?   moment(row?.original?.updateDateAmzPrice).tz("America/Phoenix").format('MM/DD/YYYY hh:mm a') : '',
    row?.original?.amazonOurPrice,
    row?.original?.lastTimePriceUS ?  moment(row?.original?.lastTimePriceUS).tz("America/Phoenix").format('MM/DD/YYYY hh:mm a') : ''
  ],[row])
  
  
  return (
    <PopoverBasePriceContainer>
      <PopoverCaContainer>
        <PopoverTitle>CA</PopoverTitle>
        <AppDivider />
        <PopoverRow>
          <LabelValueComponent label={'Amz Price'} value={caAmazonPrice} format={formatPrice} />
          <LabelValueComponent label={'Updated Date'} value={updatedDateAmzPrice} />
        </PopoverRow>
      </PopoverCaContainer>
      <PopoverAmzContainer>
        <PopoverTitle>AMAZON</PopoverTitle>
        <AppDivider />
        <PopoverRow>
          <LabelValueComponent label={'Stock Price'} value={amazonOurPrice} format={formatPrice} />
          <LabelValueComponent label={'Updated Date'} value={lastTimePriceUS} />
        </PopoverRow>
      </PopoverAmzContainer>
    </PopoverBasePriceContainer>
  )
}

export default PopoverBasePrice