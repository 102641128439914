import React, {memo, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Table, TableBody, TableRow,} from "@mui/material";
import {TableContainer, TableDataContainer, TBodyRow} from "components/Table/style";
import TableDataContext from "components/Table/context/context";
import {TRequestOptions, TTableState} from "components/Table/d";
import {useExpanded, useResizeColumns, useTable} from "react-table";
import API from "api";
import TDCell from "components/Table/TDCell";
import TableFooter from "components/Table/TableFooter";
import THead from "components/Table/THead";
import {useLoading} from "hooks/useLoading";
import {deepEqual} from 'fast-equals';
import {NOOP} from "Utils";
import TableEmpty from "components/Table/TableEmpty";
import {TableEmptyCell} from "components/Table/TableEmpty/style";
import {getAllColumns} from "components/Table/helpers";

const TableRows = memo(({rows, prepareRow, tableBordered, isAllRowsExpanded}: any) => {

    const {getColumnWidth} = useContext(TableDataContext)
    let selected = 0;
    return rows.map((row: any, rInd: number) => {
        prepareRow(row)
        if (row.depth === 0) selected = (selected + 1) % 2
        return (
            <TBodyRow {...row.getRowProps()} depth={row.depth} selected={!!selected}>
                {row.cells.map((cell: any, index: number) => {
                    const expandProps = index === 0 ? {
                        expand: row.canExpand,
                        expandProps: row.canExpand ? row.getToggleRowExpandedProps() : {},
                        isAllRowsExpanded
                    } : {}
                    const width = (() => {
                        if (!getColumnWidth) return
                        return getColumnWidth(cell.column.id)
                    })()
                    return <TDCell key={`cell-${index}`} {...expandProps} width={width} tableBordered={tableBordered}
                                   cell={cell}/>
                })}
            </TBodyRow>
        )
    })
})

const Component = ({
                       visibleColumns,
                       tableData,
                       totalRecords,
                       tableBordered,
                       isVisibilityHeader = true,
                       className,
                       isResizable
                   }: any) => {

    const {setColumnResizing} = useContext(TableDataContext)

    const {
        getTableBodyProps,
        getTableProps,
        headerGroups,
        prepareRow,
        toggleAllRowsExpanded,
        isAllRowsExpanded,
        rows,
        state,
    } = useTable(
        {
            columns: visibleColumns,
            data: tableData,
            pageCount: totalRecords,
            defaultColumn: {
                minWidth: 30,
                width: 65,
                maxWidth: 400
            },
            manualFilters: true,
            manualGlobalFilter: true,
            manualPagination: true
        } as any,
        useExpanded,
        useResizeColumns
    ) as any

    useEffect(() => {
        if (!tableData) return
        if (!isAllRowsExpanded) {
            toggleAllRowsExpanded(true)
        }
    }, [tableData, isAllRowsExpanded])

    const emptyColSpan = useMemo(() => (getAllColumns(visibleColumns).flat()).length + 1, [visibleColumns])

    useEffect(() => {
        if (!setColumnResizing || !state.columnResizing?.columnWidths) return
        setColumnResizing(state.columnResizing?.columnWidths)
    }, [state, setColumnResizing])


    return (
        <Table stickyHeader {...getTableProps()} className={className}>
            <THead headerGroups={headerGroups} isResizable={isResizable} isVisibilityHeader={isVisibilityHeader}/>
            <TableBody {...getTableBodyProps()}>
                {
                    !tableData?.length ? (<TableRow>
                            <TableEmptyCell colSpan={emptyColSpan}>
                                <TableEmpty message={"No data available"}/>
                            </TableEmptyCell>
                        </TableRow>)
                        : (<TableRows rows={rows} isAllRowsExpanded={isAllRowsExpanded} prepareRow={prepareRow}
                                      tableBordered={tableBordered}/>)
                }
            </TableBody>
        </Table>
    )
}

export const StaticTable = (props: any) => <Component {...props} />

const TableComponent = ({
                            csvFileName,
                            title,
                            modelName,
                            needRefetch,
                            refetchDone = NOOP,
                            tableBordered,
                            usePagination,
                            className,
                            hideLoading,
                            preventFetch,
                            emitFetchSuccess,
                            isResetPagination,
                            rowsPerPageOptions,
                            children,
                            useHeader,
                            FooterChildren,
                            isResizable,
                            actionDownloadDefinition,
                            postFetchFn = null
                        }: any) => {
    const [tableState, setState] = useState<TTableState>({
        rows: [],
        count: 0
    })
    const {setLoading, resetLoading} = useLoading()
    const {isNeedRefetch, requestOptions, setPage, visibleColumns} = useContext(TableDataContext)

    const refForFetch = useRef({
        hideLoading,
        preventFetch
    })
    refForFetch.current = {
        hideLoading,
        preventFetch
    }

    const refControllerCallAbort = useRef(null)
    const refPrevTime = useRef(new Date().getTime())

    const fetchData = useCallback(async ({forceShowLoading, ...data}: TRequestOptions & {
        forceShowLoading?: boolean
    }) => {
        const time = new Date().getTime();
        refPrevTime.current = time
        if (!modelName || refForFetch.current.preventFetch) return
        try {
            if (!(refForFetch.current.hideLoading && !forceShowLoading)) setLoading({
                isMini: true
            });
            (refControllerCallAbort.current as any)?.abort()
            let {result} = await API.getModelAllCount(modelName, data, true, refControllerCallAbort)
            if(postFetchFn) {
               result = await postFetchFn(result, refControllerCallAbort)
            }
            refControllerCallAbort.current = null
            setState({
                ...result
            })
            emitFetchSuccess && emitFetchSuccess(result)
            refetchDone()
        } catch (e) {
            console.log(e)
            return;
        } finally {
            resetLoading()
        }
    }, [setLoading, resetLoading, refetchDone, setState, modelName, refForFetch, emitFetchSuccess])

    const [tableData, totalRecords] = useMemo(() => [
        [...tableState.rows].map((x: any, index: number) => ({
            ...x,
            index: index + 1
        })),
        tableState.count
    ], [
        tableState
    ])

    const refReqOptions = useRef({})
    const refLastIsNeedRefetch = useRef(0)

    const _requestOptions = useMemo(() => {
        if (!deepEqual(requestOptions, refReqOptions.current)) refReqOptions.current = requestOptions
        return refReqOptions.current
    }, [requestOptions, refReqOptions])

    useEffect(() => {
        if (!isResetPagination) return
        setPage(0)
    }, [isResetPagination, setPage])

    useEffect(() => {
        if (!isNeedRefetch && !needRefetch) return
        fetchData({
            ..._requestOptions,
            forceShowLoading: (() => {
                if (refLastIsNeedRefetch.current === isNeedRefetch) return false
                refLastIsNeedRefetch.current = isNeedRefetch as number
                return true
            })()
        } as any).then()
    }, [fetchData, isNeedRefetch, _requestOptions, needRefetch])



    return (
        <TableDataContainer className={className}>
            {useHeader ? children : null}
            <TableContainer>
                <Component
                    tableBordered={tableBordered}
                    visibleColumns={visibleColumns}
                    tableData={tableData}
                    totalRecords={totalRecords}
                    isResizable={isResizable}
                />
            </TableContainer>
            {usePagination &&
                <TableFooter title={title} csvFileName={csvFileName} modelName={modelName} totalRecords={totalRecords}
                             rowsPerPageOptions={rowsPerPageOptions} FooterChildren={FooterChildren}
                             actionDownloadDefinition={actionDownloadDefinition}/>}
        </TableDataContainer>
    )
}

export default TableComponent
