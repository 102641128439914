import AmazonLink from "pages/Legacy/Marketing/components/AmazonLink";
import React from "react";
import OptiScore from "pages/Legacy/Marketing/components/OptiScore";
import WalmartVisibility from "pages/Legacy/Marketing/components/WalmartVisibility";
import TdCellEllipsis from "components/Table/TableCells/TdCellEllipsis";
import EbayStatus from "pages/Legacy/Marketing/components/EbayStatus";
import EbayLink from "pages/Legacy/Marketing/components/EbayLink";
import {
  formatPrice,
  formatQuantity
} from "Utils";
import { SHEET_TAB_IDS } from "def";
import WalmartOptiScore from "pages/Legacy/Marketing/components/WalmartOptiScore";
import { FieldColumnType } from "components/Table/TableHeader/FilterSortCell/d";
import NewEggLink from "pages/Legacy/Marketing/components/NewEggLink";
import WalmartLink from "pages/Legacy/Marketing/components/WalmartLink";

export const mainSheetColumnsGlobalSearch = [
  'asin',
  'mpn'
]

export const requestOptions = {
  filter: {
    $and: [
      {
        active: 1,
        sheetId: SHEET_TAB_IDS.INITIAL_DROP
      }
    ]
  },
  sort: {
    field: 'rowIndex',
    direction: 'ASC'
  },
}

export const tableColumns = [
  {
    Header: 'Section',
    accessor: 'statusGroupId',
    canSort: true,
    canFilter: true,
    fieldType: 'value',
    isVisible: false,
    style: {
      minWidth: 150,
      maxWidth: 150
    },
    Cell: ({ row }: any) => row.original?.groupStatusLabel || ""
  },
  {
    Header: 'ASIN',
    accessor: 'asin',
    canFilter: true,
    style: {
      minWidth: 120,
      maxWidth: 120,
    },
    canSort: true,
    Cell: (props: any) => <AmazonLink value={props.value} />
  },
  {
    Header: 'BSR',
    accessor: 'amazonBsr',
    canSort: true,
    canFilter: true,
    align: 'center',
    fieldType: 'number',
    style: {
      minWidth: 70,
      maxWidth: 70,
    },
    Cell: (props: any) => props?.value || 'N/A'
  },
  {
    Header: 'Visibility',
    accessor: 'amazonVisibility',
    canFilter: true,
    canSort: true,
    fieldType: 'value',
    style: {
      minWidth: 85,
      maxWidth: 85,
    }
  },
  {
    Header: 'OptiS',
    accessor: 'amazonOptiScore',
    canSort: true,
    canFilter: true,
    fieldType: 'number',
    style: {
      minWidth: 75,
      maxWidth: 75
    },
    Cell: (props: any) => <OptiScore row={props.row} />
  },
  {
    Header: 'W Visib.',
    accessor: 'walmartVisibility',
    canSort: true,
    canFilter: true,
    fieldType: 'value',
    style: {
      minWidth: 70,
      maxWidth: 70,
    },
    Cell: (props: any) => <WalmartVisibility value={props.value} />
  },
  {
    Header: 'W Opti',
    accessor: 'walmartOptiScore',
    canSort: true,
    canFilter: true,
    fieldType: FieldColumnType.multiselection,
    style: {
      minWidth: 75,
      maxWidth: 75
    },
    Cell: (props: any) => <WalmartOptiScore {...props} />
  },
  
  {
    Header: 'WPID',
    accessor: 'wpid',
    canFilter: true,
    style: {
      minWidth: 110,
      maxWidth: 110,
      textAlign: 'center'
    },
    canSort: true,
    Cell: (props: any) => <WalmartLink value={props.value} />
  },
  
  {
    Header: 'NE Opti',
    accessor: 'newEggOptiScore',
    canSort: true,
    canFilter: true,
    fieldType: FieldColumnType.multiselection,
    style: {
      minWidth: 75,
      maxWidth: 75
    },
    Cell: (props: any) => <WalmartOptiScore value={props.value} />
  },
  {
    Header: 'NewEgg #',
    accessor: 'newEggItemNumber',
    canFilter: true,
    style: {
      minWidth: 110,
      maxWidth: 110,
      textAlign: 'center'
    },
    canSort: true,
    Cell: (props: any) => <NewEggLink value={props.value} />
  },
  {
    Header: 'MPN',
    accessor: 'mpn',
    canSort: true,
    canFilter: true,
    style: {
      minWidth: 140,
      maxWidth: 140
    },
    Cell: (props: any) => <TdCellEllipsis {...props} />
  },
  {
    Header: 'ERP',
    accessor: 'erp',
    align: 'center',
    style: {
      padding: 3,
      minWidth: 40,
      maxWidth: 40
    },
    Cell: (props: any) => <EbayStatus row={props.row.original} />
  },
  {
    Header: 'Alex Note',
    accessor: 'alexNote',
    align: 'left',
    style: {
      minWidth: 80,
      maxWidth: 80
    },
    Cell: (props: any) => ''
  },
  {
    Header: 'Ebay link',
    accessor: 'ebayEpid',
    canSort: true,
    canFilter: true,
    style: {
      minWidth: 110,
      maxWidth: 110
    },
    Cell: (props: any) => <EbayLink value={props.value} />
  },
  {
    Header: 'Seller',
    accessor: 'ebaySellerName',
    align: 'center',
    Cell: (props: any) => props.value || ''
  },
  {
    Header: 'Make',
    accessor: 'make',
    canSort: true,
    canFilter: true,
    style: {
      minWidth: 110,
      maxWidth: 110
    },
    Cell: (props: any) => <TdCellEllipsis {...props} />
  },
  {
    Header: 'Model',
    accessor: 'modelNumber',
    canSort: true,
    canFilter: true,
    style: {
      minWidth: 110,
      maxWidth: 110
    },
    Cell: (props: any) => <TdCellEllipsis {...props} />
  },
  {
    Header: 'Bid price',
    accessor: 'ebayBidPrice',
    canSort: true,
    canFilter: true,
    fieldType: 'number',
    align: 'right',
    style: {
      minWidth: 85,
      maxWidth: 85
    },
    Cell: (props: any) => formatPrice(props?.value) || 'N/A'
  },
  {
    Header: 'Price',
    accessor: 'ebayPrice',
    canSort: true,
    canFilter: true,
    fieldType: 'number',
    align: 'right',
    style: {
      minWidth: 85,
      maxWidth: 85
    },
    Cell: (props: any) => formatPrice(props?.value) || 'N/A'
  },
  {
    Header: 'X',
    accessor: 'ebayOffer',
    align: 'center',
    style: {
      minWidth: 50,
      maxWidth: 50
    },
    Cell: (props: any) => props.value ? 'X' : ''
  },
  {
    Header: 'QTY',
    accessor: 'ebayQuantity',
    canSort: true,
    canFilter: true,
    fieldType: 'number',
    align: 'center',
    style: {
      minWidth: 65,
      maxWidth: 65
    },
    Cell: (props: any) => formatQuantity(props?.value) || ''
  },
  {
    Header: 'BB',
    accessor: 'amazonFirstPrice',
    canSort: true,
    canFilter: true,
    fieldType: 'number',
    align: 'right',
    style: {
      minWidth: 100,
      maxWidth: 100
    },
  },
  {
    Header: 'BB/LP Seller',
    accessor: 'amazonFirstSeller',
    style: {
      minWidth: 100,
      maxWidth: 100
    },
    Cell: (props: any) => <TdCellEllipsis value={props.value} />
  },
  {
    Header: 'BSR',
    accessor: 'amazonBsr2',
    canSort: true,
    canFilter: true,
    align: 'center',
    fieldType: 'number',
    style: {
      minWidth: 70,
      maxWidth: 70,
    },
    Cell: (props: any) => props?.row?.original?.amazonBsr || 'N/A'
  },
  {
    Header: "Base Price",
    accessor: "basePrice",
    align: 'right',
    canSort: true,
    caseFilter: true,
    fieldType: 'number',
    style: {
      minWidth: 100,
      maxWidth: 100
    },
    Cell: (props: any) => formatPrice(props?.value) || "N/A"
  },
  {
    Header: '% profit',
    accessor: 'percentProfit',
    style: {
      minWidth: 80,
      maxWidth: 80
    },
    Cell: (props: any) => 'N/A'
  },
  {
    Header: 'Margin',
    accessor: 'margin',
    Cell: (props: any) => 'N/A'
  },
  {
    Header: 'Tool',
    accessor: 'tool',
    Cell: (props: any) => 'N/A'
  },
  {
    Header: 'Rep. link',
    accessor: 'replaceLink',
    style: {
      minWidth: 70,
      maxWidth: 70
    },
    Cell: (props: any) => 'N/A'
  },
  {
    Header: 'Rep. MPN',
    accessor: 'replaceMpn',
    style: {
      minWidth: 70,
      maxWidth: 70
    },
    Cell: (props: any) => 'N/A'
  },
  {
    Header: 'Ingram',
    accessor: 'ingram',
    Cell: (props: any) => formatPrice(props.value) || "N/A"
  },
  {
    Header: 'Provan',
    accessor: 'provan',
    Cell: (props: any) => formatPrice(props.value) || "N/A"
  },
  {
    Header: 'Barcodes Inc',
    accessor: 'barcodesInc',
    style: {
      minWidth: 100,
      maxWidth: 100
    },
    Cell: (props: any) => formatPrice(props.value) || "N/A"
  },
]
