import React from 'react'


export const OurWBIcon = ()=> (
    <svg aria-hidden="true"
         focusable="false"
         data-prefix="far" data-icon="trophy-alt" role="img" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 576 512" className="svg-inline&#45;&#45;fa fa-trophy-alt fa-w-18">
        <path fill="currentColor"
              d="M359.3 138.9l-43.4-6.3-19.4-39.3c-3.5-7-13.5-7.1-17 0l-19.4 39.3-43.4 6.3c-7.8 1.1-10.9 10.7-5.3 16.2l31.4 30.6-7.4 43.2c-1.3 7.7 6.8 13.7 13.8 10l38.8-20.4 38.8 20.4c6.9 3.6 15.1-2.2 13.8-10l-7.4-43.2 31.4-30.6c5.6-5.5 2.5-15.1-5.3-16.2zM448 64V16c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16v48H16C7.2 64 0 71.2 0 80v60.8C0 201.1 68.3 266 159.6 283.4c27.4 57.9 68.1 88.2 104.4 97.4V464h-64c-22.1 0-40 17.9-40 40 0 4.4 3.6 8 8 8h240c4.4 0 8-3.6 8-8 0-22.1-17.9-40-40-40h-64v-83.2c36.3-9.3 77-39.5 104.4-97.4C507.5 266.1 576 201.2 576 140.8V80c0-8.8-7.2-16-16-16H448zM48 140.8V112h80c0 39.2 2.1 76.2 12.3 116.8-55.1-18.9-92.3-58.9-92.3-88zM288 336c-53 0-112-78.4-112-216V48h224v72c0 140.5-60.8 216-112 216zm240-195.2c0 29.1-37.2 69.1-92.3 88C445.9 188.2 448 151.1 448 112h80v28.8z"
              className=""></path>
    </svg>
)



export const FirstPositionIcon = ()=> (// @ts-ignore
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
         x="0px" y="0px" viewBox="0 0 1000 1000"  >
        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
        <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M7633.9,4693.4c-6.2-16.5-47.4-138.1-90.7-267.9l-78.3-237l-272-92.7l-272-92.7l274.1-92.7l272-92.7l82.4-251.4c45.3-138.1,86.6-255.5,92.7-261.7c6.2-6.2,51.5,109.2,98.9,255.5l88.6,265.8l272,90.7l272,90.7l-272,90.7l-274.1,88.6l-90.7,270C7685.4,4611,7642.2,4712,7633.9,4693.4z"/><path
            d="M4728.3,3731.1c-4.1-20.6-16.5-70.1-22.7-109.2c-28.8-138.1-129.8-327.6-230.8-428.6c-191.6-191.7-434.8-272-917-305l-222.5-14.4v-335.9V2200l603.8-4.1l601.7-6.2l6.2-1766l4.1-1768.1H5056h504.9l2.1,113.3c0,61.8,0,1156.1,0,2431.6c0,1275.6,0,2373.9,0,2441.9l-2.1,123.6h-410.1C4775.7,3766.1,4738.6,3762,4728.3,3731.1z" /><path
            d="M1064.4,3090.2c-311.2-333.8-393.6-657.4-241.1-958.2c57.7-113.3,169-247.3,232.9-280.3c45.3-24.7,59.8-20.6,140.1,35c364.7,249.3,418.3,638.8,158.7,1154c-57.7,115.4-113.3,210.2-121.6,210.2S1148.9,3178.8,1064.4,3090.2z" /><path
            d="M8736.4,3193.2c-144.2-204-263.8-601.7-239-797.5c28.9-212.2,158.7-416.3,331.8-517.2c109.2-63.9,138.1-51.5,257.6,101c131.9,164.9,169,265.8,171.1,459.5c2.1,150.4-4.1,177.2-76.3,323.5c-53.6,109.2-127.8,210.2-239.1,327.7l-158.7,166.9L8736.4,3193.2z" /><path
            d="M6498.5,2927.4l-57.7-189.6l-166.9-55.6c-92.7-30.9-175.2-59.8-185.5-65.9c-10.3-6.2,61.8-39.2,162.8-74.2l183.4-63.9l57.7-173.1c30.9-94.8,59.7-177.2,65.9-181.3c4.1-4.1,35,72.1,68,173.1l59.7,181.3l183.4,63.9c101,35,173.1,68,162.8,74.2c-10.3,6.2-92.7,35-183.4,65.9l-166.9,53.6l-63.9,191.6l-61.8,189.6L6498.5,2927.4z" /><path
            d="M1971.1,2092.8c-269.9-133.9-438.9-294.7-509-480.1c-61.8-160.7-53.6-375,20.6-556.4l51.5-123.6l94.8,4.1c115.4,2.1,288.5,78.3,391.5,169c92.7,80.4,189.6,270,224.6,430.7c37.1,177.2,4.1,663.6-43.3,663.6C2191.6,2200,2088.6,2152.6,1971.1,2092.8z" /><path
            d="M7778.2,2144.3c-6.2-30.9-18.5-148.4-24.7-257.6c-30.9-434.8,105.1-741.9,389.5-879.9c70.1-35,171-70.1,224.6-76.3l98.9-14.4l55.6,121.6c51.5,107.1,57.7,146.3,57.7,327.6c0,189.6-4.1,214.3-61.8,317.4c-82.4,150.4-269.9,313.2-471.9,412.1C7807,2210.3,7794.7,2212.3,7778.2,2144.3z" /><path
            d="M390.6,1414.9C271,1392.2,100,1334.5,100,1318c0-45.3,193.7-325.6,300.9-432.8c263.8-263.8,564.6-317.4,873.7-156.6c166.9,86.5,175.2,96.8,146.3,197.8c-68,228.7-234.9,403.9-451.3,469.8C827.4,1439.6,561.6,1449.9,390.6,1414.9z" /><path
            d="M9070.2,1408.7c-125.7-41.2-261.7-125.7-325.6-202c-63.9-74.2-175.2-296.7-175.2-346.2c0-45.3,189.6-154.6,338-197.8c253.5-72.1,513.1,16.5,725.3,245.2c109.2,117.5,232.9,296.7,265.8,389.5C9933.7,1390.1,9282.5,1478.7,9070.2,1408.7z" /><path
            d="M2335.9,1029.5c-199.9-107.2-406-305-488.4-471.9c-55.6-111.3-63.9-154.6-63.9-280.3c2.1-218.4,121.6-509,210.2-509c86.6,0,272,88.6,368.9,175.2c154.6,140.1,216.4,294.7,226.7,568.8c8.3,204-4.1,315.3-59.8,519.3c-10.3,41.2-26.8,76.3-35,76.3C2486.3,1107.8,2416.2,1072.8,2335.9,1029.5z" /><path
            d="M7465,980c-28.9-98.9-39.2-197.8-39.2-408c0-274.1,2.1-282.3,68-416.3c96.9-197.8,292.6-350.3,490.4-381.2c59.8-10.3,72.1-2.1,117.5,68c86.6,140.1,125.7,276.1,125.7,430.7c0,280.3-166.9,521.4-506.9,729.5c-92.7,57.7-179.3,105.1-193.7,105.1C7514.4,1107.8,7485.6,1050.1,7465,980z" /><path
            d="M1025.3,69.2C839.8,15.6,485.4-178.1,440-252.3c-22.7-37.1,222.6-232.9,416.3-327.6c276.1-138.1,521.4-129.8,748,22.7c111.3,72.1,267.9,241.1,267.9,286.4c0,43.3-220.5,249.3-323.5,298.8C1412.7,96,1177.7,112.5,1025.3,69.2z" /><path
            d="M8555.1,69.2c-115.4-37.1-208.1-96.9-296.7-191.7c-134-144.3-131.9-138.1-59.8-239c148.4-208.1,418.3-338,647.1-313.2c65.9,6.2,166.9,30.9,224.6,53.6c113.3,45.3,335.9,187.5,447.2,286.4l72.1,63.9l-82.4,70C9402-110.1,9127.9,28,8981.6,67.1C8843.6,104.2,8670.5,104.2,8555.1,69.2z" /><path
            d="M2962.3,17.7c-603.8-251.4-809.8-655.3-593.5-1158.1l41.2-94.8l111.3,18.6c317.3,49.5,531.7,251.4,618.2,587.3c51.5,193.7,37.1,708.9-18.5,704.8C3108.6,75.4,3036.5,48.6,2962.3,17.7z" /><path
            d="M6867.4,54.8c-26.8-43.3-35-498.7-10.3-626.5c68-364.8,319.4-610,675.9-653.3c61.8-8.2,68-4.1,121.6,115.4c80.4,179.3,92.7,408,26.8,579.1c-70.1,187.5-259.7,368.9-513.1,490.4C6931.2,73.3,6888,85.7,6867.4,54.8z" /><path
            d="M6137.9-957c0-249.3,82.4-542,187.5-671.8c107.2-129.8,239.1-195.8,445.1-222.6l107.2-12.4l30.9,109.2c129.8,436.8-131.9,770.7-702.7,894.3l-68,14.4V-957z" /><path
            d="M3695.9-950.8c-395.7-113.3-587.3-319.4-585.2-630.6c0-78.3,12.4-177.2,24.7-216.4l24.7-72.1l121.6,4.1c346.2,12.4,554.3,282.3,583.2,764.5C3879.3-884.9,3889.6-895.2,3695.9-950.8z" /><path
            d="M1614.6-998.2c-214.3-28.8-525.5-201.9-717.1-401.8l-78.3-82.4l111.3-72.1c263.8-175.2,476-251.4,694.5-249.3c181.3,2.1,294.7,39.2,434.8,140.1c103,78.3,265.8,296.7,265.8,360.6c0,90.7-329.7,294.7-504.9,313.2C1775.4-985.9,1682.6-987.9,1614.6-998.2z" /><path
            d="M8155.3-994.1c-148.4-24.7-366.8-156.6-447.2-269.9c-41.2-59.8-2.1-140.1,144.2-296.7c164.9-177.2,315.3-245.2,542-243.2c121.6,2.1,201.9,14.4,300.8,51.5c140.1,51.5,362.7,171,451.3,243.2l51.5,41.2l-103,92.7C8773.5-1084.8,8437.6-948.8,8155.3-994.1z" /><path
            d="M2325.6-1975c-136-49.5-296.7-162.8-408-286.5c-107.2-121.6-294.7-420.4-300.9-476c-6.2-96.9,624.4-158.7,853.1-84.5c278.2,92.7,469.8,335.9,525.5,665.6l12.4,84.5l-129.8,57.7C2715-1940,2469.8-1923.5,2325.6-1975z" /><path
            d="M7302.1-1960.6c-86.6-22.7-278.2-107.2-278.2-123.6c0-6.2,14.4-70.1,30.9-142.2c125.7-531.7,550.2-739.8,1207.6-589.3c78.3,18.5,146.3,41.2,152.5,49.4c33,53.6-216.4,434.8-389.5,593.5c-169,152.5-311.2,216.4-504.9,222.6C7434-1946.2,7337.2-1950.3,7302.1-1960.6z" /><path
            d="M3761.9-2127.5c-195.8-33-346.2-160.7-445.1-370.9c-76.3-164.9-86.6-455.4-22.7-626.4c98.9-257.6,317.3-395.7,618.2-389.5c249.4,4.1,457.5,92.7,702.7,298.8c144.2,119.5,146.3,107.2,24.7-138.1c-179.3-358.6-492.5-675.9-785.1-793.4c-80.4-30.9-148.4-111.3-148.4-175.2c0-24.7,20.6-72.1,45.3-103c39.2-49.5,53.6-55.6,142.2-47.4c142.2,14.4,383.3,158.7,591.4,354.4c183.4,175.2,298.8,325.6,426.6,554.3l78.3,142.2l74.2-142.2c210.2-399.8,556.4-737.7,902.6-882c158.7-65.9,294.7-8.3,294.7,125.7c-2.1,86.6-30.9,113.3-222.6,214.3c-305,156.6-568.8,449.2-737.7,811.9c-39.2,84.5-70.1,160.7-70.1,169c0,6.2,49.5-28.9,109.2-78.3c255.5-220.5,480.1-313.2,746-313.2c187.5,2.1,302.9,41.2,430.7,152.5c344.1,302.9,239,1013.9-179.3,1195.2c-150.4,65.9-373,63.9-618.2-10.3c-175.2-49.4-533.7-210.2-663.5-294.7l-61.8-39.2l-226.7,119.5c-276.1,144.2-529.6,234.9-741.9,263.8c-86.6,12.4-158.7,20.6-160.7,20.6C3862.8-2111,3817.5-2119.2,3761.9-2127.5z M4172-2500.5c177.2-53.6,461.6-183.4,461.6-208.1c0-45.3-325.6-342.1-432.7-393.6c-206.1-101-420.4-109.2-519.3-22.7c-146.3,131.9-127.8,494.6,35,622.3C3792.8-2442.8,3980.3-2440.7,4172-2500.5z M6257.4-2508.7c80.4-68,131.9-199.9,131.9-342.1c0-323.5-263.8-428.6-622.3-249.4c-105.1,51.5-432.8,350.3-432.8,393.6c0,22.7,257.6,138.1,443,199.9c90.7,28.8,195.8,47.4,282.3,49.4C6177-2457.2,6205.9-2465.4,6257.4-2508.7z" /></g></g>
        </svg>
)

export const WBExists = ()=> (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
            d="M5 0c0 9.803 5.105 12.053 5.604 16h2.805c.497-3.947 5.591-6.197 5.591-16h-14zm7.006 14.62c-.408-.998-.969-1.959-1.548-2.953-1.422-2.438-3.011-5.162-3.379-9.667h9.842c-.368 4.506-1.953 7.23-3.372 9.669-.577.993-1.136 1.954-1.543 2.951zm-.006-3.073c-1.125-2.563-1.849-5.599-1.857-8.547h-1.383c.374 3.118 1.857 7.023 3.24 8.547zm12-9.547c-.372 4.105-2.808 8.091-6.873 9.438.297-.552.596-1.145.882-1.783 2.915-1.521 4.037-4.25 4.464-6.251h-2.688c.059-.45.103-.922.139-1.405h4.076zm-24 0c.372 4.105 2.808 8.091 6.873 9.438-.297-.552-.596-1.145-.882-1.783-2.915-1.521-4.037-4.25-4.464-6.251h2.688c-.058-.449-.102-.922-.138-1.404h-4.077zm13.438 15h-2.866c-.202 1.187-1.63 2.619-3.571 2.619v4.381h10v-4.381c-1.999 0-3.371-1.432-3.563-2.619zm2.562 6h-8v-2h8v2z" />
    </svg>
)



export const NotExistsWB = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
        <path
            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
    </svg>
)
