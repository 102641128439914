import React, {
  useMemo,
  useEffect
} from 'react'
import {
  OptiScoreContainer,
  FontAwesomeIconLink
} from "pages/Legacy/Marketing/components/WalmartOptiScore/style";
import { WalmartLinkContainer } from "pages/Legacy/Marketing/components/WalmartLink/style";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";


const WalmartOptiScore = (props: any) => {

   const value = props.value
   const optiScore = useMemo(()=> value,[value])
   const _value = props.row?.original?.wpid
  
  const link= useMemo(()=> !/^[A-Za-z]+$/.test(_value) && _value !== 'N/A' ? `https:///www.walmart.com/ip/${_value}`: '', [_value])

    const color = useMemo(()=> {
       const val = Number(optiScore)
        //if (val === 4) return '#93c47d'
        if (val === 2) return '#6aa84f'
        if (val === 3) return '#38761d'
        return 'transparent'
    },[optiScore])


    return (
        <OptiScoreContainer color={color}>
            {optiScore}
          { link &&
            <WalmartLinkContainer  href={link} target={'_blank'}>
            <FontAwesomeIconLink icon={faArrowUpRightFromSquare} />
          </WalmartLinkContainer>
          }
        </OptiScoreContainer>
    )
}

export default WalmartOptiScore
