import styled  from "styled-components";
import {
  Box,
  Divider
} from "@mui/material";

export const Flex= styled.div`
  display: flex;
`

export const Page = styled(Box)`
  width: 100%;
  padding: 5px;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`

export const AppDivider = styled(Divider)`
  &&& {
    width: 100%;
  }
`
