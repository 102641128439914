import styled from "styled-components";
import Colors from "def/Colors";

export const EbayLinkContainer = styled.a`
    color: ${Colors.PALETTE.BLUE._900};
    text-decoration: none;
    
  &:hover {
    text-decoration: underline;
  }
`
