import {
  TRule,
  TForbiddenRoutes
} from "casl/d";
import {
  TAction,
  TCASLPermission
} from "./d";
import { CASL_SET_RULES } from "store/casl/types";

const init = {
   sha: '',
   rules: [] as TRule[]
}

const state = (state =  init, action: TAction): TCASLPermission => {

  switch (action.type) {

    case CASL_SET_RULES : {
       if(state.sha === action.payload?.sha) return state
       return  {
         ...action.payload as TCASLPermission
       }
    }

    default:
      return state
  }

}

export default state
