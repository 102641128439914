import {API, rootApi} from "api/index";

export const getAmazonSummary = async () => {
    return (await API.get(`${rootApi}/amazons/getSummary`))?.data?.result || {}
}

export const getAmazonProductByAsin = async (asin: string) => {
    return (await API.post(`${rootApi}/amazon_products/getByAsin`, {asin}))?.data?.result || {}
}

export const getAmazonByAsin = async (asin: string) => {
    return (await API.post(`${rootApi}/amazons/getByAsin`, {asin}))?.data?.result || {}
}


export const syncAmazonApiByAsin = async (asin: string) => {
    return (await API.post(`${rootApi}/amazons/syncAmazonApiByAsin`, {asin}))?.data?.result || {}
}


export const getAmazonMarketPlaces = async () => {
    return (await API.get(`${rootApi}/amazon_market_place/getMarketPlaces`))?.data?.result || []
}


export const getAmazonCategories = async () => {
    return (await API.get(`${rootApi}/amazon_category/getCategories`))?.data?.result || []
}

export const getAmazonSearchCategories = async () => {
    return (await API.get(`${rootApi}/amazon_search_category/getSearchCategories`))?.data?.result || []
}


export const getAmazonBrands = async () => {
    //return (await API.get(`${rootApi}/amazon_items_brands/getBrands`))?.data?.result || []
    return []
}

export const getAmazonRestrictedInfo = async (asin: string, sellerId = 5) => {
    return (await API.post(`${rootApi}/amazon_items_restrictions/getRestrictedByAsin`, {
        asin,
        sellerId
    }))?.data?.result || []
}

export const getAmazonItemBsrHistory = async (amazonItemId: number) => {
    return (await API.post(`${rootApi}/amazon_items_bsr_history/get-item-history`, {amazonItemId}))?.data?.result || []
}


export const getAmazonRestrictedAllAsins = async (asins: string[], signal?: AbortController) => {
    return (await API.post(`${rootApi}/amazon_items_restrictions/get-all-restricted-by-asin`, {asins}, signal))?.data?.result || []
}


export const getCategoryData = async (ids: string[], signal?: AbortController) => {
    ids = Array.from(new Set(ids.filter(x => !!x)))
    return (await API.post(`${rootApi}/view_sheets_sum_quantity_by_sheet_new/get-category-data`, {ids}, signal))?.data?.result || []
}
