import React       from 'react'
import {
    TdCellEllipsisContainer,
    TdCellEllipsisBox
}                  from "components/Table/TableCells/TdCellEllipsis/style";
import { Tooltip } from "@mui/material";

const TdCellEllipsis = ({ value, topTipPosition = 'bottom', className }: any) => {
    return (
        <TdCellEllipsisBox>
            <Tooltip title={value || ""} placement={topTipPosition}>
                <TdCellEllipsisContainer className={className}>
                    {value}
                </TdCellEllipsisContainer>
            </Tooltip>
        </TdCellEllipsisBox>
    )
}

export default TdCellEllipsis


