import styled, { css } from "styled-components";
import TableComponent from "components/Table";
import { Flex } from "style";
import { TDCellContainer } from "components/Table/style";

export const Table = styled(TableComponent)`
  &&& {
    max-width: 1800px;
    margin: 0px auto;
    
    &&&& ${TDCellContainer} {
      position: relative;
    }
    
  }
`

export const Order365InfoContainer = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`

export const Order365InfoIcon = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`

export const SmallHeader = styled.span`
  font-size: 10px;
`

export const PriceHeader = styled.span`
  font-size: 10px;
`
