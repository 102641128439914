import React, {Suspense, useEffect} from 'react';
import {
  ThemeProvider,
  createTheme
} from "@mui/material";

import store, { reduxInit } from "store";
import {
  Provider,
  useDispatch
} from 'react-redux'
import {
  Route,
  Routes,
  HashRouter
} from "react-router-dom";
import SpinnerCenter from "components/Spinner";
import { ComponentLazy } from "helpers/ComponentLazy";
import { TRoute } from "components/Drawer/Menu/d";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import {
  routerList
} from "def/routes";

const  ModalContainer = React.lazy(() => import("components/Dialog/ModalContainer"))
const AuthLayout = React.lazy(() => import("layout/auth"))
const AdminLayout = React.lazy(() => import("layout/admin"))
const MainLayout = React.lazy(() => import("layout"))
const AccessControl = React.lazy(() => import("pages/AccessControl"))
const Home = React.lazy(() => import("pages/profile"))
const Login = React.lazy(() => import("pages/Login"))
const ForgotPassword = React.lazy(() => import("pages/ForgotPassword"))
const ConfirmResetPassword = React.lazy(() => import("pages/ConfirmResetPassword"))

const renderRoutes = (list: TRoute[]): any => {
  return list.filter(x=> !x?.noRoute).map((route) => {
    if (route.children) return renderRoutes(route.children)
    return <Route key={route.guid} path={route.path} element={<ComponentLazy component={route.component} />} />
  })
}

const AppRedux = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    reduxInit(dispatch)
  }, [dispatch])

  return (
    <Routes>
      <Route element={<ComponentLazy component={MainLayout} />}>
        {renderRoutes(routerList)}
        <Route path="*" element={<ComponentLazy component={Home} />} />
      </Route>
      <Route element={<ComponentLazy component={AdminLayout} />}>
        {/*{renderRoutes(accessControlRoutes)}*/}
        <Route path="/access-control" element={<ComponentLazy component={AccessControl} />}/>
      </Route>
      <Route element={<ComponentLazy component={AuthLayout} />}>
        <Route path="/" element={<ComponentLazy component={Login} />} />
        <Route path="/forgot-password" element={<ComponentLazy component={ForgotPassword} />} />
        <Route path="/confirm-reset-password" element={<ComponentLazy component={ConfirmResetPassword} />} />
      </Route>
    </Routes>
  );
}

const App: React.FC = () => {

  const mdTheme = createTheme({
    typography: {
      fontFamily: 'Roboto',
      fontSize: 12,
      body2: {
        fontSize: 12
      },
      body1: {
        fontSize: 12
      },
      button: {
        fontSize: 12
      }
    },
  });
  return (
    <Provider store={store}>
      <HashRouter basename={''}>
        <ThemeProvider theme={mdTheme}>
          <AppRedux />
          <SpinnerCenter />
          <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover
          />
        </ThemeProvider>
        <Suspense>
          <ModalContainer />
        </Suspense>
      </HashRouter>
    </Provider>
  )
}

export default App;
