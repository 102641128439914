import { toast } from 'react-toastify';

export const errorToast = (message: string) => {
  if (!message) return
  return toast(message, {
    type: 'error',
    theme: 'colored',
    position: "bottom-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  })
}

export const successToast = (message?: string) => {
  return toast(message || "Success", {
    type: 'success',
    theme: 'colored',
    position: "bottom-center",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });
}

export const infoToast = (message?: string) => {
  return toast(message || "Success", {
    type: 'info',
    theme: 'colored',
    position: "bottom-center",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });
}

export const warningToast = (message?: string) => {
  return toast(message || "Warning", {
    type: "warning",
    position: "bottom-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  })
}
