import React, {
    useState,
    useCallback,
    useEffect,
    useContext,
    useMemo
} from 'react'
import {
    useLocation,
    useSearchParams
} from "react-router-dom";
import {
    ThCellFilterFormComponentProps,
    FilterDefaultOptions
} from "components/Table/TableHeader/FilterSortCell/d";
import {
    FilterMultiSelectSelectedContainer,
    MultiSelectChip,
    FilterMultiSelectContainer
}                                         from "components/Table/TableHeader/FilterSortCell/style";
import TableDataContext                   from "components/Table/context/context";
import { isEqual }                        from "lodash";


const FilterMultiSelect = ({column}: any) => {
    const [value, setValue] = useState<number[]|string[]>([])
    const [options, setOptions] = useState([] as any)
    const { pathname } = useLocation()
    const [searchParams] = useSearchParams();
    const {filter, setFilter, clearFilter} = useContext(TableDataContext)
    const {id} = column
    
    const labelHeader = useMemo(()=> column.HeaderExport ? column.HeaderExport : column.Header,[column])

    const onChange= useCallback((e: any)=> {
        const val = e.target.value
        val && setValue(val)
        !val?.length && clearFilter(id)
    },[setValue, clearFilter, id])

    const onClear = useCallback(()=> {
        id && clearFilter(id)
        setValue([])
    }, [setValue, clearFilter, id])

    useEffect(()=> {
        if(!filter[column?.id]?.length)return
        setValue(filter[column?.id])
    },[])

    useEffect(() => {
        if(!id) return
        let path = pathname
        const tab = searchParams.get('tab')
        if(tab) path = `${path}-${tab}`
        const props = ThCellFilterFormComponentProps?.[path]?.[id] || FilterDefaultOptions[id];
        if(!props) return
        (async ()=> {
            let opt = [] as any
            if(typeof props?.options === 'function') opt = await props.options()
            else opt = props?.options || []
            setOptions(opt)
        })()
    },[id, pathname, setOptions])

    useEffect(()=> {
        if (!value?.length || !id || isEqual(value,filter[id])) {
            return
        }
        setFilter({
            field: id,
            data: value
        })
    },[id, value, filter, setFilter])
    
    return<FilterMultiSelectContainer
        label={`Search ${labelHeader}`}
        options={options}
        value={value}
        onChange={onChange}
        onClear={onClear}
        disabled={!options?.length}
        multiple
        useHelperText={false}
        renderValue={(selected: any) => {
            return (
              <FilterMultiSelectSelectedContainer>
                  {selected?.map((value: string|number,index: number)=> {
                      const val = options.find((x:any)=> x.value === value)?.label || value
                      return <MultiSelectChip key={index} label={val} />
                  })}
              </FilterMultiSelectSelectedContainer>
            )
        }}
    />
}


export default FilterMultiSelect
