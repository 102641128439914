import { styled } from "@mui/material/styles";
import { Flex }   from "style";

export const TableSearchInputContainer = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  min-width: 190px;
  height: 40px;
  margin: 0 15px;
  
  .MuiFormControl-root {
    justify-content: flex-start;
    align-items: center;
  }
`
