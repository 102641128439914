import {
  mainSheetColumnsGlobalSearch
} from "pages/Legacy/Marketing/InitialDrop/helpers";

export const getAllColumns = (cols: any) => {
  return cols.map((col: any) => {
    if (col?.accessor) return col
    return getAllColumns(col?.columns)
  })
}

export const globalSearchFilter = (value: string | number, columns = mainSheetColumnsGlobalSearch) => ({
  $or: columns.map(x => ({
    [x]: {
      $like: `%${value}%`
    }
  }))
})

export const defaultFnFilters = {
  globalSearch: (value: string) => globalSearchFilter(value)
}
