import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef
} from "react"
import {
  Container,
  ContainerOneFilter,
  FilterName,
  FilterValues,
  FilterValue,
  FilterData,
  SaveFilterContainer,
  InputFilter,
  CanBeSavedButton,
  SavedButton
} from './style'
import TableDataContext from "components/Table/context/context";
import { getFilterMap } from "helpers";
import {
  useLocation,
  useSearchParams
} from "react-router-dom";
import {
  SaveAs as SaveAsIcon,
  Save as SaveIcon
} from "@mui/icons-material";
import { useLoading } from "hooks/useLoading";

export const ShowOneFilter = ({ filter }: any) => {
  
  const keys = Object.keys(filter)
  
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams();
  
  const data = useContext(TableDataContext)
  const { columns, isCurrentFilterSavedOne, saveFilterToBackend } = data;
  
  const [filterName, setFilterName] = useState<string | null>(null)
  
  const showToSave = !isCurrentFilterSavedOne && !!Object.keys(filter).length
  
  const wantToSave = useCallback(() => {
    setFilterName("");
  }, [])
  
  const onChangeInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.target?.value)
  }, [])
  
  useEffect(() => {
    setFilterName(null)
  }, [filter])
  
  
  const { setLoading, resetLoading } = useLoading()
  

  const refFilterName = useRef(filterName)
  refFilterName.current = filterName
  const saveFilter = useCallback(async () => {
    setLoading()
    try {
      await saveFilterToBackend(refFilterName.current as string)
      setFilterName(null)
    }catch(e){
    
    }finally {
      resetLoading()
    }
  },[saveFilterToBackend, setFilterName, refFilterName, setLoading, resetLoading])
  
  return (<Container>
    <FilterData>
      {keys.map(m => {
        const column = columns.find((s: any) => s.accessor === m)
        const name = column?.Header || ""
        const values = (() => {
          const val = filter[m]
          if (!Array.isArray(val)) return [val]
          const arr = getFilterMap({
            id: m,
            pathname,
            tab: searchParams.get("tab")
          }) as any
          return arr.filter((x: any) => val.includes(x.value)).map((x: any) => x?.label || '')
        })() as any
        return (
          <ContainerOneFilter key={m}>
            <FilterName>{name}</FilterName>
            <FilterValues>
              {
                (values || []).map((k: any) => <FilterValue key={k}>{k}</FilterValue>)
              }
            </FilterValues>
          </ContainerOneFilter>)
      })
      }
    </FilterData>
    {showToSave &&
    <SaveFilterContainer>
      {filterName === null ? <CanBeSavedButton variant="outlined" endIcon={<SaveAsIcon />} onClick={wantToSave}>Want To Save Filter </CanBeSavedButton>
      : <>
          <InputFilter
            label={"Filter Name"}
            helperText={"Enter filter name at least 8 characters"}
            value={filterName}
            onChange={onChangeInput}
            selectOnFocus
          />
           <SavedButton variant="contained" endIcon={<SaveIcon />} disabled={filterName.length < 8}  onClick={saveFilter}>Save Filter {filterName.length < 8 && <>&nbsp;<small>enter min 8 chars</small></>}</SavedButton>
         </> }
    </SaveFilterContainer>
    
    }
  </Container>)
  
}

export default ShowOneFilter
